/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600;700&display=swap');

/* CSS Variables */
:root {
    --sidebar-width: 240px;
    --navbar-height: 60px;
    --mobile-navbar-height: 60px;
    --mobile-breakpoint: 768px;
    --primary-color: #D8FF60;
    --background-dark: #121212;
    --background-darker: #0A0A0A;
}

/* App Layout */
.app-layout {
    display: flex;
    min-height: 100vh;
    background: linear-gradient(135deg, var(--background-darker) 0%, var(--background-dark) 100%);
    position: relative;
}

/* Sidebar */
.sidebar {
    position: fixed;
    left: 0;
    top: var(--navbar-height);
    bottom: 0;
    width: var(--sidebar-width);
    background: rgba(18, 18, 18, 0.95);
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    z-index: 100;
    display: flex;
    flex-direction: column;
}

/* Main Content */
.main-content {
    flex: 1;
    min-height: 100vh;
    padding: 2rem;
    margin-left: var(--sidebar-width);
    padding-top: calc(var(--navbar-height) + 1rem);
    position: relative;
    overflow-y: auto;
    background: linear-gradient(135deg, var(--background-darker) 0%, var(--background-dark) 100%);
}

/* Edit Info Page */
.edit-info-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    position: relative;
}

/* Loading Spinner */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    backdrop-filter: blur(5px);
}

.spinner {
    width: 40px;
    height: 40px;
    border: 3px solid rgba(216, 255, 96, 0.1);
    border-top-color: var(--primary-color);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Edit Info Container */
.edit-info-container {
    background: rgba(30, 30, 30, 0.95);
    border-radius: 24px;
    padding: 30px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(216, 255, 96, 0.15);
    backdrop-filter: blur(10px);
}

/* General Styling */
.location-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 100px;
    background-color: #121212; /* Darker background for a more futuristic look */
    color: #fff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    min-height: 100vh;
    box-sizing: border-box;
    position: relative;
    background-image: radial-gradient(circle at top right, #1e1e1e, #121212);
}

/* Container for the content */
.location-container {
    max-width: 900px;
    width: 100%;
    padding: 50px;
    background-color: rgba(42, 42, 42, 0.95); /* Semi-transparent for depth */
    border-radius: 20px;
    box-shadow: 0 0 20px rgba(216, 255, 96, 0.2); /* Soft neon glow */
    backdrop-filter: blur(10px);
    margin-bottom: 40px;
    position: relative;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.location-container::before {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    width: 200%;
    height: 200%;
    background: linear-gradient(45deg, rgba(216, 255, 96, 0.2), transparent);
    transform: rotate(45deg);
    z-index: 0;
}

.location-container > * {
    position: relative;
    z-index: 1;
}

/* Header Card */
.header-card {
    margin-bottom: 30px;
}

.header-card h1 {
    font-size: 3rem;
    color: #D8FF60; /* Neon green */
    text-align: center;
    margin-bottom: 20px;
    position: relative;
    line-height: 1.2;
    text-shadow: 0 0 10px rgba(216, 255, 96, 0.8); /* Enhanced text glow */
}

/* Changing text styling */
.changing-text {
    font-weight: bold;
    background-color: transparent;
    padding: 5px 10px;
    border-radius: 8px;
    animation: fadeIn 1s ease-in-out;
    box-shadow: 0 0 5px rgba(216, 255, 96, 0.5); /* Subtle glow */
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.location-container p {
    color: #ccc;
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 40px;
}

/* Form container styling */
.formStyle {
    display: flex;
    flex-direction: column;
    gap: 25px; /* Increased gap for better spacing */
    background-color: transparent; /* Transparent to show container's background */
    padding: 0;
    border-radius: 12px;
    color: #ffffff;
    width: 100%;
}

/* Row style for inputs to align side by side */
.rowStyle {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

/* Input Fields */
.location-input {
    flex: 1;
    padding: 15px 20px;
    border-radius: 10px;
    border: 2px solid rgba(216, 255, 96, 0.3); /* Lighter neon border */
    background-color: #1e1e1e;
    color: #fff;
    font-size: 1rem;
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.5);
    transition: border 0.3s ease, box-shadow 0.3s ease;
}

.location-input:focus {
    border-color: #D8FF60;
    box-shadow: 0 0 10px rgba(216, 255, 96, 0.7); /* Enhanced glow on focus */
    outline: none;
}

.location-input::placeholder {
    color: #777;
}

/* Select Fields */
.location-input[type="select"] {
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24"><path fill="white" d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position: right 15px center;
    background-size: 12px;
    cursor: pointer;
}

/* Error Styling */
.inputError {
    border-color: #ff4444;
    box-shadow: 0 0 10px rgba(255, 68, 68, 0.7); /* Red glow on error */
}

.errorText {
    color: #ff4444;
    font-size: 0.9rem;
    margin-top: -10px;
    margin-bottom: 10px;
    display: block;
}

/* Buttons */
.addButtonStyle, .removeButtonStyle, .submitButtonStyle {
    padding: 12px 20px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    transition: background-color 0.3s ease, transform 0.2s, box-shadow 0.3s ease;
}

.addButtonStyle, .submitButtonStyle {
    background: linear-gradient(45deg, #D8FF60, #A3FF00); /* Gradient neon green */
    color: #111;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.4); /* Neon glow */
}

.addButtonStyle:hover, .submitButtonStyle:hover {
    background: linear-gradient(45deg, #B8FF50, #8AFF20); /* Darker on hover */
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(184, 255, 80, 0.6);
}

.removeButtonStyle {
    background-color: #ff4444; /* Bright red */
    color: #ffffff;
    box-shadow: 0 4px 15px rgba(255, 68, 68, 0.4);
}

.removeButtonStyle:hover {
    background-color: #ff2222;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(255, 34, 34, 0.6);
}

/* Submit Button */
.location-button.submitButtonStyle {
    background: linear-gradient(45deg, #D8FF60, #A3FF00);
    color: #111;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.4);
}

.location-button.submitButtonStyle:hover {
    background: linear-gradient(45deg, #B8FF50, #8AFF20);
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(184, 255, 80, 0.6);
}

/* Collapsible Section Styles */
.collapsible-section {
    background-color: rgba(42, 42, 42, 0.9);
    border-radius: 15px;
    margin-bottom: 25px;
    padding: 15px;
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.2);
    transition: transform 0.3s ease;
}

.collapsible-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #1e1e1e;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.collapsible-header:hover {
    background-color: #2a2a2a;
}

.collapsible-icon {
    display: flex;
    align-items: center;
    margin-right: 10px;
    color: #D8FF60;
    font-size: 1.2rem;
}

.collapsible-title {
    flex: 1;
    font-size: 1.1rem;
    color: #fff;
}

.collapsible-toggle {
    font-size: 1.5rem;
    color: #D8FF60;
}

.collapsible-content {
    padding: 20px;
    animation: slideDown 0.3s ease-in-out;
}

@keyframes slideDown {
    from {
        opacity: 0;
        transform: translateY(-10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Response Text */
.responseText {
    text-align: center;
    color: #5FCEAE;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: bold;
    animation: fadeIn 0.5s ease-in-out;
}

.responseTextError {
    text-align: center;
    color: #ff4444;
    font-size: 1.2rem;
    margin-bottom: 20px;
    font-weight: bold;
    animation: fadeIn 0.5s ease-in-out;
}

/* Tooltip Styles */
.tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.85);
    color: #ffffff;
    padding: 10px 15px;
    border-radius: 8px;
    max-width: 250px;
    word-wrap: break-word;
    z-index: 1000;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
    text-align: center;
    white-space: normal;
    line-height: 1.5;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.tooltip::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-width: 7px;
    border-style: solid;
    border-color: transparent transparent rgba(0, 0, 0, 0.85) transparent;
}

.tooltip-visible {
    opacity: 1;
    transform: translateY(-5px);
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    :root {
        --sidebar-width: 200px;
    }
    
    .main-content {
        margin-left: var(--sidebar-width);
        padding: 1.5rem;
        padding-top: calc(var(--navbar-height) + 1rem);
    }

    .location-container {
        padding: 25px;
    }

    .header-card h1 {
        font-size: 2.5rem;
    }

    .location-input,
    .location-button {
        max-width: 100%;
        width: 100%;
    }

    .rowStyle {
        flex-direction: column;
        gap: 15px;
    }

    .collapsible-section {
        padding: 10px;
    }

    .collapsible-content {
        padding: 15px;
    }
}

@media (max-width: 768px) {
    .app-layout {
        flex-direction: column;
    }

    .sidebar {
        display: none; /* Hide sidebar on mobile */
    }

    .main-content {
        margin-left: 0;
        padding: 1rem;
        padding-top: calc(var(--mobile-navbar-height) + 1rem);
    }

    .edit-info-page {
        padding: 10px;
    }

    .location-page {
        padding: 40px 20px;
    }

    .location-container {
        padding: 20px 20px;
    }

    .header-card h1 {
        font-size: 2rem;
    }

    .collapsible-section {
        padding: 10px;
    }

    .collapsible-content {
        padding: 15px;
    }
}

@media (max-width: 600px) {
    .location-page {
        padding: 30px 10px;
    }

    .location-container {
        padding: 20px 15px;
    }

    .header-card h1 {
        font-size: 1.8rem;
    }

    .collapsible-section {
        padding: 8px;
    }

    .collapsible-content {
        padding: 10px;
    }

    .tooltip {
        max-width: 200px;
    }
}

/* Security Banner */
.security-banner {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    background: rgba(42, 42, 42, 0.95);
    border: 1px solid rgba(216, 255, 96, 0.2);
    padding: 12px 20px;
    border-radius: 12px;
    font-size: 0.9rem;
    color: #D8FF60;
    margin-bottom: 30px;
    box-shadow: 0 4px 20px rgba(216, 255, 96, 0.1);
}

.shield-icon {
    font-size: 1.2rem;
    filter: drop-shadow(0 0 8px rgba(216, 255, 96, 0.4));
}

/* Progress Navigation */
.form-progress {
    display: flex !important;
    justify-content: center !important;
    margin: 0 0 30px !important;
    gap: 20px !important;
    padding: 0 !important;
    width: 100% !important;
    min-height: 48px !important;
}

button.progress-step {
    all: unset !important;
    position: relative !important;
    width: 150px !important;
    height: 48px !important;
    background: rgba(26, 26, 26, 0.95) !important;
    border: 1px solid rgba(216, 255, 96, 0.15) !important;
    color: rgba(255, 255, 255, 0.7) !important;
    padding: 12px 24px !important;
    border-radius: 8px !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 15px !important;
    font-weight: 500 !important;
    text-transform: none !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
    margin: 0 !important;
    text-align: center !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    box-sizing: border-box !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

button.progress-step.active {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
    border: none !important;
    font-weight: 600 !important;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.3) !important;
}

button.progress-step.completed {
    background: rgba(216, 255, 96, 0.15) !important;
    border-color: rgba(216, 255, 96, 0.3) !important;
    color: #D8FF60 !important;
}

button.progress-step:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(216, 255, 96, 0.2) !important;
    border-color: rgba(216, 255, 96, 0.4) !important;
    background: rgba(42, 42, 42, 0.95) !important;
}

button.progress-step.active:hover {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
}

/* Form Section */
.form-section {
    background: rgba(42, 42, 42, 0.95);
    border-radius: 16px;
    padding: 25px;
    margin-bottom: 25px;
    border: 1px solid rgba(216, 255, 96, 0.15);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.form-section h2 {
    color: #ffffff !important;
    font-size: 1.5rem !important;
    margin-bottom: 25px !important;
    display: flex !important;
    align-items: center !important;
    gap: 10px !important;
    font-weight: 600 !important;
    text-shadow: none !important;
}

.form-section h2 .section-icon {
    color: #D8FF60 !important;
    font-size: 1.3em !important;
}

.form-field label {
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    margin-bottom: 8px !important;
    color: #ffffff !important;
    font-size: 0.9rem !important;
    font-weight: 500 !important;
}

/* Input Fields */
.input-group {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-bottom: 20px;
}

.form-field {
    margin-bottom: 20px;
}

.form-field label {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.9rem;
    font-weight: 500;
}

.field-icon {
    color: #D8FF60;
    font-size: 1rem;
}

.form-field input,
.form-field select {
    width: 100%;
    padding: 12px 16px;
    background: rgba(30, 30, 30, 0.95);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 10px;
    color: #fff;
    font-size: 0.95rem;
    transition: all 0.3s ease;
    font-family: 'Plus Jakarta Sans', sans-serif;
}

.form-field input:focus,
.form-field select:focus {
    outline: none;
    border-color: #D8FF60;
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.15);
    background: rgba(42, 42, 42, 0.95);
}

.form-field input:hover,
.form-field select:hover {
    border-color: rgba(216, 255, 96, 0.3);
    background: rgba(42, 42, 42, 0.95);
}

/* Next Button */
button.next-button {
    width: 100% !important;
    padding: 14px !important;
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    border: none !important;
    border-radius: 12px !important;
    color: #111 !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.2) !important;
    margin-top: 20px !important;
    text-transform: none !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

button.next-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 20px rgba(216, 255, 96, 0.3) !important;
}

button.next-button.disabled {
    opacity: 0.6;
    cursor: not-allowed;
    background: rgba(216, 255, 96, 0.3);
    transform: none;
    box-shadow: none;
}

button.next-button.disabled:hover {
    transform: none;
    box-shadow: none;
}

/* Media Queries */
@media (max-width: 768px) {
    .edit-info-container {
        padding: 20px;
        margin: 10px;
        border-radius: 20px;
    }

    .security-banner {
        padding: 10px 15px;
        font-size: 0.85rem;
        margin-bottom: 25px;
    }

    .form-progress {
        gap: 12px !important;
        min-height: 44px !important;
    }

    button.progress-step {
        width: 120px !important;
        height: 44px !important;
        padding: 10px 16px !important;
        font-size: 14px !important;
    }

    .form-section {
        padding: 20px;
    }

    .form-section h2 {
        font-size: 1.3rem;
        margin-bottom: 20px;
    }

    .input-group {
        grid-template-columns: 1fr;
        gap: 15px;
    }

    .form-field {
        margin-bottom: 15px;
    }

    .form-field input,
    .form-field select {
        padding: 12px 14px;
    }
}

@media (max-width: 480px) {
    .edit-info-page {
        padding: 15px;
    }

    .edit-info-container {
        padding: 15px;
        margin: 0;
    }

    .security-banner {
        padding: 8px 12px;
        font-size: 0.8rem;
        margin-bottom: 20px;
    }

    .form-progress {
        gap: 8px !important;
        min-height: 40px !important;
    }

    button.progress-step {
        width: 100px !important;
        height: 40px !important;
        padding: 8px 12px !important;
        font-size: 13px !important;
    }

    .form-section {
        padding: 15px;
    }

    .form-section h2 {
        font-size: 1.2rem;
        margin-bottom: 15px;
    }

    .form-field label {
        font-size: 0.85rem;
    }

    .form-field input,
    .form-field select {
        padding: 10px 12px;
        font-size: 0.9rem;
    }

    button.next-button {
        padding: 12px !important;
        font-size: 0.95rem !important;
    }
}

/* Add Email Button */
button.add-button {
    all: unset !important;
    display: flex !important;
    align-items: center !important;
    gap: 8px !important;
    padding: 12px 20px !important;
    background: rgba(216, 255, 96, 0.1) !important;
    border: 1px solid rgba(216, 255, 96, 0.2) !important;
    border-radius: 8px !important;
    color: #D8FF60 !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2) !important;
    width: fit-content !important;
    margin-top: 10px !important;
}

button.add-button:hover {
    background: rgba(216, 255, 96, 0.15) !important;
    border-color: rgba(216, 255, 96, 0.3) !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 12px rgba(216, 255, 96, 0.15) !important;
}

button.add-button svg {
    font-size: 16px !important;
    color: #D8FF60 !important;
}

/* Form Navigation */
.form-navigation {
    margin-top: 30px;
}

.nav-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 15px;
}

.prev-button,
.next-button {
    padding: 0 24px;
    height: 48px;
    border-radius: 12px;
    font-size: 0.95rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 120px;
}

.next-button {
    background: linear-gradient(45deg, #D8FF60, #A3FF00);
    color: #111;
    border: none;
    font-weight: 600;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.2);
}

.next-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 6px 20px rgba(216, 255, 96, 0.3);
}

.prev-button {
    background: rgba(26, 26, 26, 0.95);
    color: #D8FF60;
    border: 1px solid rgba(216, 255, 96, 0.3);
}

.prev-button:hover {
    background: rgba(42, 42, 42, 0.95);
    border-color: #D8FF60;
}

@media (max-width: 768px) {
    .nav-buttons {
        flex-direction: column;
        gap: 12px;
    }

    .prev-button,
    .next-button {
        width: 100%;
        height: 44px;
        font-size: 14px;
    }
}

@media (max-width: 480px) {
    .nav-buttons {
        gap: 10px;
    }

    .prev-button,
    .next-button {
        height: 40px;
        font-size: 13px;
    }
}

/* Address Section Styles */
.form-field.full-width {
    width: 100% !important;
    margin-bottom: 25px !important;
}

.form-field input[type="text"],
.form-field select,
.select-container select {
    all: unset !important;
    width: 100% !important;
    padding: 16px !important;
    background: rgba(26, 26, 26, 0.95) !important;
    border: 1px solid rgba(216, 255, 96, 0.2) !important;
    border-radius: 12px !important;
    color: rgba(255, 255, 255, 0.9) !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 15px !important;
    box-sizing: border-box !important;
    transition: all 0.3s ease !important;
    height: 52px !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.form-field input[type="text"]::placeholder {
    color: rgba(255, 255, 255, 0.4) !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

/* Select Container Styles */
.select-container {
    position: relative !important;
    width: 100% !important;
    margin-bottom: 15px !important;
}

.select-container::after {
    content: '' !important;
    position: absolute !important;
    right: 16px !important;
    top: 50% !important;
    width: 20px !important;
    height: 20px !important;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='%23D8FF60' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E") !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
    transform: translateY(-50%) !important;
    pointer-events: none !important;
    transition: transform 0.3s ease !important;
}

.select-container select {
    cursor: pointer !important;
    padding-right: 48px !important;
    background-image: none !important;
    width: 100% !important;
    padding: 16px !important;
    background: rgba(26, 26, 26, 0.95) !important;
    border: 1px solid rgba(216, 255, 96, 0.2) !important;
    border-radius: 12px !important;
    color: rgba(255, 255, 255, 0.9) !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    font-size: 15px !important;
    box-sizing: border-box !important;
    transition: all 0.3s ease !important;
    height: 52px !important;
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
}

.select-container select:hover,
.select-container select:focus {
    border-color: rgba(216, 255, 96, 0.4) !important;
    background: rgba(42, 42, 42, 0.95) !important;
}

.select-container select:focus {
    border-color: #D8FF60 !important;
    box-shadow: 0 0 0 1px #D8FF60 !important;
}

.select-container select option {
    background: #1e1e1e !important;
    color: #fff !important;
    padding: 12px !important;
    font-size: 14px !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
}

/* Media Queries */
@media (max-width: 768px) {
    .select-container::after {
        right: 14px !important;
        width: 18px !important;
        height: 18px !important;
    }

    .select-container select {
        height: 48px !important;
        padding: 14px !important;
        padding-right: 44px !important;
        font-size: 14px !important;
    }
}

@media (max-width: 480px) {
    .select-container::after {
        right: 12px !important;
        width: 16px !important;
        height: 16px !important;
    }

    .select-container select {
        height: 44px !important;
        padding: 12px !important;
        padding-right: 40px !important;
        font-size: 13px !important;
    }
}

/* Terms Section */
.terms-section {
    margin-top: 30px !important;
    margin-bottom: 20px !important;
}

.terms-checkbox {
    display: flex !important;
    align-items: center !important;
    gap: 10px !important;
}

.terms-checkbox input[type="checkbox"] {
    all: unset !important;
    width: 20px !important;
    height: 20px !important;
    border: 1px solid rgba(216, 255, 96, 0.3) !important;
    border-radius: 6px !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    position: relative !important;
    background: rgba(26, 26, 26, 0.95) !important;
}

.terms-checkbox input[type="checkbox"]:checked {
    background: #D8FF60 !important;
    border-color: #D8FF60 !important;
}

.terms-checkbox input[type="checkbox"]:checked::after {
    content: '✓' !important;
    color: #111 !important;
    font-size: 14px !important;
    font-weight: bold !important;
}

.terms-checkbox input[type="checkbox"]:hover {
    border-color: rgba(216, 255, 96, 0.5) !important;
}

.terms-checkbox label {
    color: rgba(255, 255, 255, 0.9) !important;
    font-size: 14px !important;
    font-family: 'Plus Jakarta Sans', sans-serif !important;
    cursor: pointer !important;
}

.terms-checkbox button {
    all: unset !important;
    color: #D8FF60 !important;
    text-decoration: underline !important;
    cursor: pointer !important;
    transition: color 0.3s ease !important;
}

.terms-checkbox button:hover {
    color: #A3FF00 !important;
}

/* Media Queries */
@media (max-width: 768px) {
    .form-field input[type="text"],
    .form-field select,
    .select-container select {
        height: 48px !important;
        padding: 14px !important;
        font-size: 14px !important;
    }

    .select-container select {
        background-size: 18px !important;
        padding-right: 44px !important;
    }

    .terms-checkbox {
        gap: 8px !important;
    }

    .terms-checkbox input[type="checkbox"] {
        width: 18px !important;
        height: 18px !important;
    }

    .terms-checkbox label {
        font-size: 13px !important;
    }
}

@media (max-width: 480px) {
    .form-field input[type="text"],
    .form-field select,
    .select-container select {
        height: 44px !important;
        padding: 12px !important;
        font-size: 13px !important;
    }

    .select-container select {
        background-size: 16px !important;
        padding-right: 40px !important;
    }

    .terms-checkbox input[type="checkbox"] {
        width: 16px !important;
        height: 16px !important;
    }

    .terms-checkbox label {
        font-size: 12px !important;
    }
}

.welcome-banner {
    background: linear-gradient(135deg, #1a1a1a 0%, #2d2d2d 100%);
    padding: 2rem;
    border-radius: 10px;
    margin-bottom: 2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border: 1px solid #3a3a3a;
}

.welcome-content {
    text-align: center;
    color: #ffffff;
}

.welcome-content .success-icon {
    color: #00ff85;
    font-size: 3rem;
    margin-bottom: 1rem;
}

.welcome-content h1 {
    font-size: 1.8rem;
    margin-bottom: 1rem;
    color: #00ff85;
}

.welcome-content p {
    font-size: 1.1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
    color: #e0e0e0;
}

.welcome-content p:last-child {
    margin-bottom: 0;
}

.form-fields {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
}

.form-field {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;
}

.form-field label {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 500;
    color: #333;
}

.form-field input {
    padding: 12px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    font-size: 1rem;
    width: 100%;
    transition: border-color 0.2s ease;
}

.form-field input:focus {
    border-color: #4CAF50;
    outline: none;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.1);
}

.form-field input.error {
    border-color: #f44336;
}

.error-message {
    color: #f44336;
    font-size: 0.875rem;
    margin-top: 0.25rem;
}

.field-icon {
    color: #666;
    font-size: 1.125rem;
}

.additional-fields {
    margin-top: 1rem;
}

.add-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 8px 16px;
    background-color: transparent;
    border: 2px dashed #4CAF50;
    color: #4CAF50;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    transition: all 0.2s ease;
}

.add-button:hover {
    background-color: rgba(76, 175, 80, 0.1);
}

.additional-field {
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1px solid #e0e0e0;
}

.input-with-action {
    display: flex;
    gap: 0.5rem;
    align-items: center;
}

.input-with-action input {
    flex: 1;
}

.remove-field {
    padding: 8px;
    background-color: transparent;
    border: none;
    color: #f44336;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    transition: background-color 0.2s ease;
}

.remove-field:hover {
    background-color: rgba(244, 67, 54, 0.1);
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-input-container input {
    width: 100%;
    padding-right: 40px;
}

.toggle-password {
    position: absolute;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-password:hover {
    color: #333;
}

.password-requirements {
    margin-top: 8px;
    padding: 8px;
    background-color: #f8f9fa;
    border-radius: 4px;
    font-size: 12px;
}

.requirement {
    color: #666;
    margin: 4px 0;
    display: flex;
    align-items: center;
}

.requirement::before {
    content: "•";
    margin-right: 8px;
    color: #D8FF60;
}

/* Responsive Styles */
@media (max-width: 1024px) {
    .main-content {
        margin-left: var(--sidebar-width);
        padding: 1.5rem;
    }

    .edit-info-container {
        padding: 25px;
    }
}

@media (max-width: 768px) {
    .app-layout {
        flex-direction: column;
    }

    .main-content {
        margin-left: 0;
        padding: 1rem;
        margin-top: var(--navbar-height);
    }

    .edit-info-page {
        padding: 10px;
    }

    .edit-info-container {
        padding: 20px;
    }

    .form-progress {
        flex-wrap: wrap;
        gap: 10px !important;
    }

    button.progress-step {
        width: calc(33.33% - 10px) !important;
        font-size: 14px !important;
        padding: 10px !important;
    }
}

@media (max-width: 480px) {
    .main-content {
        padding: 0.5rem;
    }

    .edit-info-page {
        padding: 5px;
    }

    .edit-info-container {
        padding: 15px;
    }

    .form-progress {
        gap: 8px !important;
    }

    button.progress-step {
        width: 100% !important;
        margin-bottom: 5px !important;
    }

    .form-navigation {
        flex-direction: column;
        gap: 10px !important;
    }

    button.prev-button,
    button.next-button {
        width: 100% !important;
    }
}

.error-banner {
    background-color: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.2);
    color: #ff4444;
    padding: 1rem;
    margin-bottom: 1rem;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 0.5rem;
}

.error-banner .error-icon {
    font-size: 1.2rem;
    color: #ff4444;
}
