.file-scan-page {
  min-height: 100vh;
  background-color: #1a1a1a;
  color: white;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
}

h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
  background: linear-gradient(45deg, #4CAF50, #45a049);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.subtitle {
  color: #888;
  font-size: 1.4rem;
  margin-bottom: 3rem;
}

.preview-container {
  margin: 2rem auto;
  max-width: 1000px;
  padding: 2rem;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
}

.software-preview {
  width: 100%;
  height: auto;
  border-radius: 12px;
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  transition: transform 0.3s ease;
}

.software-preview:hover {
  transform: translateY(-10px);
}

.features-section {
  margin: 6rem 0;
}

.features-section h2 {
  font-size: 2.5rem;
  margin-bottom: 3rem;
}

.features-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 4rem 0;
}

.feature-card {
  background-color: #2a2a2a;
  padding: 2.5rem;
  border-radius: 16px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 30px rgba(76, 175, 80, 0.1);
}

.feature-icon {
  font-size: 2.5rem;
  color: #4CAF50;
  margin-bottom: 1.5rem;
}

.feature-card h3 {
  margin-bottom: 1rem;
  font-size: 1.4rem;
}

.feature-card p {
  color: #888;
  line-height: 1.6;
}

.cta-section {
  margin: 4rem 0;
  padding: 4rem;
  background: linear-gradient(45deg, rgba(76, 175, 80, 0.1), rgba(69, 160, 73, 0.1));
  border-radius: 20px;
}

.cta-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.cta-section p {
  color: #888;
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.download-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  background: linear-gradient(45deg, #4CAF50, #45a049);
  color: white;
  border: 2px solid rgba(255, 255, 255, 0.1);
  padding: 1.2rem 3.5rem;
  border-radius: 16px;
  font-size: 1.4rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  box-shadow: 0 4px 6px rgba(76, 175, 80, 0.1),
              0 8px 16px rgba(76, 175, 80, 0.1);
  backdrop-filter: blur(10px);
  position: relative;
  overflow: hidden;
}

.download-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 12px rgba(76, 175, 80, 0.2),
              0 12px 24px rgba(76, 175, 80, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.download-button:active {
  transform: translateY(1px);
  box-shadow: 0 2px 4px rgba(76, 175, 80, 0.1);
}

.download-button svg {
  font-size: 1.6rem;
}

.unavailable-message {
  position: fixed;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff4444;
  color: white;
  padding: 1rem 2rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  animation: slideUp 0.3s ease;
  z-index: 1000;
}

.footer {
  background-color: #2a2a2a;
  padding: 3rem 0;
  margin-top: 4rem;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  text-align: center;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.footer-links a {
  color: #888;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #4CAF50;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.social-links a {
  color: #888;
  font-size: 1.5rem;
  transition: color 0.3s ease;
}

.social-links a:hover {
  color: #4CAF50;
}

.copyright {
  color: #666;
  font-size: 0.9rem;
}

@keyframes slideUp {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@media (max-width: 768px) {
  .content {
    padding: 1rem;
  }

  h1 {
    font-size: 2rem;
  }

  .subtitle {
    font-size: 1.1rem;
  }

  .features-grid {
    grid-template-columns: 1fr;
    gap: 1.5rem;
  }

  .cta-section {
    padding: 2rem;
  }

  .footer-links {
    flex-direction: column;
    gap: 1rem;
  }

  .download-button {
    padding: 1rem 2.5rem;
    font-size: 1.2rem;
  }
}
