.area-code-main {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  opacity: 0;
  transform: translateY(10px);
  animation: slideIn 0.5s ease forwards;
}

.hero-section {
  text-align: center;
  padding: 40px 20px;
  margin-bottom: 40px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 15px;
}

.hero-icon {
  font-size: 48px;
  color: #D8FF60;
  margin-bottom: 20px;
}

.hero-icon.pulse {
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.hero-subtitle {
  max-width: 600px;
  margin: 20px auto;
  color: #ffffff;
  opacity: 0.8;
  line-height: 1.6;
}

.search-section {
  max-width: 600px;
  margin: 30px auto;
}

.search-container {
  position: relative;
}

.search-wrapper {
  position: relative;
  width: 100%;
}

.search-icon {
  position: absolute;
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #D8FF60;
  font-size: 20px;
  z-index: 1;
}

.search-input {
  width: 100%;
  padding: 15px 20px;
  border: 2px solid rgba(216, 255, 96, 0.3);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.05);
  color: #ffffff;
  font-size: 16px;
  transition: all 0.3s ease;
}

.search-input:focus {
  outline: none;
  border-color: #D8FF60;
  background: rgba(255, 255, 255, 0.1);
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: #1a1a1a;
  border: 1px solid rgba(216, 255, 96, 0.3);
  border-radius: 10px;
  margin-top: 5px;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  color: #ffffff;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.search-result-item:hover {
  background-color: rgba(216, 255, 96, 0.1);
}

.area-code {
  font-size: 18px;
  font-weight: bold;
  color: #D8FF60;
}

.result-meta {
  display: flex;
  align-items: center;
  gap: 10px;
}

.location {
  color: #ffffff;
  opacity: 0.8;
}

.safety-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.safety-badge.safe {
  background-color: #2ed573;
  color: #000000;
}

.safety-badge.moderate {
  background-color: #ffa502;
  color: #000000;
}

.safety-badge.high-risk {
  background-color: #ff4757;
  color: #ffffff;
}

.external-link-icon {
  font-size: 12px;
  opacity: 0.6;
}

.info-cards {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 40px auto;
  max-width: 1200px;
  padding: 0 20px;
}

.info-card {
  flex: 1;
  min-width: 250px;
  max-width: 350px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 10px;
  padding: 25px;
  text-align: center;
  border-left: 4px solid;
  transition: transform 0.3s ease;
  opacity: 0;
  animation: fadeIn 0.5s ease forwards;
}

.info-card:nth-child(1) { animation-delay: 0.1s; }
.info-card:nth-child(2) { animation-delay: 0.2s; }
.info-card:nth-child(3) { animation-delay: 0.3s; }

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.info-card:hover {
  transform: translateY(-5px);
}

.info-icon {
  font-size: 32px;
  margin-bottom: 15px;
}

.area-codes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  padding: 20px;
}

.area-code-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 20px;
  text-decoration: none;
  color: white;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.area-code-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.area-code-card h3 {
  color: #D8FF60;
  margin: 0 0 10px 0;
  font-size: 24px;
}

.area-code-info {
  margin-top: 15px;
}

.quick-links {
  display: flex;
  gap: 10px;
  margin: 10px 0;
  flex-wrap: wrap;
}

.alert-count {
  color: #FF4757;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.govt-numbers {
  color: #2ed573;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 0.9rem;
}

.view-details {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #D8FF60;
  font-size: 0.9rem;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .area-code-main {
    padding: 10px;
  }

  .hero-section {
    padding: 30px 15px;
  }

  .info-cards {
    flex-direction: column;
    align-items: center;
  }

  .info-card {
    width: 100%;
    max-width: 100%;
  }

  .area-codes-grid {
    grid-template-columns: 1fr;
  }

  .search-results {
    max-height: 300px;
  }
}

.hero-section h1 {
  font-size: 2.8rem;
  margin-bottom: 1.5rem;
  background: linear-gradient(45deg, #D8FF60, #A3FF00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
}

/* Add these loading animation styles */
.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60vh;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 40px;
}

.loading-pulse {
  width: 60px;
  height: 60px;
  border: 3px solid rgba(216, 255, 96, 0.2);
  border-radius: 50%;
  border-top-color: #D8FF60;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 20px;
}

.loading-text {
  color: #D8FF60;
  font-size: 1.2rem;
  opacity: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
} 