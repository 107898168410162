/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&family=Orbitron:wght@500;700&display=swap');

/* Reset */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Dashboard Header */
.dashboard-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 15px;
    background: rgba(216, 255, 96, 0.05);
    border-radius: 20px;
    margin-bottom: 15px;
    border: 1px solid rgba(216, 255, 96, 0.1);
}

/* Mobile styles */
@media screen and (max-width: 768px) {
    .dashboard-header {
        padding: 10px;
        width: 100%;
    }
}

/* Header Background Grid */
.header-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1;
    background: 
        linear-gradient(90deg, rgba(216, 255, 96, 0.07) 1px, transparent 1px),
        linear-gradient(rgba(216, 255, 96, 0.07) 1px, transparent 1px),
        linear-gradient(90deg, rgba(216, 255, 96, 0.05) 1px, transparent 1px),
        linear-gradient(rgba(216, 255, 96, 0.05) 1px, transparent 1px);
    background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position: 0 0, 0 0, 10px 10px, 10px 10px;
    animation: gridMove 15s linear infinite;
}

@keyframes gridMove {
    0% {
        background-position: 0 0, 0 0, 10px 10px, 10px 10px;
    }
    100% {
        background-position: 100px 0, 100px 0, 30px 10px, 30px 10px;
    }
}

/* Header Content */
.header-content {
    position: relative;
    z-index: 2;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 0;
    margin: 0;
}

/* Header Title */
.header-title {
    font-family: 'Orbitron', sans-serif;
    font-size: clamp(1.8rem, 5vw, 2.8rem);
    font-weight: 700;
    color: #D8FF60;
    text-shadow: 
        0 0 10px rgba(216, 255, 96, 0.4),
        0 0 20px rgba(216, 255, 96, 0.2),
        0 0 30px rgba(216, 255, 96, 0.1);
    margin-bottom: 1rem;
    position: relative;
    display: inline-block;
    letter-spacing: 2px;
}

.title-accent {
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%);
    width: 60%;
    height: 2px;
    background: linear-gradient(90deg, 
        transparent 0%, 
        #D8FF60 50%, 
        transparent 100%
    );
    animation: scanline 2s linear infinite;
}

@keyframes scanline {
    0% {
        background-position: 0 0;
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        background-position: 100% 0;
        opacity: 0.5;
    }
}

/* Task Container */
.task-container {
    display: flex;
    align-items: center;
    gap: 10px;
    background: rgba(0, 0, 0, 0.3);
    padding: 12px 20px;
    border-radius: 12px;
    margin-top: 15px;
    border: 1px solid rgba(216, 255, 96, 0.1);
}

.dashboard-task {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
}

.task-label {
    color: #D8FF60;
    font-weight: 600;
    white-space: nowrap;
}

.task-text {
    color: #ffffff;
    opacity: 0.9;
}

/* Task Indicator */
.task-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #D8FF60;
    animation: pulse 2s infinite;
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.5);
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 10px rgba(216, 255, 96, 0.5);
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
        box-shadow: 0 0 20px rgba(216, 255, 96, 0.7);
    }
    100% {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 10px rgba(216, 255, 96, 0.5);
    }
}

/* Header Buttons Container */
.header-buttons {
    display: flex;
    gap: 24px;
    margin-top: 1.5rem;
}

.button-link {
    text-decoration: none;
    position: relative;
}

/* Button Styling */
.manage-profile-button,
.view-removals-button {
    min-width: 220px;
    padding: 16px 32px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 0.95rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1.5px;
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    position: relative;
    overflow: hidden;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.button-icon {
    font-size: 1.2rem;
    color: #111 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.3s ease;
}

.button-text {
    position: relative;
    z-index: 1;
}

.manage-profile-button:hover,
.view-removals-button:hover {
    background: linear-gradient(45deg, #B8FF50, #8AFF20) !important;
    color: #111 !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(184, 255, 80, 0.4);
}

.manage-profile-button:active,
.view-removals-button:active {
    transform: translateY(1px);
    box-shadow: 0 2px 10px rgba(184, 255, 80, 0.3);
}

.manage-profile-button::before,
.view-removals-button::before {
    display: none;
}

/* Responsive Adjustments for Buttons */
@media (max-width: 1024px) {
    .header-title {
        font-size: 2.5rem;
    }

    .task-container {
        padding: 12px 20px;
        margin: 1rem auto;
    }

    .header-buttons {
        gap: 15px;
    }

    .manage-profile-button,
    .view-removals-button {
        width: 200px;
    }
}

@media (max-width: 768px) {
    .dashboard-header {
        padding: 16px;
        margin: 0;
        width: 100%;
    }

    .header-content {
        padding: 0;
        margin: 0;
    }

    .header-title {
        font-size: 2rem;
    }

    .task-container {
        padding: 12px;
        margin: 0;
        max-width: 90%;
    }

    .task-indicator {
        width: 10px;
        height: 10px;
    }

    .dashboard-task {
        font-size: 1rem;
    }

    .header-buttons {
        flex-direction: column;
        gap: 16px;
        width: 100%;
        max-width: 300px;
        margin: 1rem auto;
    }

    .manage-profile-button,
    .view-removals-button {
        width: 100%;
        min-width: unset;
        padding: 14px 24px;
        font-size: 0.9rem;
    }

    .title-accent {
        width: 80%;
    }
}

@media (max-width: 380px) {
    .header-title {
        font-size: 1.8rem;
    }

    .task-container {
        padding: 8px 12px;
    }

    .dashboard-task {
        font-size: 0.95rem;
    }
}

/* Keyframes for Grid Movement */
@keyframes gridMove {
    0% {
        background-position: 0 0, 0 0, 10px 10px, 10px 10px;
    }
    100% {
        background-position: 100px 0, 100px 0, 30px 10px, 30px 10px;
    }
}

/* Pulse Animation for Task Indicator */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 10px rgba(216, 255, 96, 0.5);
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
        box-shadow: 0 0 20px rgba(216, 255, 96, 0.7);
    }
    100% {
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 10px rgba(216, 255, 96, 0.5);
    }
}

/* Customer Card Container */
.customer-card {
    background: linear-gradient(145deg, rgba(26, 26, 26, 0.95), rgba(21, 21, 21, 0.95));
    border: 1px solid rgba(216, 255, 96, 0.15);
    border-radius: 12px;
    padding: 24px;
    margin: 1.5rem 0;
    backdrop-filter: blur(10px);
    box-shadow: 
        0 4px 20px rgba(0, 0, 0, 0.2),
        inset 0 0 30px rgba(216, 255, 96, 0.03);
}

/* Customer Info Grid */
.customer-info {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    margin-top: 16px;
}

/* Customer Name */
.customer-name {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    color: #fff;
    margin-bottom: 4px;
    letter-spacing: 0.5px;
}

/* Customer Email */
.customer-email {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1rem;
    color: rgba(216, 255, 96, 0.8);
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.customer-email i {
    font-size: 0.9rem;
    opacity: 0.8;
}

/* Info Item Styling */
.info-item {
    display: flex;
    flex-direction: column;
    gap: 6px;
    padding: 12px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid rgba(216, 255, 96, 0.1);
}

/* Info Labels */
.info-label {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 0.8rem;
    font-weight: 500;
    color: #888;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Info Values */
.info-value {
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 1.1rem;
    font-weight: 600;
    color: #fff;
}

/* Trial Status Badge */
.status-badge {
    display: inline-flex;
    align-items: center;
    gap: 6px;
    padding: 6px 12px;
    background: rgba(216, 255, 96, 0.1);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 20px;
    font-size: 0.9rem;
    color: #D8FF60;
}

.status-badge i {
    font-size: 0.8rem;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    .customer-info {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .customer-name {
        font-size: 1.3rem;
    }

    .customer-email {
        font-size: 0.95rem;
    }

    .info-value {
        font-size: 1rem;
    }
}

/* Safe Area Support for Modern Phones */
@supports (padding: max(0px)) {
    .settings-container {
        padding-left: max(16px, env(safe-area-inset-left));
        padding-right: max(16px, env(safe-area-inset-right));
    }
}

/* Dark Mode Enhancement */
@media (prefers-color-scheme: dark) {
    .settings-container {
        background: #000;
    }
    
    .settings-group {
        background: #1C1C1E;
    }
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .task-container {
        padding: 10px 15px;
        margin-top: 10px;
    }

    .dashboard-task {
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
    }

    .task-label {
        font-size: 0.9rem;
    }

    .task-text {
        font-size: 0.85rem;
        line-height: 1.4;
    }
}

/* Extra small screens */
@media screen and (max-width: 480px) {
    .task-container {
        margin: 10px 5px;
    }
}

