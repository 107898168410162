/* FeatureToggles.css */
@import url('https://fonts.googleapis.com/css2?family=SF+Pro+Text:wght@400;500;600&display=swap');

.feature-toggles {
    background: rgba(26, 26, 26, 0.95);
    border: 1px solid rgba(216, 255, 96, 0.15);
    border-radius: 12px;
    padding: 20px;
    margin: 20px 0;
}

.feature-toggles h3 {
    color: #D8FF60;
    font-size: 1.2rem;
    margin: 0 0 20px 0;
    font-weight: 600;
}

.toggles-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 16px;
}

.toggle-item {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 12px;
    background: rgba(42, 42, 42, 0.8);
    border-radius: 8px;
    transition: all 0.3s ease;
    cursor: pointer;
    user-select: none;
}

.toggle-item.enabled {
    border: 1px solid rgba(216, 255, 96, 0.3);
    background: rgba(216, 255, 96, 0.1);
}

.toggle-item:hover {
    transform: translateY(-1px);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.toggle-item.updating {
    opacity: 0.7;
    cursor: not-allowed;
    border: 1px solid rgba(216, 255, 96, 0.3);
}

.toggle-item.disabled {
    border: 1px solid rgba(255, 255, 255, 0.1);
    opacity: 0.7;
}

.toggle-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    flex-shrink: 0;
}

.enabled .toggle-icon {
    background: rgba(216, 255, 96, 0.2);
    color: #D8FF60;
}

.disabled .toggle-icon {
    background: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
}

.toggle-label {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
}

.feature-toggles-loading,
.feature-toggles-error {
    background: rgba(26, 26, 26, 0.95);
    border: 1px solid rgba(216, 255, 96, 0.15);
    border-radius: 12px;
    padding: 20px;
    margin: 20px 0;
    text-align: center;
    color: #ffffff;
}

.loading-spinner, .toggle-spinner {
    width: 24px;
    height: 24px;
    border: 2px solid rgba(216, 255, 96, 0.3);
    border-top-color: #D8FF60;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin: 0 auto 12px;
}

.toggle-spinner {
    width: 16px;
    height: 16px;
    margin: 0;
    border-width: 1.5px;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.retry-button {
    margin-top: 12px;
    padding: 8px 16px;
    background: linear-gradient(135deg, #D8FF60 0%, #A8FF00 100%);
    color: #000000;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.retry-button:hover {
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(216, 255, 96, 0.2);
}

.retry-status {
    margin-top: 16px;
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .feature-toggles {
        padding: 16px;
        margin: 16px 0;
    }

    .toggles-grid {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .toggle-item {
        padding: 10px;
    }

    .toggle-label {
        font-size: 13px;
    }
}

/* Remove any conflicting styles */
.feature-toggles,
.toggle-item,
.toggle-list,
.switch,
.slider {
    box-shadow: none !important;
    text-shadow: none !important;
}

/* Override any dark mode enhancements */
@media (prefers-color-scheme: dark) {
    .feature-toggles,
    .toggle-item,
    .toggle-list {
        background: transparent !important;
    }
    
    .toggle-item {
        background: rgba(40, 40, 40, 0.95) !important;
    }
    
    .toggle-item.disabled {
        background: rgba(80, 80, 80, 0.95) !important;
    }
}

/* Mobile adjustments */
@media screen and (max-width: 991px) {
    .feature-toggles {
        padding: 16px !important;
    }

    .toggle-item {
        padding: 14px !important;
        min-height: 52px !important;
    }

    .feature-toggles h3 {
        font-size: 1.1rem !important;
        margin-bottom: 16px !important;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .feature-toggles {
        margin: 16px;
        border-radius: 12px;
    }
}

@media (max-width: 480px) {
    .feature-toggles {
        margin: 12px;
        border-radius: 14px;
    }

    .feature-toggles h3 {
        font-size: 24px;
        letter-spacing: -0.6px;
    }
    
    .toggle-label {
        font-size: 16px;
        max-width: calc(100% - 70px); /* Prevent text from overlapping toggle */
        line-height: 1.3;
    }
    
    /* Ensure toggle stays the right size on mobile */
    .toggle {
        margin-left: 12px;
    }
}

/* Safe Area Support for Modern Phones */
@supports (padding: max(0px)) {
    .feature-toggles {
        padding-left: max(16px, env(safe-area-inset-left));
        padding-right: max(16px, env(safe-area-inset-right));
    }
}

.feature-toggles-container {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    padding: 24px;
    width: 100%;
}

.feature-toggles-title {
    color: #D8FF60;
    font-size: 1.2rem;
    margin: 0 0 20px 0;
}

.feature-toggles-list {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 8px;
}

.feature-toggle-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    border: 1px solid rgba(216, 255, 96, 0.05);
    transition: all 0.3s ease;
}

.feature-toggle-item:hover {
    border-color: rgba(216, 255, 96, 0.1);
    background: rgba(0, 0, 0, 0.3);
}

.toggle-label {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
}

.toggle-switch {
    position: relative;
    width: 51px;
    height: 31px;
    background-color: rgba(120, 120, 128, 0.32);
    border-radius: 31px;
    cursor: pointer;
    transition: all 0.4s ease;
    padding: 2px;
}

.toggle-switch.enabled {
    background-color: #34C759;
}

.toggle-slider {
    position: relative;
    width: 27px;
    height: 27px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transition: transform 0.4s cubic-bezier(0.4, 0.0, 0.2, 1);
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-switch.enabled .toggle-slider {
    transform: translateX(20px);
}

.toggle-switch.loading {
    opacity: 0.7;
    cursor: not-allowed;
}

.toggle-loading {
    width: 14px;
    height: 14px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid #34C759;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Hover effects */
.toggle-switch:hover {
    background-color: rgba(120, 120, 128, 0.36);
}

.toggle-switch.enabled:hover {
    background-color: #30B955;
}

/* Active/pressed state */
.toggle-switch:active .toggle-slider {
    width: 32px;
    border-radius: 14px;
}

.toggle-switch.enabled:active .toggle-slider {
    transform: translateX(15px);
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .feature-toggles-container {
        padding: 16px;
    }

    .feature-toggle-item {
        padding: 10px;
    }

    .toggle-switch {
        width: 46px;
        height: 28px;
    }

    .toggle-slider {
        width: 24px;
        height: 24px;
    }

    .toggle-switch.enabled .toggle-slider {
        transform: translateX(18px);
    }
}

.feature-toggles-error {
    background: rgba(255, 59, 48, 0.1);
    border: 1px solid rgba(255, 59, 48, 0.2);
    color: #FF3B30;
    padding: 12px 16px;
    border-radius: 8px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
}

.feature-toggles-error i {
    font-size: 1.1rem;
}
