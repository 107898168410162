@import './styles/variables.css';

/* Reset and Base Styles */
body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    width: 100%;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: auto !important;
    height: 100%;
}

/* Mobile Input Fix */
.input-field {
    -webkit-user-select: text;
    user-select: text;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    z-index: 1;
}

/* Main Container */
.app-container {
    min-height: 100vh;
    width: 100%;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
}

/* Main Content Area */
.main-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding-top: 80px;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
    width: 100%;
    margin-left: 0; /* Default to no margin */
    transition: margin-left 0.3s ease, width 0.3s ease;
    min-height: 100vh;
}

/* Global Content Wrapper - Use this instead of individual page content-wrapper classes */
.global-content-wrapper {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

/* Only apply sidebar margin on desktop when sidebar is present */
@media (min-width: 769px) {
    .main-content.with-sidebar {
        margin-left: 280px;
        width: calc(100% - 280px);
    }

    .with-sidebar .global-content-wrapper {
        max-width: calc(1200px - 280px);
        width: 100%;
    }
}

/* Full Screen Mode - ensure no sidebar space */
.main-content.full-screen {
    margin-left: 0 !important;
    width: 100% !important;
    padding-top: 80px;
}

/* Footer */
.footer {
    margin-top: auto;
    width: 100%;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
    position: relative;
    z-index: 1;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
    .main-content,
    .main-content.with-sidebar,
    .main-content.full-screen {
        margin-left: 0 !important;
        padding-top: 60px;
        width: 100% !important;
    }

    .global-content-wrapper {
        padding: 15px;
        width: 100%;
        max-width: 100%;
        min-height: calc(100vh - 60px); /* Ensure full height minus mobile header */
    }

    .app-container {
        position: relative;
        height: auto;
        overflow-y: visible;
        -webkit-overflow-scrolling: touch;
    }
}

/* Handle notched phones */
@supports (padding: max(0px)) {
    .app-container {
        padding-left: env(safe-area-inset-left);
        padding-right: env(safe-area-inset-right);
        padding-bottom: env(safe-area-inset-bottom);
    }
}

/* Global Mobile Input Fix */
html {
    height: -webkit-fill-available;
}

/* Fix for input focus on mobile */
input, 
textarea, 
select {
    font-size: 16px !important; /* Prevents iOS zoom */
    -webkit-user-select: text;
    user-select: text;
    -webkit-appearance: none;
    appearance: none;
    position: relative;
    z-index: 2;
}

/* Container class for pages */
.page-container {
    min-height: 100vh;
    min-height: -webkit-fill-available;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: visible;
    -webkit-overflow-scrolling: touch;
}

/* Add or update these classes */
.app-container.sidebar-hidden .main-content {
    margin-left: 0;
    width: 100%;
}

.main-content.full-width {
    width: 100%;
    margin-left: 0;
}

/* Reset any smooth scroll behaviors */
* {
    scroll-behavior: auto !important;
}

#root {
    min-height: 100%;
    position: relative;
}
