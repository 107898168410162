/* PasswordCheckPage.css */

/* General Page Styles */
.password-check-page {
    margin: 0;
    padding: var(--navbar-height) 0 0 0; /* Add padding top equal to navbar height */
    min-height: 100vh;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
    color: #e0e0e0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    box-sizing: border-box;
}

/* Content Wrapper */
.content-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: var(--mobile-breakpoint)) {
    .password-check-page {
        padding-top: var(--mobile-navbar-height);
    }
}

/* Card Container */
.card {
    background-color: rgba(30, 30, 30, 0.8);
    padding: 30px;
    border-radius: 12px;
    border: 1px solid #333;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 40px;
    transition: background-color 0.3s ease, transform 0.3s ease;
    line-height: 1.6;
    letter-spacing: 0.2px;
    backdrop-filter: blur(10px);
}

.card:hover {
    background-color: rgba(35, 35, 35, 0.9);
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.3);
}

/* Header Section */
.header-section {
    text-align: center;
    padding: 0 20px 20px;
    margin-bottom: 20px;
}

.header-section h1 {
    font-size: 2.5rem;
    color: #D8FF60;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.header-section h2 {
    font-size: 1.1rem;
    color: #e0e0e0;
    margin-bottom: 1.5rem;
    line-height: 1.6;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
}

.header-section h3 {
    font-size: 1.3rem;
    color: #fff;
    margin-top: 20px;
}

.header-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-top: 15px;
}

/* Security Notice */
.security-notice {
    background: rgba(216, 255, 96, 0.1);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 8px;
    padding: 12px 16px;
    margin: 20px auto;
    max-width: 600px;
    font-size: 0.95rem;
    color: #D8FF60;
    line-height: 1.5;
    display: flex;
    align-items: center;
    gap: 8px;
}

/* Privacy Statement */
.privacy-statement {
    font-size: 0.9rem;
    color: #888;
    text-align: center;
    max-width: 500px;
    margin: 20px auto;
    line-height: 1.5;
    padding: 12px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 6px;
}

/* Featured In Section */
.featured-in-section {
    margin-top: 20px;
    text-align: center;
}

.featured-in-section h3 {
    font-size: 1.3rem;
    color: #d4ff00;
    margin-bottom: 10px;
}

.featured-in-section p {
    color: #ccc;
    font-size: 1rem;
}

/* Password Check Form */
.password-check-form {
    max-width: 500px;
    margin: 30px auto;
    padding: 0 20px;
}

.input-group {
    margin-bottom: 20px;
}

.password-input-wrapper {
    position: relative;
    width: 100%;
}

.password-input {
    width: 100%;
    padding: 12px;
    font-size: 1rem;
    border-radius: 10px;
    border: 1px solid #333;
    background-color: rgba(25, 25, 25, 0.9);
    color: #fff;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.password-input:focus {
    border-color: #D8FF60;
    outline: none;
    box-shadow: 0 0 0 2px rgba(216, 255, 96, 0.1);
}

.toggle-visibility-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    color: #888;
    transition: color 0.3s ease;
}

.toggle-visibility-button:hover {
    color: #D8FF60;
}

.check-button {
    width: 100%;
    padding: 12px;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    border-radius: 5px;
    background: linear-gradient(45deg, #00ccff, #00ff99);
    border: none;
    color: #111;
    cursor: pointer;
    transition: transform 0.2s;
}

.check-button:hover {
    transform: scale(1.05);
}

.error-message {
    color: #ff4d4d;
    margin-top: 10px;
    text-align: center;
    font-size: 1rem;
}

/* Analysis Results */
.analysis-results {
    max-width: 600px;
    margin: 20px auto;
    padding: 20px;
    background-color: rgba(35, 35, 35, 0.9);
    border-radius: 12px;
    text-align: left;
    border: 1px solid #333;
}

.analysis-results h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #ffffff;
    letter-spacing: -0.3px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}

.safety-score-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.score-label {
    font-size: 1.2rem;
    font-weight: bold;
}

.strength-label {
    font-size: 1.1rem;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #333;
}

.result-box {
    margin-bottom: 20px;
}

.result-box h3 {
    font-size: 1.1rem;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 0.75rem;
}

.subsection-icon {
    margin-right: 10px;
    font-size: 1.3rem;
}

.result-list {
    list-style-type: none;
    padding: 0;
}

.attack-badge {
    display: inline-block;
    margin-right: 10px;
    padding: 5px 10px;
    border-radius: 4px;
    background-color: #444;
}

.suggestions-list {
    list-style-type: disc;
    padding-left: 20px;
    color: #cccccc;
    line-height: 1.7;
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
}

.suggestions-list li {
    margin-bottom: 0.75rem;
    max-width: 65ch;
}

.crack-time {
    font-weight: bold;
    color: #D8FF60;
}

/* Password Generation */
.password-generation {
    text-align: center;
    background-color: #2a2a2a;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.4);
    transition: background-color 0.3s ease;
    position: relative;
    top: 0;
    margin-bottom: 20px;
}

.password-generation:hover {
    background-color: #333;
}

.password-generation h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.generate-password-button {
    background: linear-gradient(45deg, #00ccff, #d4ff00);
    color: #0a0a0a;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    padding: 15px 35px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    transition: background-color 0.3s ease, transform 0.2s;
    border: none;
}

.generate-password-button:hover {
    transform: scale(1.02);
    background: linear-gradient(45deg, #00ccff, #e4ff00);
}

.generated-password-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(25, 25, 25, 0.9);
    padding: 15px 20px;
    border-radius: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
    border: 1px solid #333;
    transition: background-color 0.3s ease;
}

.generated-password-box:hover {
    background-color: rgba(35, 35, 35, 0.9);
}

.generated-password {
    font-size: 1.2rem;
    color: #fff;
    margin-right: 10px;
    word-break: break-all;
    text-align: center;
}

.copy-button {
    background: none;
    border: none;
    color: #d4ff00;
    cursor: pointer;
    font-size: 1.5rem;
    transition: color 0.3s ease, transform 0.2s;
}

.copy-button:hover {
    color: #c4ef00;
    transform: scale(1.2);
}

.copy-success {
    color: #66ff66;
    margin-top: 10px;
    font-size: 1rem;
    animation: fadeInOut 3s forwards;
    text-align: center;
}

@keyframes fadeInOut {
    0% { opacity: 0; }
    10% { opacity: 1; }
    90% { opacity: 1; }
    100% { opacity: 0; }
}

.crack-time-display {
    margin-top: 15px;
    background-color: rgba(25, 25, 25, 0.9);
    padding: 15px;
    border-radius: 10px;
    border: 1px solid #333;
    transition: background-color 0.3s ease;
}

.crack-time-display:hover {
    background-color: rgba(35, 35, 35, 0.9);
}

.crack-time-display p {
    color: #ccc;
    font-size: 1rem;
    margin: 10px 0;
}

/* Password Tips Section */
.password-tips-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.password-tips-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.password-tips-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tip-item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
}

.tip-icon {
    color: #d4ff00;
    margin-right: 15px;
    font-size: 1.5rem;
    flex-shrink: 0;
    margin-top: 5px;
}

.tip-item p {
    color: #ccc;
    font-size: 1.1rem;
    margin: 0;
    line-height: 1.5;
}

/* Video Section */
.password-manager-section {
    max-width: 900px;
    margin: 0 auto 40px auto;
    text-align: center;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.password-manager-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.password-manager-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.password-manager-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 25px;
}

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    border-radius: 12px;
    border: 1px solid #333;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    margin-bottom: 25px;
}

.video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 8px;
}

.button-link {
    background-color: #d4ff00;
    color: #111;
    padding: 15px 30px;
    border-radius: 8px;
    text-decoration: none;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, transform 0.2s;
    display: inline-block;
}

.button-link:hover {
    background-color: #c4ef00;
    transform: scale(1.05);
}

/* How to Create a Secure Password Section */
.create-secure-password-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.create-secure-password-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.create-secure-password-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.create-secure-password-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.best-practices-list {
    list-style: disc inside;
    padding-left: 20px;
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.6;
}

.best-practices-list li {
    margin-bottom: 15px;
}

/* The Importance of Password Security Section */
.importance-password-security-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.importance-password-security-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.importance-password-security-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.importance-password-security-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.consequences-list {
    list-style: disc inside;
    padding-left: 20px;
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.6;
}

.consequences-list li {
    margin-bottom: 15px;
}

/* Consequences of Stolen Passwords Section */
.consequences-stolen-passwords-section {
    max-width: 900px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.consequences-stolen-passwords-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.consequences-stolen-passwords-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.consequences-stolen-passwords-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.business-impact-list,
.consumer-impact-list {
    list-style: disc inside;
    padding-left: 20px;
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.6;
}

.business-impact-list li,
.consumer-impact-list li {
    margin-bottom: 15px;
}

/* Additional Online Security Measures Section */
.additional-security-measures-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.additional-security-measures-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.additional-security-measures-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.additional-security-measures-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.security-measures-list {
    list-style: disc inside;
    padding-left: 20px;
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.6;
}

.security-measures-list li {
    margin-bottom: 15px;
}

/* How Our Password Checker Works Section */
.how-works-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.how-works-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.how-works-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.how-works-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.checker-work-list {
    list-style: disc inside;
    padding-left: 20px;
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.6;
}

.checker-work-list li {
    margin-bottom: 15px;
}

/* Frequently Asked Questions (FAQ) Section */
.faq-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.faq-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.faq-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 25px;
    text-align: center;
}

.faq-item {
    margin-bottom: 25px;
}

.faq-item h3 {
    font-size: 1.3rem;
    color: #d4ff00;
    margin-bottom: 10px;
}

.faq-item p {
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Generate a Secure Password Instantly Section */
.generate-password-instantly-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
}

.generate-password-instantly-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.generate-password-instantly-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.generate-password-instantly-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 20px;
}

.generate-password-instantly-section .generate-password-button {
    background: linear-gradient(45deg, #00ccff, #d4ff00);
    color: #0a0a0a;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    padding: 15px 35px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    transition: background-color 0.3s ease, transform 0.2s;
    border: none;
    margin-bottom: 15px;
}

.generate-password-instantly-section .generate-password-button:hover {
    background-color: #c4ef00;
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(212, 255, 0, 0.7);
}

.generate-password-instantly-section .generated-password-box {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #1e1e1e;
    padding: 15px 20px;
    border-radius: 8px;
    margin-top: 20px;
    flex-wrap: wrap;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: background-color 0.3s ease;
}

.generate-password-instantly-section .generated-password-box:hover {
    background-color: #333;
}

.generate-password-instantly-section .generated-password {
    font-size: 1.2rem;
    color: #fff;
    margin-right: 10px;
    word-break: break-all;
    text-align: center;
}

.generate-password-instantly-section .copy-button {
    background: none;
    border: none;
    color: #d4ff00;
    cursor: pointer;
    font-size: 1.5rem;
    transition: color 0.3s ease, transform 0.2s;
}

.generate-password-instantly-section .copy-button:hover {
    color: #c4ef00;
    transform: scale(1.2);
}

.generate-password-instantly-section .copy-success {
    color: #66ff66;
    margin-top: 10px;
    font-size: 1rem;
    animation: fadeInOut 3s forwards;
    text-align: center;
}

.generate-password-instantly-section .crack-time-display {
    margin-top: 15px;
    background-color: #1e1e1e;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.2);
    transition: background-color 0.3s ease;
}

.generate-password-instantly-section .crack-time-display:hover {
    background-color: #333;
}

.generate-password-instantly-section .crack-time-display p {
    color: #ccc;
    font-size: 1rem;
    margin: 10px 0;
}

.generate-password-instantly-section p:last-child {
    margin-top: 15px;
    font-size: 1rem;
    color: #ccc;
}

/* Why Strong Passwords Matter Section */
.why-strong-passwords-matter-section {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.why-strong-passwords-matter-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.why-strong-passwords-matter-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.strong-passwords-list {
    list-style: none;
    padding-left: 0;
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.6;
}

.strong-passwords-list li {
    margin-bottom: 15px;
    position: relative;
    padding-left: 25px;
}

.strong-passwords-list li::before {
    content: '•';
    position: absolute;
    left: 0;
    color: #d4ff00;
    font-size: 1.5rem;
    line-height: 1;
    top: 0;
}

/* Watch Our Password Security Video Section */
.watch-video-section {
    max-width: 900px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.watch-video-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.watch-video-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
}

.watch-video-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 25px;
}

.watch-video-section .video-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    border-radius: 8px;
    border: 2px solid #00ccff;
    box-shadow: 0 0 20px rgba(0, 204, 255, 0.5);
    margin-bottom: 25px;
}

.watch-video-section .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 8px;
}

.watch-video-section .button-link {
    display: block;
    width: fit-content;
    margin: 20px auto;
    padding: 15px 30px;
    background-color: #d4ff00;
    color: #111;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s, transform 0.3s;
}

.watch-video-section .button-link:hover {
    background-color: #c4ef00;
    transform: scale(1.05);
}

/* Info Check Section */
.info-check-section {
    max-width: 700px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 30px 40px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    transition: background-color 0.3s ease, transform 0.3s ease;
    text-align: center;
}

.info-check-section:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.info-check-section h2 {
    font-size: 2rem;
    color: #d4ff00;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.info-check-section p {
    color: #ccc;
    font-size: 1.1rem;
    margin-bottom: 25px;
}

.info-check-section form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
}

.info-check-section input {
    width: 48%;
    padding: 15px;
    background-color: #1e1e1e;
    border: 2px solid #444;
    border-radius: 8px;
    color: #fff;
    font-size: 1rem;
    transition: border 0.3s ease;
}

.info-check-section input:focus {
    border-color: #d4ff00;
    outline: none;
}

.info-check-section input::placeholder {
    color: #ccc;
}

.info-check-section .check-info-button {
    background: linear-gradient(45deg, #00ccff, #d4ff00);
    color: #0a0a0a;
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    padding: 15px 30px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1.1rem;
    transition: background-color 0.3s ease, transform 0.2s ease;
    border: none;
}

.info-check-section .check-info-button:hover {
    background-color: #c4ef00;
    transform: scale(1.05);
    box-shadow: 0 0 15px rgba(212, 255, 0, 0.7);
}

.info-check-section .info-check-result {
    margin-top: 20px;
    padding: 15px;
    border-radius: 8px;
    font-size: 1rem;
    transition: background-color 0.3s ease, color 0.3s ease;
}

.info-check-section .info-check-result.success {
    background-color: #66ff66;
    color: #111;
}

.info-check-section .info-check-result.error {
    background-color: #ff4d4d;
    color: #fff;
}

/* Footer */
.footer {
    background-color: #2a2a2a;
    padding: 20px 40px;
    text-align: center;
    color: #ccc;
    font-size: 1rem;
    border-top: 1px solid #444;
    margin-top: 40px;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .content-wrapper {
        padding-top: 60px;
    }
}

@media (max-width: 992px) {
    .password-check-form {
        max-width: 100%;
    }

    .info-check-section input {
        width: 90%;
    }
}

@media (max-width: 768px) {
    .password-check-page {
        padding: 40px 15px;
    }

    .content-wrapper {
        padding-top: 60px; /* Adjust padding for mobile */
    }

    .header-section h1 {
        font-size: 2.5rem;
    }

    .header-section h2 {
        font-size: 1.3rem;
    }

    .header-section h3 {
        font-size: 1.1rem;
    }

    .header-section p {
        font-size: 1rem;
    }

    .privacy-statement {
        font-size: 0.9rem;
    }

    .featured-in-section h3 {
        font-size: 1.1rem;
    }

    .featured-in-section p {
        font-size: 0.9rem;
    }

    .create-secure-password-section,
    .importance-password-security-section,
    .consequences-stolen-passwords-section,
    .additional-security-measures-section,
    .how-works-section,
    .faq-section,
    .generate-password-instantly-section,
    .why-strong-passwords-matter-section,
    .watch-video-section,
    .info-check-section {
        padding: 20px 25px;
    }

    .password-tips-section,
    .password-manager-section {
        padding: 20px 25px;
    }

    .password-tips-section h2,
    .why-strong-passwords-matter-section h2,
    .why-strong-passwords-section h2,
    .how-works-section h2,
    .faq-section h2,
    .generate-password-instantly-section h2,
    .watch-video-section h2,
    .info-check-section h2,
    .analysis-results h2,
    .consequences-stolen-passwords-section h2,
    .importance-password-security-section h2,
    .create-secure-password-section h2,
    .password-generation h2 {
        font-size: 1.8rem;
    }

    .password-tips-section .tip-item p,
    .why-strong-passwords-matter-section .strong-passwords-list li,
    .password-manager-section p,
    .how-works-section p,
    .faq-section .faq-item p,
    .consequences-stolen-passwords-section p,
    .create-secure-password-section .best-practices-list li,
    .additional-security-measures-section .security-measures-list li,
    .why-strong-passwords-section p {
        font-size: 1rem;
    }

    .password-tips-section .tip-icon,
    .strong-passwords-list li::before,
    .result-list li::before {
        font-size: 1.2rem;
    }

    .password-manager-section .button-link {
        font-size: 1rem;
    }

    .watch-video-section .button-link {
        font-size: 1rem;
    }

    .faq-item h3 {
        font-size: 1.1rem;
    }

    .generate-password-instantly-section .generate-password-button,
    .info-check-section .check-info-button,
    .check-button,
    .generate-password-button {
        font-size: 1rem;
        padding: 12px 25px;
    }

    .generated-password {
        font-size: 1rem;
    }

    .copy-button {
        font-size: 1.3rem;
    }

    .result-box h3,
    .analysis-results h2,
    .faq-section h2 {
        font-size: 1.5rem;
    }

    .result-box p,
    .consequences-stolen-passwords-section p,
    .create-secure-password-section p,
    .additional-security-measures-section p,
    .importance-password-security-section p,
    .how-works-section p,
    .faq-section .faq-item p,
    .why-strong-passwords-section p,
    .password-manager-section p,
    .why-strong-passwords-matter-section .strong-passwords-list li,
    .password-tips-section .tip-item p,
    .generate-password-instantly-section p {
        font-size: 0.95rem;
    }

    .password-manager-section .video-container iframe,
    .watch-video-section .video-container iframe {
        height: 200px;
    }
}

/* Additional Enhancements */

/* Smooth Transitions for All Hover Effects */
.card,
.password-check-form,
.analysis-results,
.password-generation,
.password-tips-section,
.password-manager-section,
.create-secure-password-section,
.importance-password-security-section,
.consequences-stolen-passwords-section,
.additional-security-measures-section,
.how-works-section,
.faq-section,
.generate-password-instantly-section,
.why-strong-passwords-matter-section,
.watch-video-section,
.info-check-section,
.generated-password-box,
.result-box,
.crack-time-display {
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
}

/* Button Disabled State */
.check-button:disabled,
.generate-password-button:disabled,
.check-info-button:disabled,
.button-link:disabled {
    background-color: #444;
    color: #888;
    cursor: not-allowed;
    transform: none;
}

.check-button:disabled:hover,
.generate-password-button:disabled:hover,
.check-info-button:disabled:hover,
.button-link:disabled:hover {
    background-color: #444;
    transform: none;
}

/* Consistent Icon Styling */
.section-icon,
.subsection-icon {
    margin-right: 10px;
    color: #d4ff00;
}

.section-icon {
    font-size: 1.5rem;
}

.subsection-icon {
    font-size: 1.3rem;
}

/* Analysis Progress (Optional) */
.analysis-progress {
    max-width: 800px;
    margin: 0 auto 40px auto;
    background-color: #2a2a2a;
    padding: 20px 30px;
    border-radius: 12px;
    box-shadow: 0 6px 12px rgba(0,0,0,0.3);
    text-align: center;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.analysis-progress:hover {
    background-color: #333;
    transform: translateY(-5px);
}

.analysis-progress h3 {
    font-size: 1.5rem;
    color: #d4ff00;
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: rgba(25, 25, 25, 0.9);
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
    border: 1px solid #333;
}

.progress {
    width: 0;
    height: 100%;
    background: linear-gradient(90deg, #D8FF60, #A3FF00);
    animation: progress 3s linear infinite;
}

.analysis-progress p {
    color: #ccc;
    font-size: 1rem;
    margin: 0;
}

/* Glowing Effect for Progress Bar */
@keyframes glowingProgress {
    0% { box-shadow: 0 0 5px #d4ff00; }
    50% { box-shadow: 0 0 20px #d4ff00; }
    100% { box-shadow: 0 0 5px #d4ff00; }
}

/* Spinner Animation for Check Button (Optional) */
@keyframes fa-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.fa-spin {
    animation: fa-spin 2s infinite linear;
}

/* Accessibility Enhancements */
:focus {
    outline: 2px dashed #d4ff00;
    outline-offset: 4px;
}

/* Scrollbar Styling (Optional) */
.password-check-page::-webkit-scrollbar {
    width: 12px;
}

.password-check-page::-webkit-scrollbar-track {
    background: #1e1e1e;
}

.password-check-page::-webkit-scrollbar-thumb {
    background-color: #00ccff;
    border-radius: 6px;
    border: 3px solid #1e1e1e;
    box-shadow: 0 0 10px rgba(0, 204, 255, 0.7);
}

.password-check-page::-webkit-scrollbar-thumb:hover {
    background-color: #d4ff00;
}

/* Footer Styling */
.footer {
    background-color: #2a2a2a;
    padding: 20px 40px;
    text-align: center;
    color: #ccc;
    font-size: 1rem;
    border-top: 1px solid #444;
    margin-top: 40px;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.footer:hover {
    background-color: #333;
    transform: translateY(-5px);
}

/* Responsive Adjustments for Mobile Devices */
@media (max-width: 576px) {
    .content-wrapper {
        padding-top: 0;
        min-height: calc(100vh - 50px); /* Adjust for smaller mobile navbar */
    }
    
    .header-section {
        padding: 20px 15px;
    }

    .header-section h1 {
        font-size: 2rem;
    }

    .header-section h2 {
        font-size: 1.2rem;
    }

    .header-section h3 {
        font-size: 1rem;
    }

    .header-section p {
        font-size: 0.95rem;
    }

    .privacy-statement,
    .featured-in-section p,
    .why-strong-passwords-section p,
    .create-secure-password-section p,
    .importance-password-security-section p,
    .consequences-stolen-passwords-section p,
    .additional-security-measures-section p,
    .how-works-section p,
    .faq-section .faq-item p,
    .generate-password-instantly-section p,
    .why-strong-passwords-matter-section .strong-passwords-list li,
    .password-tips-section .tip-item p {
        font-size: 0.9rem;
    }

    .check-button,
    .generate-password-button,
    .check-info-button,
    .button-link {
        font-size: 1rem;
        padding: 12px 25px;
    }

    .generated-password {
        font-size: 1rem;
    }

    .copy-button {
        font-size: 1.3rem;
    }

    .result-box h3,
    .analysis-results h2,
    .faq-section h2 {
        font-size: 1.5rem;
    }

    .result-box p,
    .consequences-stolen-passwords-section p,
    .create-secure-password-section p,
    .additional-security-measures-section p,
    .importance-password-security-section p,
    .how-works-section p,
    .faq-section .faq-item p,
    .why-strong-passwords-section p,
    .password-manager-section p,
    .why-strong-passwords-matter-section .strong-passwords-list li,
    .password-tips-section .tip-item p,
    .generate-password-instantly-section p {
        font-size: 0.95rem;
    }

    .password-manager-section .video-container iframe,
    .watch-video-section .video-container iframe {
        height: 200px;
    }

    .tips-list,
    .strong-passwords-list,
    .best-practices-list,
    .consequences-list,
    .security-measures-list,
    .checker-work-list,
    .result-list,
    .faq-section .faq-item {
        padding-left: 15px;
    }

    .password-tips-section .tip-icon,
    .strong-passwords-list li::before,
    .result-list li::before {
        font-size: 1.2rem;
    }
}

/* Animations */
@keyframes neonPulse {
    0% { opacity: 0.5; }
    50% { opacity: 1; }
    100% { opacity: 0.5; }
}

.section-icon, .subsection-icon {
    animation: neonPulse 2s infinite;
}

.analysis-animation {
    margin-top: 20px;
    text-align: center;
}

.progress-bar {
    width: 100%;
    height: 10px;
    background-color: #1e1e1e;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.progress {
    width: 0;
    height: 100%;
    background-color: #00ccff;
    animation: progress 3s linear infinite;
}

@keyframes progress {
    0% { width: 0; }
    100% { width: 100%; }
}

/* Update button styles to match the global theme */
.check-button,
.generate-password-button,
.check-info-button,
.button-link {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
    padding: 15px 30px;
    border-radius: 10px;
    border: none;
    font-size: 0.9375rem;
    font-weight: 500;
    letter-spacing: 0.3px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    display: inline-block;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.2);
}

.check-button:hover,
.generate-password-button:hover,
.check-info-button:hover,
.button-link:hover {
    background: linear-gradient(45deg, #B8FF50, #8AFF20) !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(184, 255, 80, 0.4);
}

/* Override any conflicting styles */
.generate-password-instantly-section .generate-password-button {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
    text-shadow: none;
}

.watch-video-section .button-link {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
}

