/* Base Styles */
.doorstep-protection-page {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    color: #ffffff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 20px;
    line-height: 1.8;
}

/* Header with Shield */
.protection-header {
    text-align: center;
    margin-bottom: 40px;
    padding: 20px;
}

.shield-icon {
    font-size: 4.5rem;
    color: #42ffb5;
    margin-bottom: 20px;
}

.protection-header h1 {
    font-size: 2.8rem;
    color: #42ffb5;
    margin: 0;
    line-height: 1.3;
}

/* Main Container */
.safety-plan {
    background: #1a1a1a;
    padding: 35px;
    border-radius: 16px;
    border: 4px solid #42ffb5;
    box-shadow: 0 4px 20px rgba(66, 255, 181, 0.2);
}

/* Risk Level Banner */
.risk-level {
    text-align: center;
    padding: 25px;
    margin: -35px -35px 30px -35px;
    background: #2d2d2d;
    border-radius: 12px 12px 0 0;
    border-bottom: 4px solid #42ffb5;
}

.risk-level h2 {
    font-size: 2.2rem;
    color: #ffffff;
    margin: 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
}

/* Situation Summary */
.summary-section {
    background: #2d2d2d;
    padding: 25px;
    border-radius: 12px;
    margin-bottom: 35px;
}

.summary-section h3 {
    color: #42ffb5;
    font-size: 2rem;
    margin-bottom: 20px;
}

.summary-section p {
    font-size: 1.6rem;
    line-height: 1.6;
    color: #ffffff;
}

/* Warning Section */
.warnings-section {
    margin: 35px 0;
}

.warnings-section h3 {
    color: #42ffb5;
    font-size: 2rem;
    margin-bottom: 25px;
}

.warning-card {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 25px;
    background: #2d2d2d;
    border-radius: 12px;
    margin-bottom: 20px;
    border-left: 8px solid #ff4b4b;
}

.warning-icon {
    font-size: 2.2rem;
    color: #ff4b4b;
}

.warning-card p {
    font-size: 1.6rem;
    margin: 0;
    line-height: 1.5;
    color: #ffffff;
}

/* Action Steps */
.actions-section {
    background: #2d2d2d;
    padding: 30px;
    border-radius: 12px;
    margin: 35px 0;
}

.actions-section h3 {
    color: #42ffb5;
    font-size: 2rem;
    margin-bottom: 25px;
    text-align: center;
}

.action-step {
    font-size: 1.6rem;
    padding: 20px;
    background: #1a1a1a;
    border-radius: 12px;
    margin-bottom: 15px;
    border-left: 8px solid #42ffb5;
    color: #ffffff;
}

/* Contact Numbers */
.help-contacts {
    background: #2d2d2d;
    padding: 30px;
    border-radius: 12px;
    margin-top: 35px;
}

.help-contacts h3 {
    color: #42ffb5;
    font-size: 2rem;
    margin-bottom: 25px;
    text-align: center;
}

.contact-button {
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 25px;
    background: #1a1a1a;
    border: none;
    border-radius: 12px;
    color: #ffffff;
    text-decoration: none;
    margin-bottom: 15px;
    transition: all 0.3s ease;
}

.contact-button:hover {
    background: #333333;
    transform: translateY(-2px);
}

.contact-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.contact-info strong {
    font-size: 1.8rem;
    color: #42ffb5;
}

.contact-info span {
    font-size: 1.6rem;
    color: #ffffff;
}

.contact-info small {
    font-size: 1.4rem;
    color: #cccccc;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .doorstep-protection-page {
        padding: 15px;
        font-size: 18px;
    }

    .protection-header h1 {
        font-size: 2.2rem;
    }

    .summary-section p,
    .warning-card p,
    .action-step,
    .contact-info span {
        font-size: 1.4rem;
    }

    .contact-info strong {
        font-size: 1.6rem;
    }

    .contact-info small {
        font-size: 1.2rem;
    }
}

/* Accessibility Improvements */
.contact-button:focus {
    outline: 4px solid #42ffb5;
    outline-offset: 2px;
}

/* High Contrast Mode */
@media (prefers-contrast: high) {
    .safety-plan {
        border-color: #ffffff;
    }

    .contact-button {
        border: 2px solid #ffffff;
    }
}

/* Reduced Motion */
@media (prefers-reduced-motion: reduce) {
    .contact-button:hover {
        transform: none;
    }
}
