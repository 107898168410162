.sites-page {
    padding: 20px;
    min-height: calc(100vh - 64px); /* Adjust for navbar height */
    background: #121212;
    color: #ffffff;
    width: 100%;
    box-sizing: border-box;
}

.app-layout {
    display: flex;
    min-height: 100vh;
    background-color: #121212;
}

.main-content {
    flex: 1;
    margin-left: 280px; /* Width of the sidebar */
    width: calc(100% - 280px);
    min-height: 100vh;
    background-color: #121212;
    padding-top: 0; /* Remove top padding */
}

.sites-header {
    text-align: center;
    margin-bottom: 30px;
    padding-top: 10px; /* Reduced top padding */
}

.sites-header h1 {
    font-size: 2.5rem;
    color: #D8FF60;
    margin-bottom: 15px;
}

.search-filter-container {
    max-width: 800px;
    margin: 30px auto;
    padding: 0 20px;
}

.search-box {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    position: relative;
}

.search-input {
    width: 100%;
    height: 50px;
    background: #1a1a1a;
    border: 2px solid rgba(216, 255, 96, 0.3);
    border-radius: 10px;
    padding: 0 45px;
    font-size: 16px;
    color: #ffffff;
    transition: all 0.3s ease;
}

.search-input:focus {
    outline: none;
    border-color: #D8FF60;
    box-shadow: 0 0 20px rgba(216, 255, 96, 0.2);
}

.search-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #D8FF60;
    font-size: 20px;
}

.category-filters {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    flex-wrap: wrap;
}

.filter-button {
    padding: 10px 20px;
    border-radius: 8px;
    border: none;
    background: rgba(0, 0, 0, 0.3);
    color: #ffffff;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 8px;
    transition: all 0.3s ease;
    font-weight: 600;
}

.filter-button:hover,
.filter-button.active {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.2);
}

.filter-button svg {
    color: #D8FF60;
    font-size: 1.1rem;
    transition: all 0.3s ease;
}

.filter-button:hover svg,
.filter-button.active svg {
    color: #111 !important;
}

.categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    padding: 20px 0;
}

.category-card {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 15px;
    padding: 20px;
    transition: all 0.3s ease;
    cursor: pointer;
}

.category-card:hover {
    transform: translateY(-5px);
    border-color: #D8FF60;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.1);
}

.category-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.category-header h2 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #D8FF60;
    font-size: 1.2rem;
}

.category-header h2 svg {
    color: #D8FF60;
    font-size: 1.2rem;
}

.site-count {
    background: rgba(216, 255, 96, 0.1);
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.9rem;
    border: 1px solid rgba(216, 255, 96, 0.2);
    color: #D8FF60;
}

.category-description {
    color: #ffffff;
    opacity: 0.8;
    margin-bottom: 20px;
    font-size: 0.9rem;
}

.sites-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
    gap: 10px;
    width: 100%;
}

.category-card.expanded .sites-list {
    max-height: 1000px;
}

.site-item {
    color: #ffffff;
    text-decoration: none;
    padding: 10px 15px;
    border-radius: 8px;
    background: rgba(0, 0, 0, 0.3);
    font-size: 0.85rem;
    transition: all 0.3s ease;
    text-align: center;
    border: 1px solid rgba(216, 255, 96, 0.1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    display: block;
    word-break: break-word;
}

.site-item:hover {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.2);
    border: none;
}

@media (max-width: 768px) {
    .main-content {
        margin-left: 0;
        width: 100%;
    }

    .sites-page {
        padding: 20px; /* Reduced padding */
    }

    .sites-header h1 {
        font-size: 2rem;
        margin-top: 10px; /* Add small top margin for mobile */
    }

    .categories-grid {
        grid-template-columns: 1fr;
    }
}

/* Reset and restyle search section */
.sites-page input[type="text"].search-input {
    width: 100%;
    height: 50px;
    background-color: #1a1a1a !important;
    border: 2px solid rgba(216, 255, 96, 0.3) !important;
    border-radius: 10px !important;
    padding: 0 45px !important;
    font-size: 16px !important;
    color: #ffffff !important;
    transition: all 0.3s ease !important;
    display: block !important;
    margin: 0 auto !important;
    box-sizing: border-box !important;
}

.sites-page .search-box {
    width: 100%;
    max-width: 600px;
    position: relative;
    display: block;
    margin: 0 auto;
}

.sites-page .search-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    color: #D8FF60;
    font-size: 20px;
    z-index: 1;
    pointer-events: none;
}

.sites-page input[type="text"].search-input:focus {
    outline: none !important;
    border-color: #D8FF60 !important;
    box-shadow: 0 0 20px rgba(216, 255, 96, 0.2) !important;
}

.sites-page input[type="text"].search-input::placeholder {
    color: rgba(255, 255, 255, 0.5) !important;
}

/* Responsive adjustments */
@media screen and (max-width: 480px) {
    .site-item {
        font-size: 0.8rem;
        padding: 8px 12px;
    }

    .sites-list {
        grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    }
}
