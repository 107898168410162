/* Navbar Header */
.navbar-header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: linear-gradient(180deg, rgba(30,30,30,0.95) 0%, rgba(18,18,18,0.98) 100%);
    padding: 0 20px;
    backdrop-filter: blur(10px);
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(216, 255, 96, 0.15);
    border-bottom: 1px solid rgba(216, 255, 96, 0.1);
    height: var(--mobile-navbar-height);
    display: flex;
    align-items: center;
}

/* Navbar content - Flexbox */
.navbar-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Logo Section */
.logo-section {
    display: flex;
    align-items: center;
    position: relative;
    text-decoration: none;
    color: inherit;
    transition: opacity 0.3s ease;
}

.logo-section:hover {
    opacity: 0.9;
}

.logo-section:active {
    opacity: 0.8;
}

.logo-icon {
    color: #D8FF60;
    font-size: 32px;
    filter: drop-shadow(0 0 8px rgba(216, 255, 96, 0.6));
    animation: subtlePulse 3s ease-in-out infinite;
}

.navbar-title {
    font-family: 'Orbitron', sans-serif;
    font-size: 22px;
    font-weight: bold;
    margin-left: 12px;
    color: white;
    text-shadow: 0 0 10px rgba(216, 255, 96, 0.4);
    letter-spacing: 1px;
}

/* Added Styles for .logo-subtitle */
.logo-subtitle {
    color: #D8FF60; /* Green color similar to main navbar */
    font-size: 0.9rem; /* Slightly smaller than main title */
    font-weight: 500;
    margin-left: 4px;
    text-shadow: 0 0 5px rgba(216, 255, 96, 0.4);
    letter-spacing: 0.5px;
}

/* Hamburger Menu Button */
.menu-button {
    background: none;
    border: 1px solid rgba(216, 255, 96, 0.3);
    border-radius: 8px;
    padding: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.menu-button:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(216, 255, 96, 0.2),
        transparent
    );
    transition: 0.5s;
}

.menu-button:hover:before {
    left: 100%;
}

.menu-icon {
    color: #D8FF60;
    font-size: 28px;
    transition: transform 0.3s ease;
}

/* Slide-In Menu */
.slide-menu {
    position: fixed;
    top: var(--mobile-navbar-height);
    right: -100%;
    width: 100%;
    height: calc(100vh - var(--mobile-navbar-height));
    background: linear-gradient(135deg, rgba(18,18,18,0.98) 0%, rgba(30,30,30,0.95) 100%);
    backdrop-filter: blur(20px);
    padding: 20px;
    transition: right 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 999;
    border-left: 1px solid rgba(216, 255, 96, 0.1);
    overflow-y: auto; /* Enable scrolling for the menu */
    -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
}

.slide-menu.open {
    right: 0;
    box-shadow: -10px 0 30px rgba(0,0,0,0.5);
}

/* Menu List */
.menu-list {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    width: 100%;
}

.menu-item {
    margin: 15px 0;
    opacity: 0;
    transform: translateX(20px);
    transition: all 0.3s ease;
    list-style: none;
}

.slide-menu.open .menu-item {
    opacity: 1;
    transform: translateX(0);
}

.menu-item a {
    color: white;
    text-decoration: none;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-size: 18px;
    display: block;
    padding: 12px 20px;
    border-radius: 8px;
    transition: all 0.3s ease;
    background: rgba(216, 255, 96, 0.05);
    border: 1px solid rgba(216, 255, 96, 0.1);
}

.menu-item a:hover {
    background: rgba(216, 255, 96, 0.1);
    transform: translateX(5px);
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.2);
}

/* Dropdown Menu */
.dropdown-menu {
    list-style: none;
    padding: 0;
    background: rgba(31, 31, 31, 0.95);
    border-radius: 12px;
    margin: 10px 0;
    border: 1px solid rgba(216, 255, 96, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    overflow: hidden;
}

.dropdown-menu li {
    margin: 8px;
}

.dropdown-menu li a {
    padding: 16px 20px;
    font-size: 16px;
    background: rgba(216, 255, 96, 0.03);
    border: 1px solid rgba(216, 255, 96, 0.1);
    display: flex;
    align-items: center;
    color: white;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    transition: background-color 0.3s ease;
}

.dropdown-menu li a:hover {
    background: rgba(216, 255, 96, 0.08);
    box-shadow: 0 0 20px rgba(216, 255, 96, 0.15);
}

/* Free Tools Button */
.free-tools-button {
    width: 100%;
    background: linear-gradient(45deg, rgba(216, 255, 96, 0.1), rgba(184, 255, 80, 0.1));
    color: #D8FF60;
    padding: 15px 30px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 18px;
    border: 1px solid rgba(216, 255, 96, 0.3);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    text-transform: uppercase;
    letter-spacing: 1px;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.1);
}

/* Remove transitions and transforms since Framer Motion will handle them */
.dropdown-menu,
.dropdown-menu li,
.dropdown-menu.active,
.slide-menu.open .dropdown-menu,
.slide-menu.open .dropdown-menu li {
    transition: none;
    transform: none;
    opacity: 1;
    visibility: visible;
}

/* Sign In/Out Button */
.sign-in a,
.sign-in button {
    width: 100%;
    background: transparent;
    color: #D8FF60;
    padding: 15px 30px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
    letter-spacing: 1px;
    border: 1px solid #D8FF60;
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.2);
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.3s ease;
    display: block;
    text-align: center;
    text-decoration: none;
}

.sign-in button {
    width: 100%;
    background: transparent;
    color: #FF4B4B;
    border-color: #FF4B4B;
    box-shadow: 0 0 15px rgba(255, 75, 75, 0.2);
}

.sign-in a:hover {
    background: rgba(216, 255, 96, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 0 20px rgba(216, 255, 96, 0.3);
}

.sign-in button:hover {
    background: rgba(255, 75, 75, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 0 20px rgba(255, 75, 75, 0.3);
}

/* Animations */
@keyframes subtlePulse {
    0% {
        filter: drop-shadow(0 0 8px rgba(216, 255, 96, 0.4));
    }
    50% {
        filter: drop-shadow(0 0 10px rgba(216, 255, 96, 0.6));
    }
    100% {
        filter: drop-shadow(0 0 8px rgba(216, 255, 96, 0.4));
    }
}

/* Stagger animation for menu items */
.slide-menu.open .menu-item:nth-child(1) { transition-delay: 0.1s; }
.slide-menu.open .menu-item:nth-child(2) { transition-delay: 0.2s; }
.slide-menu.open .menu-item:nth-child(3) { transition-delay: 0.3s; }
.slide-menu.open .menu-item:nth-child(4) { transition-delay: 0.4s; }
.slide-menu.open .menu-item:nth-child(5) { transition-delay: 0.5s; }

/* Added Responsive Styles for .logo-subtitle */
@media (max-width: 768px) {
    .logo-subtitle {
        font-size: 0.8rem; /* Adjust size for smaller screens */
        margin-left: 2px;
    }
}

.free-badge.mobile {
    color: #D8FF60;
    font-size: 0.8em;
    margin-left: 5px;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
}

.free-badge-container.mobile {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-left: 8px;
    background: rgba(216, 255, 96, 0.1);
    padding: 2px 6px;
    border-radius: 4px;
}

.free-badge {
    color: #D8FF60;
    font-size: 0.8em;
    animation: pulse 2s infinite;
}

.free-text {
    color: #D8FF60;
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: 0.5px;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
}

body {
    padding-top: 60px; /* Match navbar height */
    margin: 0;
    min-height: 100vh;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
}

/* Subtle highlight for Data Broker Check */
.data-broker-item {
    background: rgba(216, 255, 96, 0.03) !important;
    border: 1px solid rgba(216, 255, 96, 0.15) !important;
}

/* Customize scrollbar for dropdown */
.dropdown-menu::-webkit-scrollbar {
    width: 6px;
}

.dropdown-menu::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 3px;
}

.dropdown-menu::-webkit-scrollbar-thumb {
    background: rgba(216, 255, 96, 0.2);
    border-radius: 3px;
}

/* Prevent body scroll when menu is open */
body.menu-open {
    overflow: hidden;
}
