/* ScanningPage.css */

.scanning-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #1e1e1e;
    color: #fff;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    padding: 40px 20px;
    min-height: 100vh;
}

.scanning-header {
    text-align: center;
    margin-bottom: 30px;
    max-width: 800px;
}

.scanning-header h1 {
    font-size: 2rem;
    margin-bottom: 10px;
    color: #D8FF60;
    font-weight: 700;
}

.scanning-header h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
    color: #D8FF60;
    font-weight: 600;
}

.scanning-header p {
    font-size: 1rem;
    color: #ccc;
    max-width: 700px;
    margin: 0 auto;
    line-height: 1.5;
}

.error-message {
    background-color: #ff4d4d;
    color: #fff;
    padding: 15px 25px;
    border-radius: 8px;
    margin: 15px 0;
    text-align: center;
    max-width: 800px;
    font-size: 1rem;
    font-weight: 500;
}

.scanning-status {
    width: 100%;
    max-width: 800px;
    padding: 30px;
    background-color: #2a2a2a;
    border-radius: 10px;
    text-align: center;
    margin-bottom: 30px;
}

.scan-visualization {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
}

.scan-icon {
    font-size: 3rem;
    color: #fff;
}

.scan-message-container {
    margin-top: 10px;
}

.scan-message {
    font-size: 1.2rem;
    color: #ccc;
}

.scanning-count {
    font-size: 1rem;
    margin-bottom: 10px;
}

.progress-bar {
    width: 100%;
    background-color: #444;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 10px;
}

.progress {
    height: 20px;
    background-color: #D8FF60;
    width: 0%;
    transition: width 0.5s ease;
}

.match-counter {
    font-size: 1rem;
    margin-bottom: 15px;
}

.threats-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: 200px;
    overflow-y: auto;
    background-color: #1e1e1e;
    padding: 10px 15px;
    border-radius: 8px;
    border: 1px solid rgba(216, 255, 96, 0.3);
}

.threat-item {
    display: flex;
    align-items: center;
    padding: 6px 0;
    color: #f1c40f;
    font-size: 1rem;
    font-weight: 500;
}

.threat-icon {
    margin-right: 10px;
    font-size: 1.2rem;
}

.no-threats {
    font-size: 0.95rem;
    color: #ccc;
    text-align: center;
    padding: 8px 0;
}

.more-sites {
    color: #777;
    font-size: 0.9rem;
    text-align: center;
    margin-top: 8px;
}

.screenshot-display {
    margin-top: 20px;
}

.screenshot {
    width: 100%;
    max-width: 600px;
    border-radius: 8px;
    margin-top: 10px;
}

.generic-scan-animation {
    width: 100%;
    max-width: 600px;
    height: 400px;
    background-color: #333;
    border-radius: 8px;
    margin-top: 10px;
}

.scan-overlay-animation {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 8px;
    animation: scanOverlay 2s infinite;
}

@keyframes scanOverlay {
    0% {
        transform: translateX(-100%);
    }
    50% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
}

.info-box-bottom {
    background-color: #2a2a2a;
    padding: 20px;
    border-radius: 10px;
    max-width: 800px;
    text-align: center;
}

.info-icon-bottom {
    font-size: 1.5rem;
    color: #2980b9;
    margin-bottom: 10px;
}

.info-box-bottom p {
    font-size: 1rem;
    color: #ccc;
    line-height: 1.5;
}

@media (max-width: 768px) {
    .progress {
        height: 15px;
    }

    .scan-icon {
        font-size: 2.5rem;
    }

    .scan-message {
        font-size: 1rem;
    }

    .screenshot {
        max-width: 100%;
    }
}
