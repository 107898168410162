/* RESET */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* GENERAL PAGE STYLING */
.chat-page {
    min-height: 100vh;
    background: #1a1a1a;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-x: hidden;
    padding-top: var(--desktop-navbar-height);
}

/* Mobile navbar height variable */
:root {
    --desktop-navbar-height: 64px;
    --mobile-navbar-height: 48px;
}

/* NAVBAR */
.navbar-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: var(--mobile-navbar-height);
    background: #121212;
    z-index: 1000;
}

/* TOP SECTION */
.top-section {
    position: fixed;
    top: var(--desktop-navbar-height);
    left: 0;
    right: 0;
    background: #1a1a1a;
    padding: 10px 0;
    z-index: 99;
}

/* RAVEN HEADER */
.raven-header {
    padding: 8px 16px;
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.2);
    border-radius: 12px;
    margin: 0 auto;
    max-width: 1200px;
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.raven-logo-section {
    display: flex;
    align-items: center;
    gap: 8px !important;
}

.raven-icon {
    font-size: 24px;
    color: #42ffb5;
}

.raven-text {
    margin: 0 !important;
    padding: 0 !important;
}

.raven-title {
    margin: 0 !important;
    line-height: 1.2 !important;
    font-size: 1.2rem !important;
}

.raven-subtitle {
    margin: 0 !important;
    line-height: 1.2 !important;
    font-size: 0.8rem !important;
}

/* Chat Container */
.chat-container {
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
    flex: 1;
    overflow-y: auto;
    margin-top: 100px; /* Space for header */
    padding-bottom: 100px; /* Space for input */
    scroll-behavior: smooth;
}

.chat-history {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 20px 0;
    width: 100%;
}

/* Input Area */
.input-area {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background: rgba(18, 18, 18, 0.95) !important;
    backdrop-filter: blur(10px) !important;
    padding: 15px !important;
    border-top: 1px solid rgba(66, 255, 181, 0.2) !important;
    z-index: 1000 !important;
}

.input-area-content {
    max-width: 1200px !important;
    margin: 0 auto !important;
    display: flex !important;
    gap: 12px !important;
    align-items: center !important;
}

.input-focus {
    flex: 1 !important;
    height: 50px !important;
    padding: 12px 16px !important;
    background: rgba(28, 28, 28, 0.95) !important;
    border: 2px solid rgba(66, 255, 181, 0.3) !important;
    border-radius: 10px !important;
    color: #fff !important;
    font-size: 1rem !important;
    transition: all 0.3s ease !important;
    outline: none !important;
}

.input-focus:focus {
    border-color: #42ffb5 !important;
    box-shadow: 0 0 20px rgba(66, 255, 181, 0.2) !important;
}

.send-button {
    height: 50px !important;
    min-width: 100px !important;
    padding: 0 24px !important;
    background: linear-gradient(135deg, #42ffb5 0%, #2ee9a0 100%) !important;
    color: #1a1a1a !important;
    border: none !important;
    border-radius: 10px !important;
    font-weight: 600 !important;
    font-size: 1rem !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    white-space: nowrap !important;
}

.send-button:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 4px 15px rgba(66, 255, 181, 0.3) !important;
}

.send-button:disabled {
    opacity: 0.5 !important;
    cursor: not-allowed !important;
    transform: none !important;
    box-shadow: none !important;
}

/* Messages */
.user-message, .bot-response {
    padding: 16px 20px;
    border-radius: 12px;
    max-width: 80%;
    line-height: 1.6;
    font-size: 1rem;
    margin: 12px 0;
    animation: fadeIn 0.3s ease;
}

.user-message {
    background: rgba(66, 255, 181, 0.1);
    border: 1px solid rgba(66, 255, 181, 0.2);
    margin-left: auto;
    margin-right: 0;
}

.bot-response {
    margin: 12px 0;
    padding: 16px 20px;
    border-radius: 12px;
    max-width: 85%;
    line-height: 1.5;
    font-size: 1rem;
    letter-spacing: 0.2px;
    background: rgba(28, 28, 28, 0.95);
    margin-right: auto;
    border-left: 3px solid #42ffb5;
    opacity: 1;
    transform: none;
    transition: opacity 0.3s ease;
    position: relative;
    overflow: visible !important;
}

/* Welcome Message */
.welcome-message {
    margin: 20px auto;
    padding: 20px;
    max-width: 800px;
    text-align: center;
    background: rgba(28, 28, 28, 0.95);
    border-radius: 12px;
    border: 1px solid rgba(66, 255, 181, 0.2);
}

.welcome-message h1 {
    font-size: 1.8rem;
    color: #fff;
    margin-bottom: 12px;
}

.welcome-message p {
    color: #e0e0e0;
    font-size: 1.1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

/* Pre-made Questions */
.pre-made-questions {
    display: flex;
    flex-direction: column;
    gap: 6px;
    width: 100%;
    max-width: 700px;
    margin: 15px auto;
    padding: 0;
}

.question-button {
    width: 100%;
    padding: 10px 16px;
    background: rgba(66, 255, 181, 0.05);
    border: 1px solid rgba(66, 255, 181, 0.2);
    border-radius: 10px;
    color: #fff;
    text-align: left;
    font-size: 1rem;
    line-height: 1.4;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;
    overflow: hidden;
}

.question-icon {
    font-size: 1.4rem;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
}

.question-text {
    flex: 1;
    margin-right: 25px;
}

.question-button::after {
    content: '→';
    position: absolute;
    right: 20px;
    font-size: 1.2rem;
    color: #42ffb5;
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
}

.question-button:hover {
    background: rgba(66, 255, 181, 0.1);
    border-color: #42ffb5;
    transform: translateX(5px);
    box-shadow: 0 4px 20px rgba(66, 255, 181, 0.15);
}

.question-button:hover::after {
    opacity: 1;
    transform: translateX(0);
}

.question-button:hover .question-icon {
    transform: scale(1.1);
    color: #42ffb5;
}

.question-button:active {
    transform: translateX(5px) scale(0.98);
}

.question-button::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 4px;
    background: #42ffb5;
    transform: scaleY(0);
    transition: transform 0.3s ease;
}

.question-button:hover::before {
    transform: scaleY(1);
}

/* Screen Size Specific Adjustments */
@media (min-width: 1536px), (max-width: 1200px) {
    .chat-container {
        margin-top: 120px !important;
    }
    
    .welcome-message {
        margin-top: 10px !important;
    }
}

@media (max-width: 768px) {
    .navbar-wrapper {
        height: var(--mobile-navbar-height) !important;
    }

    .top-section {
        top: var(--mobile-navbar-height) !important;
        height: auto;
        padding: 8px 0;
    }

    .chat-container {
        width: 100%;
        padding: 0 10px;
        margin-top: 80px;
    }

    .raven-header {
        margin: 0 auto !important;
        padding: 8px !important;
        width: 95% !important;
        flex-direction: column !important;
        gap: 4px !important;
        background: rgba(28, 28, 28, 0.98) !important;
    }

    .raven-logo-section {
        width: 100%;
        justify-content: center;
        text-align: center;
    }

    .raven-title {
        font-size: 0.85rem !important;
        white-space: normal !important;
        text-align: center !important;
    }

    .raven-subtitle {
        font-size: 0.65rem !important;
        white-space: normal !important;
        text-align: center !important;
    }

    .new-chat-button {
        width: 90% !important;
        max-width: 200px !important;
        margin: 4px auto !important;
        padding: 6px 12px !important;
        font-size: 0.85rem !important;
        height: 28px !important;
    }

    .welcome-message {
        margin: 10px;
        padding: 15px;
    }

    .welcome-message h1 {
        font-size: 1.4rem !important;
        margin-bottom: 12px !important;
        margin-top: 0 !important;
    }

    .welcome-message p {
        font-size: 0.9rem !important;
        margin: 12px 0 !important;
        line-height: 1.5 !important;
    }

    .input-area {
        padding: 10px !important;
    }

    .input-area-content {
        gap: 8px !important;
    }

    .input-focus {
        height: 45px !important;
        padding: 10px 14px !important;
        font-size: 0.95rem !important;
    }

    .send-button {
        height: 45px !important;
        min-width: 80px !important;
        padding: 0 16px !important;
        font-size: 0.95rem !important;
    }

    .pre-made-questions {
        margin-top: 20px !important;
    }

    .question-button {
        padding: 12px !important;
        font-size: 0.85rem !important;
        line-height: 1.4 !important;
        white-space: normal !important;
        text-align: left !important;
        height: auto !important;
        min-height: 60px !important;
    }

    .question-text {
        flex: 1 !important;
        white-space: normal !important;
        line-height: 1.4 !important;
        margin-right: 25px !important;
        font-size: 0.85rem !important;
    }

    .question-icon {
        font-size: 1.2rem !important;
        min-width: 24px !important;
        align-self: flex-start !important;
        margin-top: 2px !important;
    }

    /* Fix Mobile Scrolling */
    .chat-page {
        position: fixed !important;
        top: 0 !important;
        left: 0 !important;
        right: 0 !important;
        bottom: 0 !important;
        overflow: hidden !important;
    }

    .chat-container {
        height: calc(100vh - 180px) !important; /* Adjust for header and input */
        overflow-y: auto !important;
        -webkit-overflow-scrolling: touch !important;
        margin-top: 120px !important;
        padding-bottom: 100px !important;
        position: relative !important;
    }

    .welcome-message {
        margin: 10px !important;
        padding: 15px !important;
    }

    .pre-made-questions {
        margin: 15px 0 !important;
        gap: 8px !important;
    }

    /* Prevent pull-to-refresh */
    body {
        overscroll-behavior-y: none !important;
    }

    /* Ensure content doesn't get hidden behind keyboard */
    .input-area {
        position: fixed !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        background: rgba(18, 18, 18, 0.95) !important;
        backdrop-filter: blur(10px) !important;
        padding: 10px !important;
        z-index: 1000 !important;
    }

    /* Adjust welcome message for better mobile display */
    .welcome-message h1 {
        font-size: 1.4rem !important;
        margin-bottom: 12px !important;
    }

    .welcome-message p {
        font-size: 0.9rem !important;
        line-height: 1.5 !important;
        margin: 12px 0 !important;
    }
}

/* Large Screen Optimizations */
@media (min-width: 1440px) {
    .chat-container,
    .input-area-content,
    .raven-header {
        max-width: 1000px;
    }

    .welcome-message {
        max-width: 800px;
    }

    .user-message, .bot-response {
        max-width: 70%;
    }
}

/* MacBook Pro 16" Specific */
@media (min-width: 1536px) {
    .chat-container {
        margin-top: 140px;
    }

    .welcome-message h1 {
        font-size: 2.2rem;
    }

    .input-area {
        padding: 20px 0;
    }
}

/* Responsive Design */
@media (max-width: 1200px) {
    .chat-container,
    .input-area-content,
    .raven-header {
        width: 95%;
    }
}

@media (max-width: 768px) {
    .top-section {
        padding: 8px 0;
    }

    .raven-header {
        padding: 12px;
    }

    .welcome-message {
        padding: 20px;
        margin: 10px auto;
    }

    .welcome-message h1 {
        font-size: 1.8rem;
    }

    .input-area {
        padding: 12px 0;
    }

    .input-focus {
        height: 45px;
    }

    .send-button {
        height: 45px;
        min-width: 80px;
        padding: 0 16px;
    }

    .user-message, .bot-response {
        padding: 14px;
        max-width: 85%;
    }
}

/* Scrollbar Styling */
.chat-container::-webkit-scrollbar {
    width: 8px;
}

.chat-container::-webkit-scrollbar-track {
    background: transparent;
}

.chat-container::-webkit-scrollbar-thumb {
    background: rgba(66, 255, 181, 0.2);
    border-radius: 4px;
}

.chat-container::-webkit-scrollbar-thumb:hover {
    background: rgba(66, 255, 181, 0.3);
}

/* Animation Keyframes */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideUp {
    from { transform: translateY(20px); opacity: 0; }
    to { transform: translateY(0); opacity: 1; }
}

/* NAVBAR WRAPPER */
.navbar-wrapper {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* RAVEN HEADER */
.raven-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 30px;
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.2);
    border-radius: 12px;
    margin-bottom: 30px;
    box-shadow: 0 8px 32px rgba(66, 255, 181, 0.1);
    position: relative;
    overflow: hidden;
}

.raven-header::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background: linear-gradient(90deg, 
        rgba(66, 255, 181, 0), 
        rgba(66, 255, 181, 0.5), 
        rgba(66, 255, 181, 0)
    );
    animation: glowPulse 3s infinite;
}

.raven-logo-section {
    display: flex;
    align-items: center;
    gap: 16px;
}

.raven-icon {
    font-size: 32px;
    color: #42ffb5;
    filter: drop-shadow(0 0 10px rgba(66, 255, 181, 0.5));
}

.raven-text {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.raven-title {
    font-size: 24px;
    font-weight: 700;
    color: #ffffff;
    margin: 0;
    text-shadow: 0 0 10px rgba(66, 255, 181, 0.3);
    letter-spacing: 0.5px;
}

.raven-subtitle {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.8);
    margin: 0;
    text-shadow: 0 0 8px rgba(66, 255, 181, 0.2);
}

.new-chat-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 10px 20px;
    background: rgba(66, 255, 181, 0.1);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 8px;
    color: #42ffb5;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 10px auto;
    width: fit-content;
}

.new-chat-button:hover {
    background: rgba(66, 255, 181, 0.15);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(66, 255, 181, 0.2);
}

@keyframes glowPulse {
    0%, 100% {
        opacity: 0.5;
        box-shadow: 0 0 20px rgba(66, 255, 181, 0.3);
    }
    50% {
        opacity: 1;
        box-shadow: 0 0 30px rgba(66, 255, 181, 0.5);
    }
}

@media (max-width: 768px) {
    .raven-header {
        padding: 8px 12px !important;
        margin: 0 auto !important;
        width: 95% !important;
        flex-direction: column !important;
        gap: 8px !important;
    }

    .raven-logo-section {
        flex-direction: row;
        gap: 12px;
        align-items: center;
    }

    .raven-icon {
        font-size: 24px;
        margin-right: 0;
    }

    .raven-text {
        text-align: left;
    }

    .raven-title {
        font-size: 18px;
        margin-bottom: 2px;
        white-space: nowrap;
    }

    .raven-subtitle {
        font-size: 11px;
        opacity: 0.9;
        white-space: nowrap;
    }

    .new-chat-button {
        padding: 8px 12px;
        font-size: 12px;
        white-space: nowrap;
        height: 32px;
        min-width: auto;
    }

    /* Chat container adjustments */
    .chat-container {
        margin-top: calc(var(--mobile-navbar-height) + 80px);
        padding: 0 12px;
        height: calc(100vh - var(--mobile-navbar-height) - 150px);
    }

    /* Welcome message adjustments */
    .welcome-message {
        margin: 10px 0;
        padding: 20px 15px;
        background: rgba(28, 28, 28, 0.98);
    }

    .welcome-message h1 {
        font-size: 1.5rem;
        margin-bottom: 12px;
    }

    .welcome-message p {
        font-size: 0.9rem;
        margin-bottom: 20px;
        line-height: 1.5;
    }

    /* Pre-made questions adjustments */
    .pre-made-questions {
        gap: 8px;
        margin: 12px 0;
    }

    .question-button {
        padding: 12px 16px;
        font-size: 0.9rem;
        border-radius: 8px;
    }

    /* Input area adjustments */
    .input-area {
        padding: 10px;
        background: rgba(26, 26, 26, 0.98);
    }

    .input-area-content {
        gap: 6px;
    }

    .input-focus {
        height: 45px;
        padding: 12px 16px;
        font-size: 14px;
        border-radius: 8px;
    }

    .send-button {
        height: 45px;
        min-width: 80px;
        padding: 12px;
        font-size: 14px;
        border-radius: 8px;
    }

    /* Message styling adjustments */
    .user-message, .bot-response {
        padding: 14px 16px;
        font-size: 0.95rem;
        margin: 6px 0;
        max-width: 90%;
        border-radius: 12px;
    }

    .bot-response {
        background: rgba(28, 28, 28, 0.98);
    }

    /* Safe area inset for iOS */
    @supports (-webkit-touch-callout: none) {
        .input-area {
            padding-bottom: max(10px, env(safe-area-inset-bottom));
        }

        .chat-container {
            padding-bottom: env(safe-area-inset-bottom);
        }
    }
}

/* Add smooth scroll behavior */
.chat-container {
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

/* Improve scrollbar appearance on mobile */
.chat-container::-webkit-scrollbar {
    width: 4px;
}

.chat-container::-webkit-scrollbar-thumb {
    background-color: rgba(66, 255, 181, 0.3);
    border-radius: 4px;
}

/* Add momentum scrolling for iOS */
.chat-container {
    -webkit-overflow-scrolling: touch;
}

/* SHAKING ANIMATION */
@keyframes shake {
    0%, 100% { transform: translateX(0); }
    25% { transform: translateX(-4px); }
    75% { transform: translateX(4px); }
}

.shake {
    animation: shake 0.4s ease-in-out;
}

/* CONFIRMATION MESSAGE */
.confirmation-message {
    background: rgba(66, 255, 181, 0.1);
    border-left: 3px solid #42ffb5;
    padding: 14px 20px;
    margin: 12px 0;
    border-radius: 12px;
    font-size: 0.95rem;
    color: #ffffff;
}

/* TOOL LINKS */
.tool-link {
    opacity: 1 !important;
    animation: none !important;
}

.tool-description {
    display: inline-block;
    margin-left: 8px;
}

/* Fade In and Slide In Animations */
@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes slideInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fade-in {
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
}

.fade-in-delayed {
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards;
    animation-delay: 1s;
}

.typewriter-text {
    opacity: 0;
    animation: fadeIn 0.1s ease-out forwards;
}

/* TOOL HIGHLIGHTS */
.location-highlight {
    color: #42ffb5;
    font-weight: 600;
}

.emphasis {
    color: #D8FF60;
    font-weight: 600;
}

/* Update button styles to ensure it's hidden when needed */
.scan-button.hidden {
    display: none;
}

/* Remove any opacity animations from typewriter-related classes */
.typewriter-text, .typewriter-entire {
    opacity: 1;
}

/* Add smooth transition for content changes */
.analysis-content {
    transition: none;
}

/* Analysis Content */
.ai-analysis {
    position: relative;
}

.analysis-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 12px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(66, 255, 181, 0.2);
}

.analysis-content {
    opacity: 1;
    transform: none;
    transition: opacity 0.3s ease;
}

/* Remove any transform animations */
.bot-response,
.ai-analysis,
.analysis-content {
    will-change: opacity;
    transform: none !important;
    animation: none !important;
}

/* Fade in smoothly */
@keyframes fadeInSmooth {
    from { opacity: 0; }
    to { opacity: 1; }
}

.bot-response {
    animation: fadeInSmooth 0.3s ease forwards !important;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .bot-response {
        padding: 14px 16px;
        font-size: 0.95rem;
        margin: 8px 0;
        border-radius: 10px;
    }
}

/* Location info styling */
.location-info {
    text-align: center;
    margin: 20px 0;
    font-size: 1.1rem;
    line-height: 1.6;
    color: #e0e0e0;
}

.location-highlight {
    color: #42ffb5;
    font-weight: 600;
}

.emphasis {
    color: #D8FF60;
    font-weight: 600;
}

/* New scan button styling */
.scan-button-wrapper {
    display: flex;
    justify-content: center;
    margin: 25px 0;
}

.scan-button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    background: linear-gradient(135deg, #42ffb5 0%, #2ee9a0 100%);
    color: #1a1a1a !important;
    padding: 12px 24px;
    border-radius: 12px;
    border: none;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    min-width: 120px;
    box-shadow: 0 4px 15px rgba(66, 255, 181, 0.3);
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.scan-button:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(66, 255, 181, 0.5);
    background: linear-gradient(135deg, #2ee9a0 0%, #42ffb5 100%);
}

.scan-button:active {
    transform: translateY(0);
}

@media (max-width: 768px) {
    .scan-button {
        padding: 10px 20px;
        font-size: 0.9rem;
        min-width: 100px;
    }
}

.button-icon {
    font-size: 1.4rem;
    color: #1a1a1a;
}

.button-text {
    font-weight: 800;
    letter-spacing: 1px;
    color: #1a1a1a;
    position: relative;
    z-index: 2;
}

.button-arrow {
    font-size: 1.4rem;
    color: #1a1a1a;
    transition: transform 0.3s ease;
    position: relative;
    z-index: 2;
}

.scan-button:hover .button-arrow {
    transform: translateX(4px);
}

/* Add a pulsing animation to draw attention */
.scan-button {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        box-shadow: 0 4px 15px rgba(66, 255, 181, 0.3);
    }
    50% {
        box-shadow: 0 4px 25px rgba(66, 255, 181, 0.5);
    }
    100% {
        box-shadow: 0 4px 15px rgba(66, 255, 181, 0.3);
    }
}

.fear-cta-block {
    background: rgba(32, 33, 35, 0.9);
    border: 1px solid #42ffb5;
    border-radius: 8px;
    padding: 20px;
    margin: 20px 0;
}

.fear-cta-block h3 {
    color: #42ffb5;
    margin-bottom: 15px;
}

.fear-cta-block ul {
    list-style-type: none;
    padding-left: 0;
    margin: 15px 0;
}

.fear-cta-block li {
    margin: 8px 0;
    padding-left: 24px;
    position: relative;
}

.fear-cta-block li:before {
    content: "•";
    color: #42ffb5;
    position: absolute;
    left: 8px;
}

.fear-cta-block .highlight {
    color: #ff4d4d;
    font-weight: 500;
}

.scan-button.cta-upgrade {
    background: #42ffb5;
    color: #000;
    border: none;
    border-radius: 4px;
    padding: 12px 24px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    width: 100%;
    margin-top: 15px;
    transition: all 0.3s ease;
}

.scan-button.cta-upgrade:hover {
    background: #3ae0a0;
    transform: translateY(-1px);
}

.doorstep-questionnaire {
    margin: 25px 0;
    padding: 25px;
    background: rgba(28, 28, 28, 0.95);
    border-radius: 16px;
    border: 2px solid rgba(66, 255, 181, 0.3);
}

.question-container h4 {
    color: #ffffff;
    font-size: 1.4rem;
    margin-bottom: 20px;
    line-height: 1.4;
    font-weight: 600;
    text-align: center;
}

.options-grid {
    display: flex;
    flex-direction: column;
    gap: 15px;
    max-width: 600px;
    margin: 0 auto;
}

.option-button {
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border: 2px solid rgba(66, 255, 181, 0.3);
    border-radius: 12px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.4;
    display: flex;
    align-items: center;
    gap: 15px;
}

.option-button:hover {
    background: rgba(66, 255, 181, 0.1);
    border-color: #42ffb5;
    transform: translateY(-2px);
}

.option-button:focus {
    outline: 3px solid #42ffb5;
    background: rgba(66, 255, 181, 0.1);
}

.option-icon {
    font-size: 1.8rem;
    min-width: 40px;
    text-align: center;
}

.progress-indicator {
    display: flex;
    justify-content: center;
    gap: 8px;
    margin-bottom: 20px;
}

.progress-step {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.2);
}

.progress-step.active {
    background: #42ffb5;
    box-shadow: 0 0 10px rgba(66, 255, 181, 0.5);
}

.question-header {
    text-align: center;
    margin-bottom: 30px;
}

.question-title {
    color: #42ffb5;
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.doorstep-question {
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 12px;
    padding: 25px;
    margin: 20px 0;
}

.question-progress {
    text-align: center;
    margin-bottom: 25px;
}

.progress-dots {
    display: flex;
    justify-content: center;
    gap: 12px;
    margin-bottom: 12px;
}

.progress-dot {
    width: 10px;
    height: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transition: all 0.3s ease;
}

.progress-dot.active {
    background: #42ffb5;
    box-shadow: 0 0 10px rgba(66, 255, 181, 0.3);
}

.question-counter {
    color: #42ffb5;
    font-size: 0.9rem;
    margin-top: 8px;
}

.question-main {
    max-width: 600px;
    margin: 0 auto;
}

.question-title {
    color: #ffffff;
    font-size: 1.3rem;
    text-align: center;
    margin-bottom: 25px;
    line-height: 1.4;
}

.answer-buttons {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 8px;
}

.answer-option {
    padding: 12px 16px;
    background: rgba(66, 255, 181, 0.08);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: left;
    width: 100%;
    display: flex;
    align-items: center;
}

.answer-option:hover {
    background: rgba(66, 255, 181, 0.15);
    transform: translateX(5px);
    border-color: #42ffb5;
}

.option-content {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.option-icon {
    font-size: 1.2rem;
    min-width: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option-text {
    flex: 1;
    font-size: 0.95rem;
    line-height: 1.4;
}

.custom-response-hint {
    text-align: center;
    color: rgba(255, 255, 255, 0.6);
    font-size: 0.85rem;
    margin-top: 8px;
    font-style: italic;
}

@media (max-width: 768px) {
    .answer-buttons {
        margin-top: 12px;
        gap: 6px;
    }

    .answer-option {
        padding: 10px 14px;
    }

    .option-icon {
        font-size: 1.1rem;
        min-width: 20px;
    }

    .option-text {
        font-size: 0.9rem;
    }

    .custom-response-hint {
        font-size: 0.8rem;
        margin-top: 6px;
    }
}

.analysis-section {
    background: rgba(66, 255, 181, 0.07);
    padding: 20px;
    border: 1px solid rgba(66, 255, 181, 0.2);
    border-radius: 12px;
    margin-top: 20px;
    box-shadow: 0 2px 10px rgba(66, 255, 181, 0.1);
}

.analysis-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgba(66, 255, 181, 0.2);
}

.analysis-header h2 {
    color: #42ffb5;
    font-size: 1.4rem;
    margin: 0;
}

.analysis-section p, 
.analysis-section ul {
    font-size: 1rem;
    line-height: 1.5;
    color: #fff;
    margin-bottom: 15px;
}

.analysis-section ul {
    padding-left: 20px;
}

.analysis-section li {
    margin-bottom: 10px;
}

.analysis-section .highlight {
    color: #42ffb5;
    font-weight: 500;
}

.recommendation-box {
    background: rgba(255, 255, 255, 0.05);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 8px;
    padding: 15px;
    margin: 15px 0;
}

.warning-box {
    background: rgba(255, 99, 71, 0.1);
    border: 1px solid rgba(255, 99, 71, 0.3);
    border-radius: 8px;
    padding: 15px;
    margin: 15px 0;
}

.contact-info {
    background: rgba(66, 255, 181, 0.05);
    border-radius: 8px;
    padding: 15px;
    margin-top: 20px;
}

.contact-info h3 {
    color: #42ffb5;
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.contact-number {
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 8px 0;
    font-size: 1.1rem;
}

/* Mobile adjustments */
@media (max-width: 768px) {
    .analysis-section {
        padding: 15px;
    }

    .analysis-header h2 {
        font-size: 1.2rem;
    }

    .analysis-section p, 
    .analysis-section ul {
        font-size: 0.95rem;
    }
}

.typing-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 16px 20px;
    background: rgba(66, 255, 181, 0.05);
    border: 1px solid rgba(66, 255, 181, 0.1);
    border-radius: 12px;
    margin: 12px 0;
    position: relative;
    overflow: hidden;
}

.typing-indicator::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(66, 255, 181, 0.1),
        transparent
    );
    animation: shimmer 2s infinite;
}

.typing-indicator span {
    width: 8px;
    height: 8px;
    background: #42ffb5;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
    opacity: 0.6;
    box-shadow: 0 0 10px rgba(66, 255, 181, 0.3);
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }
.typing-indicator span:nth-child(3) { animation-delay: 0s; }

@keyframes shimmer {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(50%);
    }
}

@keyframes bounce {
    0%, 80%, 100% { 
        transform: scale(0);
        opacity: 0.6;
    }
    40% { 
        transform: scale(1);
        opacity: 1;
        box-shadow: 0 0 20px rgba(66, 255, 181, 0.5);
    }
}

.follow-up-question {
    margin: 20px 0;
    padding: 20px;
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 12px;
}

.question-content {
    text-align: center;
}

.follow-up-question p {
    font-size: 1.1rem;
    color: #fff;
    margin-bottom: 20px;
    line-height: 1.4;
}

.follow-up-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.follow-up-btn {
    display: inline-block;
    min-width: 120px;
    padding: 12px 30px;
    background: #42ffb5 !important;
    border: none;
    border-radius: 8px;
    color: #1a1a1a !important;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 15px rgba(66, 255, 181, 0.3);
}

.follow-up-btn:hover {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(66, 255, 181, 0.5);
    background: #2ee9a0 !important;
}

.follow-up-btn:active {
    transform: translateY(0);
}

.yes-btn {
    background: #42ffb5 !important;
    color: #1a1a1a !important;
}

.no-btn {
    background: #42ffb5 !important;
    color: #1a1a1a !important;
}

@media (max-width: 768px) {
    .follow-up-question {
        padding: 15px;
        margin: 15px 0;
    }

    .follow-up-question p {
        font-size: 1rem;
    }

    .follow-up-buttons {
        gap: 15px;
    }
    
    .follow-up-btn {
        min-width: 100px;
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}

.immediate-tools {
    margin: 20px 0;
    background: rgba(28, 28, 28, 0.95) !important;
    border: 1px solid rgba(66, 255, 181, 0.3) !important;
    border-radius: 12px;
    overflow: hidden;
    padding: 20px;
}

.tools-header {
    margin-bottom: 16px;
    border-bottom: 1px solid rgba(66, 255, 181, 0.2);
    padding-bottom: 12px;
}

.tools-header h3 {
    color: #42ffb5;
    font-size: 1.2rem;
    font-weight: 600;
    margin: 0;
}

.tools-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tool-item {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    background: rgba(66, 255, 181, 0.05);
    border: 1px solid rgba(66, 255, 181, 0.2);
    border-radius: 10px;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease;
}

.tool-item:hover {
    background: rgba(66, 255, 181, 0.1);
    border-color: #42ffb5;
    transform: translateX(8px);
    box-shadow: 0 4px 12px rgba(66, 255, 181, 0.2);
}

.tool-icon {
    font-size: 1.4rem;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(66, 255, 181, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
}

.tool-item:hover .tool-icon {
    background: rgba(66, 255, 181, 0.15);
    transform: scale(1.1);
}

.tool-content {
    flex: 1;
}

.tool-title {
    color: #42ffb5;
    font-size: 1.1rem;
    font-weight: 600;
}

.tool-description {
    color: #e0e0e0;
    font-size: 0.9rem;
    opacity: 0.9;
    line-height: 1.4;
}

.tool-arrow {
    color: #42ffb5;
    font-size: 1.4rem;
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
}

.tool-card:hover .tool-arrow {
    opacity: 1;
    transform: translateX(0);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .immediate-tools {
        margin: 15px 0;
        padding: 15px;
    }

    .tools-header h3 {
        font-size: 1.1rem;
    }

    .tool-item {
        padding: 12px;
    }

    .tool-icon {
        font-size: 1.2rem;
        min-width: 32px;
        height: 32px;
    }

    .tool-title {
        font-size: 1rem;
    }

    .tool-description {
        font-size: 0.85rem;
    }
}

/* Input area improvements */
.input-area {
    position: fixed !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    background: rgba(18, 18, 18, 0.95) !important;
    backdrop-filter: blur(10px) !important;
    padding: 15px !important;
    border-top: 1px solid rgba(66, 255, 181, 0.2) !important;
    z-index: 1000 !important;
}

.input-area-content {
    max-width: 1200px !important;
    margin: 0 auto !important;
    display: flex !important;
    gap: 12px !important;
    align-items: center !important;
}

/* Ensure content doesn't get hidden behind keyboard on mobile */
@media (max-width: 768px) {
    .chat-page {
        padding-top: var(--mobile-navbar-height);
    }
    
    body.keyboard-visible .input-area {
        position: absolute;
    }
}

/* Animation for new messages */
@keyframes fadeIn {
    from { opacity: 0; transform: translateY(10px); }
    to { opacity: 1; transform: translateY(0); }
}

/* Improved bot response formatting */
.bot-response {
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 12px;
    padding: 15px;
    margin: 10px 0;
    animation: fadeIn 0.3s ease;
}

.user-message {
    background: rgba(66, 255, 181, 0.1);
    border: 1px solid rgba(66, 255, 181, 0.2);
    border-radius: 12px;
    padding: 15px;
    margin: 10px 0;
    animation: fadeIn 0.3s ease;
}

/* Doorstep Alert Styling */
.doorstep-alert {
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 12px;
    padding: 25px;
    margin: 20px 0;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.doorstep-alert strong {
    display: block;
    color: #42ffb5;
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: 600;
}

.doorstep-alert p {
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.6;
    margin: 15px 0;
}

.doorstep-alert .scan-button.cta-upgrade {
    width: 100%;
    padding: 14px;
    background: #42ffb5;
    border: none;
    border-radius: 8px;
    color: #1a1a1a;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    margin-top: 20px;
    box-shadow: 0 4px 15px rgba(66, 255, 181, 0.3);
}

.doorstep-alert .scan-button.cta-upgrade:hover {
    background: #2ee9a0;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(66, 255, 181, 0.4);
}

@media (max-width: 768px) {
    .doorstep-alert {
        padding: 20px;
        margin: 15px 0;
    }

    .doorstep-alert strong {
        font-size: 1.1rem;
        margin-bottom: 12px;
    }

    .doorstep-alert p {
        font-size: 0.95rem;
    }

    .doorstep-alert .scan-button.cta-upgrade {
        padding: 12px;
        font-size: 0.95rem;
    }
}

/* Chat container mobile adjustments */
@media (max-width: 768px) {
    .chat-container {
        margin-top: 120px !important; /* Increase top margin to account for header */
        padding-top: 20px;
    }

    .chat-history {
        padding-top: 10px;
    }

    .thinking-indicator {
        margin-top: 20px;
    }

    .bot-response {
        margin-top: 20px;
    }
}

/* Ensure proper spacing after header on mobile */
@media (max-width: 768px) {
    .top-section {
        position: fixed !important;
        top: 48px !important;
        left: 0 !important;
        right: 0 !important;
        z-index: 100 !important;
        background: #121212 !important;
    }

    .raven-header {
        margin: 0 auto !important;
        padding: 8px !important;
        width: 95% !important;
        border-radius: 12px !important;
    }
}

/* Section Headers */
.section-header {
    color: #42ffb5;
    font-size: 1.1rem;
    font-weight: 600;
    margin: 1.5em 0 1em;
    padding-bottom: 0.5em;
    border-bottom: 1px solid rgba(66, 255, 181, 0.2);
}

.section-header:first-child {
    margin-top: 0;
}

/* Lists */
.styled-list {
    margin: 1em 0;
    padding-left: 1.2em;
}

.styled-list li {
    margin: 0.5em 0;
    line-height: 1.6;
    position: relative;
}

.styled-list li::before {
    content: '';
    position: absolute;
    left: -1.2em;
    top: 0.7em;
    width: 6px;
    height: 6px;
    background: #42ffb5;
    border-radius: 50%;
}

/* Animated Text */
.animated-text {
    margin: 1em 0;
    line-height: 1.6;
}

/* Typing Cursor */
.bot-response.typing .animated-text:last-child::after {
    content: '▋';
    display: inline-block;
    color: #42ffb5;
    animation: blink 1s infinite;
    margin-left: 2px;
    font-weight: 100;
}

@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
}

/* Links */
.bot-response a {
    color: #42ffb5;
    text-decoration: none;
    border-bottom: 1px solid rgba(66, 255, 181, 0.3);
    transition: all 0.2s ease;
}

.bot-response a:hover {
    border-bottom-color: #42ffb5;
    text-shadow: 0 0 8px rgba(66, 255, 181, 0.5);
}

/* Strong Text */
.bot-response strong {
    color: #42ffb5;
    font-weight: 600;
}

/* Highlight Keywords */
.highlight {
    color: #D8FF60;
    font-weight: 500;
    padding: 0.1em 0.2em;
    border-radius: 3px;
    background: rgba(216, 255, 96, 0.1);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
    .bot-response {
        font-size: 0.95rem;
        padding: 14px 16px;
    }

    .section-header {
        font-size: 1rem;
    }
}

/* Improve mobile scrolling performance */
.chat-container {
    -webkit-overflow-scrolling: touch !important;
    will-change: transform !important;
    transform: translateZ(0) !important;
    backface-visibility: hidden !important;
    perspective: 1000px !important;
}

/* Message content styling */
.message-content {
    white-space: pre-wrap;
}

.message-content p {
    margin: 0.5em 0;
}

.message-content p:first-child {
    margin-top: 0;
}

.message-content p:last-child {
    margin-bottom: 0;
}

/* Tool Suggestions Styling */
.tools-section {
    margin: 24px 0;
    padding: 20px;
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 12px;
}

.tools-section .section-header {
    color: #42ffb5;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 16px;
    padding-bottom: 12px;
    border-bottom: 1px solid rgba(66, 255, 181, 0.2);
}

.tools-grid {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.tool-card {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    background: rgba(66, 255, 181, 0.05);
    border: 1px solid rgba(66, 255, 181, 0.2);
    border-radius: 10px;
    text-decoration: none;
    color: inherit;
    transition: all 0.3s ease;
}

.tool-card:hover {
    background: rgba(66, 255, 181, 0.1);
    border-color: #42ffb5;
    transform: translateX(8px);
    box-shadow: 0 4px 12px rgba(66, 255, 181, 0.2);
}

.tool-content {
    flex: 1;
    margin-right: 16px;
}

.tool-header {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 4px;
}

.tool-icon {
    font-size: 1.4rem;
    min-width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(66, 255, 181, 0.1);
    border-radius: 8px;
    transition: all 0.3s ease;
}

.tool-card:hover .tool-icon {
    background: rgba(66, 255, 181, 0.15);
    transform: scale(1.1);
}

.tool-title {
    color: #42ffb5;
    font-size: 1.1rem;
    font-weight: 600;
}

.tool-description {
    color: #e0e0e0;
    font-size: 0.9rem;
    opacity: 0.9;
    line-height: 1.4;
}

.tool-arrow {
    color: #42ffb5;
    font-size: 1.4rem;
    opacity: 0;
    transform: translateX(-10px);
    transition: all 0.3s ease;
}

.tool-card:hover .tool-arrow {
    opacity: 1;
    transform: translateX(0);
}

@media (max-width: 768px) {
    .tools-section {
        margin: 16px 0;
        padding: 16px;
    }

    .tools-section .section-header {
        font-size: 1.1rem;
        margin-bottom: 12px;
    }

    .tool-card {
        padding: 12px;
    }

    .tool-icon {
        font-size: 1.2rem;
        min-width: 32px;
        height: 32px;
    }

    .tool-title {
        font-size: 1rem;
    }

    .tool-description {
        font-size: 0.85rem;
    }
}

.thinking-indicator {
    background: rgba(28, 28, 28, 0.95);
    border: 1px solid rgba(66, 255, 181, 0.3);
    border-radius: 12px;
    padding: 16px 20px;
    margin: 12px 0;
    max-width: 85%;
    margin-right: auto;
    border-left: 3px solid #42ffb5;
    animation: fadeIn 0.3s ease;
    display: flex;
    align-items: center;
    gap: 12px;
}

.thinking-dots {
    display: flex;
    gap: 4px;
}

.thinking-dots span {
    width: 6px;
    height: 6px;
    background: #42ffb5;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
}

.thinking-dots span:nth-child(1) { animation-delay: -0.32s; }
.thinking-dots span:nth-child(2) { animation-delay: -0.16s; }

.thinking-content {
    color: #42ffb5;
    font-size: 1rem;
    margin: 0;
    opacity: 0.9;
}

@media (max-width: 768px) {
    .thinking-indicator {
        padding: 14px 16px;
        font-size: 0.95rem;
        margin: 8px 0;
        border-radius: 10px;
    }
}

@keyframes textReveal {
    0% {
        opacity: 0;
        filter: blur(8px);
        transform: scale(0.98);
    }
    50% {
        opacity: 0.5;
        filter: blur(4px);
    }
    100% {
        opacity: 1;
        filter: blur(0);
        transform: scale(1);
    }
}

.message-reveal {
    animation: textReveal 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.bot-response p, 
.bot-response ul, 
.bot-response h1, 
.bot-response h2, 
.bot-response h3 {
    opacity: 0;
}

.bot-response.revealing p, 
.bot-response.revealing ul, 
.bot-response.revealing h1, 
.bot-response.revealing h2, 
.bot-response.revealing h3 {
    animation: textReveal 0.8s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.bot-response.revealing p:nth-child(n) {
    animation-delay: calc(0.1s * var(--reveal-index, 0));
}

.typing-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px 16px;
    background: rgba(0, 255, 0, 0.05);
    border-radius: 8px;
    margin: 8px 0;
}

.typing-indicator span {
    width: 8px;
    height: 8px;
    background: #00ff9d;
    border-radius: 50%;
    animation: bounce 1.4s infinite ease-in-out;
    opacity: 0.6;
}

.typing-indicator span:nth-child(1) { animation-delay: -0.32s; }
.typing-indicator span:nth-child(2) { animation-delay: -0.16s; }

@keyframes bounce {
    0%, 80%, 100% { 
        transform: scale(0);
        opacity: 0.6;
    }
    40% { 
        transform: scale(1);
        opacity: 1;
    }
}

