/* src/components/Sidebar.css */

/* Import Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;600;700&display=swap');

/* Sidebar Styling */
.sidebar {
    position: fixed;
    top: 0;
    left: 0;
    width: 280px;
    height: 100vh;
    background: #121212;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-family: 'Plus Jakarta Sans', sans-serif;
    z-index: 1100;
    padding-top: 60px;
    border-right: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.3s ease;
}

/* Hide Sidebar on Mobile */
@media (max-width: var(--mobile-breakpoint)) {
    .sidebar {
        transform: translateX(-100%);
        transition: transform 0.3s ease;
    }

    .sidebar.open {
        transform: translateX(0);
    }

    /* Toggle Button for Sidebar (Optional) */
    .sidebar-toggle {
        display: block;
        position: fixed;
        top: 20px;
        left: 20px;
        background: none;
        border: none;
        color: #fff;
        font-size: 1.5rem;
        z-index: 1100;
        cursor: pointer;
    }
}

/* Sidebar Profile Section */
.sidebar-profile {
    padding: 32px 24px;
    text-align: center;
    margin-bottom: 0;
    background: rgba(0, 0, 0, 0.2);
}

.profile-pic-wrapper {
    position: relative;
    width: 90px;
    height: 90px;
    margin: 0 auto;
    padding: 3px;
    background: linear-gradient(45deg, #D8FF60, #96ff60);
    border-radius: 50%;
}

.profile-pic {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid #0a0c1b;
    transition: all 0.3s ease;
}

.online-status {
    position: absolute;
    bottom: 5px;
    right: 5px;
}

.status-dot {
    width: 12px;
    height: 12px;
    background: #63b3ed;
    border-radius: 50%;
    position: relative;
}

.status-dot::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: inherit;
    border-radius: inherit;
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
    opacity: 0.5;
}

@keyframes pulse {
    0% { transform: scale(1); opacity: 0.5; }
    50% { transform: scale(2); opacity: 0; }
    100% { transform: scale(1); opacity: 0.5; }
}

/* Status Badge */
.status-badge {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 4px;
}

/* Navigation Items */
.nav-item {
    display: flex;
    align-items: center;
    padding: 12px 24px;
    color: #e2e8f0;
    text-decoration: none;
    transition: all 0.3s ease;
    margin: 4px 12px;
    border-radius: 8px;
}

.nav-item:hover {
    background: rgba(216, 255, 96, 0.1);
    color: #D8FF60;
}

.nav-item.active {
    background: rgba(216, 255, 96, 0.15);
    color: #D8FF60;
}

.nav-icon {
    margin-right: 12px;
    font-size: 1.2rem;
    width: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .sidebar {
        transform: translateX(-100%);
    }

    .sidebar.open {
        transform: translateX(0);
    }

    .add-family-member-button {
        display: none; /* Hide on mobile when sidebar is closed */
    }

    .sidebar.open .add-family-member-button {
        display: flex; /* Show when sidebar is open */
        width: 200px; /* Match desktop width */
    }
}

@media (max-width: 480px) {
    .add-family-member-button {
        padding: 10px 16px;
        font-size: 0.9rem;
    }
}

/* Status Indicator Styling */
.status-indicator {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-left: auto;
    padding: 2px 8px;
    background: rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    font-size: 0.65rem;
    color: #D8FF60;
}

.status-dot {
    width: 6px;
    height: 6px;
    background: #D8FF60;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.5; }
    100% { opacity: 1; }
}

/* Status Badge Styling */
.status-badge {
    display: inline-flex;
    align-items: center;
    padding: 4px 12px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 20px;
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 4px;
}

.sidebar-nav {
    flex: 1;
    overflow-y: auto;
    padding: 20px 0;
    padding-bottom: 100px; /* Space for fixed button */
}

.nav-section-divider {
    margin: 20px 24px 10px;
    color: #00ff85;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Add these styles to your existing Sidebar.css */
.nav-item.admin {
    background: rgba(0, 255, 133, 0.1);
    color: rgba(255, 255, 255, 0.9);
}

.nav-item.admin:hover {
    background: rgba(0, 255, 133, 0.2);
    color: #00ff85;
}

.nav-item.admin.active {
    background: rgba(0, 255, 133, 0.15);
    color: #00ff85;
}

.profile-info {
    margin-top: 15px;
}

.user-name {
    color: #fff;
    font-size: 1.2rem;
    margin: 0 0 5px 0;
    font-weight: 600;
}

.member-since {
    font-size: 0.8rem;
    color: rgba(255, 255, 255, 0.5);
    margin-top: 4px;
}

/* Add Family Member Button - Fixed positioning */
.add-family-member-button {
    position: fixed;
    bottom: 20px;
    left: 20px;
    width: 200px; /* Reduced from 240px */
    padding: 12px;
    background: rgba(216, 255, 96, 0.1);
    color: #D8FF60;
    text-decoration: none;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: 500;
    font-size: 0.9rem;
    transition: all 0.3s ease;
    z-index: 1101;
    border: 1px solid rgba(216, 255, 96, 0.2);
}

.add-family-member-button:hover {
    background: rgba(216, 255, 96, 0.15);
    transform: translateY(-1px);
    border-color: rgba(216, 255, 96, 0.3);
}

.button-icon {
    font-size: 0.9rem;
}
