/* Base Layout */
.dashboard-page {
    display: flex;
    min-height: 100vh;
    background-color: #1a1a1a;
    color: #D8FF60;
    overflow-x: hidden;
    padding-top: 64px; /* Height of top nav */
    width: 100%;
    position: relative;
}

/* Loading Spinner */
.loading-spinner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(26, 26, 26, 0.98);
    z-index: 1000;
    border-radius: 16px;
    padding: 32px;
    width: 360px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(216, 255, 96, 0.2);
    backdrop-filter: blur(12px);
}

.loading-spinner-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.loading-spinner-icon {
    width: 64px;
    height: 64px;
    border: 3px solid rgba(216, 255, 96, 0.1);
    border-top-color: #D8FF60;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 12px;
}

.progress-percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #D8FF60;
    font-size: 16px;
    font-weight: 600;
}

.loading-spinner-info {
    width: 100%;
    text-align: center;
}

.loading-spinner-text {
    color: #D8FF60;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.loading-spinner-stage {
    color: rgba(216, 255, 96, 0.7);
    font-size: 14px;
    margin-bottom: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    animation: pulse 2s infinite;
}

.loading-spinner-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 16px;
    width: 100%;
    padding-top: 16px;
    border-top: 1px solid rgba(216, 255, 96, 0.1);
}

.stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
}

.stat-value {
    color: #D8FF60;
    font-size: 20px;
    font-weight: 600;
}

.stat-label {
    color: rgba(216, 255, 96, 0.6);
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    white-space: nowrap;
    line-height: 1.2;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}

/* Content Layout */
.content-wrapper {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
}

.dashboard-main {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dashboard-content {
    flex: 1;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    box-sizing: border-box;
    background-color: rgba(26, 26, 26, 0.95); /* Match main background with transparency */
    border-radius: 22px;
}

/* Frame border effect */
.dashboard-content::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(135deg,
        rgba(216, 255, 96, 0.2) 0%,
        rgba(216, 255, 96, 0.1) 50%,
        rgba(216, 255, 96, 0.05) 100%
    );
    border-radius: 22px;
    z-index: -1;
    pointer-events: none;
}

/* Additional glossy highlight */
.dashboard-content::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(180deg,
        rgba(255, 255, 255, 0.1) 0%,
        rgba(255, 255, 255, 0) 100%
    );
    border-radius: 20px 20px 0 0;
    pointer-events: none;
}

/* Grid overlay effect */
.dashboard-content {
    background-image: 
        linear-gradient(rgba(216, 255, 96, 0.03) 1px, transparent 1px),
        linear-gradient(90deg, rgba(216, 255, 96, 0.03) 1px, transparent 1px);
    background-size: 20px 20px;
    background-position: center center;
}

.dashboard-title {
    font-size: 2.5rem;
    color: #D8FF60;
    margin: 0;
    text-shadow: 
        0 0 10px rgba(216, 255, 96, 0.3),
        0 0 20px rgba(216, 255, 96, 0.2),
        0 0 30px rgba(216, 255, 96, 0.1);
    position: relative;
    z-index: 1;
}

/* Dashboard Header */
.dashboard-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    padding: 12px 16px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    backdrop-filter: blur(10px);
}

.header-content {
    margin-top: 0;
    padding-top: 0;
}

.header-title {
    margin: 0;
    padding: 0;
}

/* Stats Grid */
.dashboard-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    margin-bottom: 20px;
    width: 100%;
}

.stat-card {
    background: rgba(0, 0, 0, 0.4);
    border: 1px solid rgba(216, 255, 96, 0.15);
    border-radius: 12px;
    padding: 16px;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    min-height: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.stat-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
        rgba(216, 255, 96, 0) 0%,
        rgba(216, 255, 96, 0.3) 50%,
        rgba(216, 255, 96, 0) 100%);
}

.stat-card:hover {
    transform: translateY(-2px);
    border-color: rgba(216, 255, 96, 0.3);
    box-shadow: 0 4px 20px rgba(216, 255, 96, 0.1);
}

.stat-header {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 8px;
}

.stat-icon {
    font-size: 1rem;
    color: #D8FF60;
    opacity: 0.9;
}

.stat-title {
    font-size: 0.8rem;
    color: rgba(216, 255, 96, 0.7);
    text-transform: uppercase;
    letter-spacing: 0.5px;
    margin: 0;
    white-space: nowrap;
}

.stat-description {
    font-size: 0.75rem;
    color: rgba(216, 255, 96, 0.6);
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Live Search Section */
.live-search-section {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    padding: 24px;
    margin: 16px 0;
    width: 100%;
}

.live-search-section h3 {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #D8FF60;
    font-size: 1.2rem;
    margin: 0 0 16px 0;
}

.live-indicator {
    display: flex;
    align-items: center;
    gap: 8px;
    background: rgba(216, 255, 96, 0.1);
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.8rem;
}

.live-dot {
    width: 8px;
    height: 8px;
    background-color: #D8FF60;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.live-search-images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
}

.image-preview {
    background: rgba(26, 26, 26, 0.95);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    padding: 20px;
    cursor: pointer;
    transition: all 0.3s ease;
    min-height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.image-preview:hover {
    transform: translateY(-2px);
    border-color: rgba(216, 255, 96, 0.3);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.preview-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(216, 255, 96, 0.05);
}

.site-name {
    color: #D8FF60;
    font-weight: 600;
    font-size: 1.1rem;
    letter-spacing: -0.01em;
}

.analysis-status {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.85rem;
    color: rgba(216, 255, 96, 0.7);
    background: rgba(216, 255, 96, 0.1);
    padding: 4px 12px;
    border-radius: 16px;
}

.status-dot {
    width: 6px;
    height: 6px;
    background-color: #D8FF60;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

.preview-content {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.9rem;
    padding: 8px;
    border-radius: 8px;
    background: rgba(216, 255, 96, 0.03);
}

.pulse-dot {
    width: 8px;
    height: 8px;
    background-color: #D8FF60;
    border-radius: 50%;
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% { opacity: 1; transform: scale(1); }
    50% { opacity: 0.5; transform: scale(0.95); }
    100% { opacity: 1; transform: scale(1); }
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 12px;
    margin: 12px 0;
}

.action-button {
    padding: 12px 24px;
    border-radius: 6px;
    background-color: #D8FF60;
    color: #000;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.2s;
}

.action-button:hover {
    transform: translateY(-2px);
}

/* Status Indicators */
.connection-status {
    position: fixed;
    top: 20px;
    right: 20px;
    padding: 12px 24px;
    border-radius: 8px;
    z-index: 1000;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    gap: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.3s ease-out;
}

@keyframes slideIn {
    from {
        transform: translateY(-100%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.connection-status.success {
    background: rgba(0, 255, 0, 0.1);
    border: 1px solid rgba(0, 255, 0, 0.3);
    color: #00ff00;
}

.connection-status.warning {
    background: rgba(255, 255, 0, 0.1);
    border: 1px solid rgba(255, 255, 0, 0.3);
    color: #ffff00;
}

.connection-status.error {
    background: rgba(255, 0, 0, 0.1);
    border: 1px solid rgba(255, 0, 0, 0.3);
    color: #ff0000;
}

/* Animations */
@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.7; }
    100% { opacity: 1; }
}

/* Desktop Sidebar Styles */
@media (min-width: 769px) {
    .main-content.with-sidebar {
        margin-left: 280px;
        width: calc(100% - 280px);
    }

    .with-sidebar .content-wrapper {
        max-width: calc(1200px - 280px);
    }
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
    .dashboard-page {
        padding-top: 60px;
        width: 100%;
        min-height: 100vh;
        overflow-x: hidden;
        margin-left: 0 !important; /* Override sidebar margin */
    }

    .content-wrapper {
        margin: 0 !important;
        padding: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        min-height: calc(100vh - 60px);
        margin-left: 0 !important; /* Override sidebar margin */
        transform: none !important; /* Remove any transforms */
    }

    /* Override any sidebar-related margins */
    .main-content,
    .main-content.with-sidebar,
    .dashboard-main,
    .dashboard-content {
        margin-left: 0 !important;
        width: 100% !important;
        max-width: 100% !important;
        padding: 10px !important;
    }

    /* Ensure the content takes full width */
    .dashboard-grid,
    .dashboard-stats,
    .live-search-section,
    .breach-check-section {
        width: 100% !important;
        max-width: 100% !important;
        margin-left: 0 !important;
        margin-right: 0 !important;
        box-sizing: border-box !important;
    }

    /* Remove any scaling that might affect layout */
    .dashboard-content {
        transform: none !important;
    }

    /* Ensure proper spacing */
    .dashboard-stats {
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        padding: 0;
    }

    .stat-card {
        margin: 0 !important;
        width: 100% !important;
    }

    /* Adjust card sizes for better visibility */
    .stat-card {
        padding: 16px !important;
        margin-bottom: 8px !important;
        min-height: 80px !important;
    }

    /* Make buttons more visible */
    .action-button {
        padding: 16px !important;
        margin: 8px 0 !important;
        font-size: 16px !important;
        height: auto !important;
        min-height: 48px !important;
    }

    /* Adjust text sizes for better readability */
    .dashboard-title {
        font-size: 1.8rem !important;
    }

    .stat-title {
        font-size: 0.9rem !important;
    }

    .stat-description {
        font-size: 0.85rem !important;
    }

    /* Adjust spacing between sections */
    .dashboard-grid {
        gap: 16px !important;
        margin-bottom: 16px !important;
    }

    .dashboard-stats {
        gap: 12px !important;
        margin: 12px 0 !important;
    }

    /* Make live section more compact */
    .live-search-section {
        padding: 16px !important;
        margin: 12px 0 !important;
    }

    .live-search-section h3 {
        font-size: 1.1rem !important;
    }

    /* Adjust header for better visibility */
    .dashboard-header {
        padding: 16px !important;
        margin-bottom: 12px !important;
    }

    /* Ensure proper text wrapping */
    .stat-label {
        font-size: 0.8rem !important;
        white-space: normal !important;
        line-height: 1.2 !important;
    }

    .stat-value {
        font-size: 1.2rem !important;
    }

    /* Remove frame border effect on mobile */
    .dashboard-content::before,
    .dashboard-content::after {
        display: none;
    }

    .dashboard-grid {
        grid-template-columns: 1fr;
        gap: 12px;
        padding: 0;
        width: 100%;
    }

    .dashboard-stats {
        grid-template-columns: repeat(2, 1fr);
        gap: 8px;
        margin: 8px 0;
        width: 100%;
    }

    .stat-card {
        width: 100%;
        padding: 12px;
        margin: 0;
    }

    /* Fix button alignment and sizing */
    .action-buttons {
        width: 100%;
        margin: 8px 0;
        padding: 0;
    }

    .action-button {
        width: 100%;
        margin: 4px 0;
    }

    /* Fix live search section on mobile */
    .live-search-section {
        margin: 8px 0;
        padding: 16px;
        border-radius: 8px;
        width: 100%;
        box-sizing: border-box;
    }

    .live-search-images {
        grid-template-columns: 1fr;
        gap: 12px;
    }

    .image-preview {
        padding: 16px;
        margin: 0;
    }
}

/* Small Mobile Styles */
@media screen and (max-width: 480px) {
    .main-content,
    .main-content.with-sidebar,
    .dashboard-main,
    .dashboard-content {
        padding: 8px !important;
    }

    .dashboard-stats {
        grid-template-columns: 1fr;
    }

    .dashboard-content {
        transform: scale(0.9);
        padding: 10px !important;
    }

    .dashboard-stats {
        grid-template-columns: 1fr !important;
    }

    .stat-card {
        padding: 14px !important;
    }

    /* Further adjust text sizes */
    .dashboard-title {
        font-size: 1.6rem !important;
    }

    .stat-title {
        font-size: 0.85rem !important;
    }

    .stat-value {
        font-size: 1.1rem !important;
    }

    /* Make buttons even more prominent */
    .action-button {
        padding: 14px !important;
        font-size: 15px !important;
    }

    .dashboard-stats {
        grid-template-columns: 1fr;
    }

    .stat-card {
        padding: 12px;
    }

    /* Adjust spacing for smaller screens */
    .live-search-section {
        padding: 12px;
    }

    .image-preview {
        padding: 12px;
    }

    /* Ensure buttons are properly sized */
    .action-button {
        padding: 10px 16px;
        font-size: 14px;
    }
}

.socket-error-banner {
    background: rgba(255, 59, 48, 0.1);
    border: 1px solid rgba(255, 59, 48, 0.2);
    color: rgba(255, 255, 255, 0.9);
    padding: 12px 20px;
    margin: 0 16px 16px 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 0.9rem;
    backdrop-filter: blur(10px);
}

.socket-error-banner i {
    color: #ff3b30;
    font-size: 1.1rem;
}

.reconnect-button {
    margin-left: auto;
    padding: 6px 12px;
    background: rgba(216, 255, 96, 0.1);
    border: 1px solid rgba(216, 255, 96, 0.3);
    border-radius: 6px;
    color: #D8FF60;
    font-size: 0.85rem;
    cursor: pointer;
    transition: all 0.2s ease;
}

.reconnect-button:hover {
    background: rgba(216, 255, 96, 0.2);
    border-color: rgba(216, 255, 96, 0.4);
    transform: translateY(-1px);
}

@media screen and (max-width: 768px) {
    .socket-error-banner {
        margin: 8px;
        padding: 10px 16px;
        font-size: 0.85rem;
        flex-wrap: wrap;
    }

    .reconnect-button {
        margin-left: 0;
        width: 100%;
        margin-top: 8px;
        padding: 8px;
    }
}

.breach-check-section {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    padding: 24px;
    width: 100%;
    backdrop-filter: blur(10px);
}

.breach-check-section h2 {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #D8FF60;
    font-size: 1.2rem;
    margin: 0 0 8px 0;
}

.breach-check-section h2 i {
    font-size: 1.1rem;
}

.breach-check-section .subtitle {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
    margin: 0 0 20px 0;
    padding-bottom: 16px;
    border-bottom: 1px solid rgba(216, 255, 96, 0.05);
}

.scanning-message {
    display: flex;
    align-items: center;
    gap: 12px;
    background: rgba(216, 255, 96, 0.05);
    padding: 16px;
    border-radius: 8px;
    margin-top: 12px;
}

.scanning-message p {
    display: flex;
    align-items: center;
    gap: 10px;
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
    margin: 0;
}

.scanning-message i {
    color: #D8FF60;
    font-size: 1.1rem;
}

.scanning-message .database-count {
    color: #D8FF60;
    font-weight: 500;
}

.pulse-dot {
    width: 8px;
    height: 8px;
    background-color: #D8FF60;
    border-radius: 50%;
    animation: pulse 2s infinite;
    flex-shrink: 0;
}

@media screen and (max-width: 768px) {
    .breach-check-section {
        padding: 16px;
    }

    .breach-check-section h2 {
        font-size: 1.1rem;
    }

    .breach-check-section .subtitle {
        font-size: 0.9rem;
        margin-bottom: 16px;
    }

    .scanning-message {
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
    }

    .scanning-message p {
        font-size: 0.9rem;
        flex-wrap: wrap;
    }
}

@media screen and (max-width: 480px) {
    .breach-check-section {
        padding: 12px;
    }

    .breach-check-section h2 {
        font-size: 1rem;
    }

    .breach-check-section .subtitle {
        font-size: 0.85rem;
        margin-bottom: 12px;
    }

    .scanning-message {
        padding: 10px;
    }

    .scanning-message p {
        font-size: 0.85rem;
    }
}

.scan-stages {
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 20px;
    padding: 16px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    border: 1px solid rgba(216, 255, 96, 0.05);
}

.scan-stage {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    transition: all 0.3s ease;
}

.scan-stage.active {
    background: rgba(216, 255, 96, 0.05);
    border-color: rgba(216, 255, 96, 0.2);
    box-shadow: 0 0 20px rgba(216, 255, 96, 0.05);
}

.scan-stage.completed {
    border-color: rgba(216, 255, 96, 0.15);
    background: rgba(216, 255, 96, 0.02);
}

.stage-info {
    display: flex;
    align-items: center;
    gap: 12px;
}

.stage-icon {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: rgba(216, 255, 96, 0.1);
    color: #D8FF60;
    font-size: 0.9rem;
}

.stage-name {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.95rem;
    font-weight: 500;
}

.stage-status {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.85rem;
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(216, 255, 96, 0.1);
}

.stage-status.queued {
    color: rgba(255, 255, 255, 0.6);
}

.stage-status.in-progress {
    color: #D8FF60;
    background: rgba(216, 255, 96, 0.1);
    border-color: rgba(216, 255, 96, 0.2);
}

.stage-status.completed {
    color: #D8FF60;
    background: rgba(216, 255, 96, 0.05);
}

.status-indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.4);
}

.status-indicator.queued {
    background: rgba(255, 255, 255, 0.4);
}

.status-indicator.in-progress {
    background: #D8FF60;
    animation: pulse 2s infinite;
}

.status-indicator.completed {
    background: #D8FF60;
}

.stage-progress {
    position: relative;
    height: 2px;
    background: rgba(216, 255, 96, 0.05);
    margin-top: 8px;
    border-radius: 1px;
    overflow: hidden;
}

.stage-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: #D8FF60;
    transition: width 0.3s ease;
}

.stage-progress-glow {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 20px;
    background: linear-gradient(90deg, transparent, rgba(216, 255, 96, 0.5), transparent);
    animation: progressGlow 2s infinite;
}

@keyframes progressGlow {
    0% { transform: translateX(-100%); }
    100% { transform: translateX(500%); }
}

@media screen and (max-width: 768px) {
    .scan-stages {
        padding: 12px;
        gap: 12px;
        margin-top: 16px;
    }

    .scan-stage {
        padding: 10px 12px;
    }

    .stage-name {
        font-size: 0.9rem;
    }

    .stage-status {
        padding: 4px 10px;
        font-size: 0.8rem;
    }

    .stage-icon {
        width: 20px;
        height: 20px;
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 480px) {
    .scan-stages {
        padding: 8px;
        gap: 8px;
        margin-top: 12px;
    }

    .scan-stage {
        padding: 8px 10px;
    }

    .stage-name {
        font-size: 0.85rem;
    }

    .stage-status {
        padding: 3px 8px;
        font-size: 0.75rem;
    }

    .stage-icon {
        width: 18px;
        height: 18px;
        font-size: 0.75rem;
    }
}
