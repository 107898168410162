.success-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 2rem;
    background-color: #1e1e1e;
    color: #ffffff;
    text-align: center;
}

.success-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #D8FF60;
}

.success-message {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #ffffff;
}

.error-message {
    color: #ff6b6b;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 8px;
    background-color: rgba(255, 107, 107, 0.1);
}

.info-message {
    color: #999;
    margin-bottom: 2rem;
    font-size: 1rem;
    max-width: 600px;
}

.button-container {
    display: flex;
    gap: 1rem;
    justify-content: center;
    width: 100%;
    max-width: 500px;
}

.primary-button {
    background-color: #D8FF60;
    color: #1e1e1e;
    padding: 1rem 2rem;
    border: none;
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.primary-button:hover {
    background-color: #c2e654;
    transform: translateY(-2px);
}

.secondary-button {
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    padding: 1rem 2rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 8px;
    font-weight: 600;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
}

.secondary-button:hover {
    background-color: rgba(255, 255, 255, 0.2);
    transform: translateY(-2px);
}

@media (max-width: 640px) {
    .success-container {
        padding: 1.5rem;
    }

    .success-title {
        font-size: 1.5rem;
    }

    .button-container {
        flex-direction: column;
        gap: 0.75rem;
    }

    .primary-button,
    .secondary-button {
        width: 100%;
        padding: 0.75rem 1.5rem;
    }
}

.debug-info {
    margin-top: 20px;
    padding: 15px;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 8px;
    font-family: monospace;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    overflow-x: auto;
}

.debug-info p {
    margin: 0 0 10px 0;
    color: #666;
    font-size: 14px;
}

.debug-info pre {
    margin: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    font-size: 12px;
    line-height: 1.4;
    color: #333;
}
