/* Footer Container */
.footer {
  width: 100%;
  background-color: #0d0d0d;
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #ffffff;
  font-family: "Orbitron", sans-serif;
  margin-top: 40px;
  border-top: 1px solid #333;
  position: relative;
  bottom: 0;
}

/* Logo Section */
.footer-logo {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  text-decoration: none;
  color: #ffffff;
}

.logo-icon {
  color: #d8ff60;
  font-size: 36px;
  filter: drop-shadow(0 0 4px #d8ff60);
}

.logo-title {
  color: #ffffff;
  font-size: 24px;
  font-weight: 700;
  margin-left: 10px;
  display: flex;
  align-items: center;
  text-shadow: 0 0 4px #d8ff60;
}

.logo-subtitle {
  color: #d8ff60;
  font-size: 16px;
  font-weight: 500;
  margin-left: 5px;
  text-shadow: 0 0 3px #d8ff60;
}

/* Footer Content */
.footer-content {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px 0;
}

.footer-column {
  flex: 1;
  margin-bottom: 30px;
  padding: 0 20px;
}

.footer-heading {
  color: #d8ff60;
  margin-bottom: 15px;
  font-size: 18px;
}

.footer-description {
  line-height: 1.5;
  color: #cccccc;
  font-size: 14px;
}

/* Links */
.footer-link {
  color: #d8ff60;
  text-decoration: none;
  font-size: 16px;
  margin: 8px 0;
  display: block;
  transition: color 0.3s, transform 0.3s;
}

.footer-link:hover {
  color: #a0ff00;
  transform: translateX(5px);
}

/* Social Media Icons */
.footer-socials {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  justify-content: center;
}

.social-icon {
  color: #d8ff60;
  font-size: 24px;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
}

.social-icon:hover {
  color: #a0ff00;
  transform: scale(1.2);
}

/* Footer Bottom */
.footer-bottom {
  margin-top: 20px;
  text-align: center;
  color: #777777;
  border-top: 1px solid #333333;
  padding-top: 20px;
  width: 100%;
  font-size: 14px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .footer-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
  }

  .footer-column {
      padding: 0;
      margin-bottom: 20px;
  }

  .footer-logo {
      margin-bottom: 20px;
  }
}
