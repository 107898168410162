.page-container {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
}

.delete-account-main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #ffffff;
    box-sizing: border-box;
    padding-top: 80px; /* Account for navbar */
}

.hero-section {
    text-align: center;
    margin-bottom: 40px;
    padding: 40px;
    background: rgba(30, 30, 30, 0.6);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    position: relative;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
    overflow: hidden;
}

.hero-section::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, rgba(216, 255, 96, 0.2), transparent);
}

.hero-icon {
    font-size: 3rem;
    color: #D8FF60;
    margin-bottom: 25px;
    filter: drop-shadow(0 0 10px rgba(216, 255, 96, 0.3));
}

.hero-section h1 {
    font-size: 2.8rem;
    color: #D8FF60;
    margin-bottom: 25px;
    text-shadow: 0 0 20px rgba(216, 255, 96, 0.3);
    font-weight: 700;
    line-height: 1.2;
}

.hero-subtitle {
    font-size: 1.2rem;
    opacity: 0.9;
    max-width: 800px;
    margin: 0 auto;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
}

.search-section {
    margin: 30px auto 40px;
    max-width: 600px;
    width: 100%;
    padding: 0 20px;
}

.search-wrapper {
    position: relative;
    width: 100%;
    background: rgba(30, 30, 30, 0.6);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 12px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.search-wrapper:hover,
.search-wrapper:focus-within {
    background: rgba(216, 255, 96, 0.1);
    border-color: rgba(216, 255, 96, 0.4);
    box-shadow: 0 4px 25px rgba(216, 255, 96, 0.1);
    transform: translateY(-1px);
}

.search-input {
    flex: 1;
    padding: 16px;
    background: transparent;
    border: none;
    font-size: 1.1rem;
    color: #fff;
    outline: none;
    width: 100%;
}

.search-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.search-results {
    margin-top: 8px;
    background: rgba(30, 30, 30, 0.9);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    backdrop-filter: blur(10px);
    max-height: 400px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.search-result-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 16px;
    color: #fff;
    text-decoration: none;
    transition: all 0.2s ease;
    border-bottom: 1px solid rgba(216, 255, 96, 0.1);
}

.search-result-item:last-child {
    border-bottom: none;
}

.search-result-item:hover {
    background: rgba(216, 255, 96, 0.1);
}

.result-meta {
    display: flex;
    align-items: center;
    gap: 8px;
}

.external-link-icon {
    color: #D8FF60;
    font-size: 0.9rem;
}

.difficulty-badge {
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.difficulty-badge.easy {
    background: rgba(52, 199, 89, 0.15);
    color: #34c759;
    border: 1px solid rgba(52, 199, 89, 0.2);
}

.difficulty-badge.medium {
    background: rgba(255, 204, 0, 0.15);
    color: #ffcc00;
    border: 1px solid rgba(255, 204, 0, 0.2);
}

.difficulty-badge.hard,
.difficulty-badge.impossible {
    background: rgba(255, 59, 48, 0.15);
    color: #ff3b30;
    border: 1px solid rgba(255, 59, 48, 0.2);
}

.info-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
    margin: 40px 20px;
}

.info-card {
    background: rgba(30, 30, 30, 0.6);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 16px;
    padding: 30px;
    transition: all 0.3s ease;
}

.info-card:hover {
    transform: translateY(-3px);
    background: rgba(216, 255, 96, 0.1);
    border-color: rgba(216, 255, 96, 0.2);
    box-shadow: 0 4px 20px rgba(216, 255, 96, 0.1);
}

.info-card .icon-title {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
}

.info-icon {
    font-size: 2rem;
    color: #D8FF60;
}

.info-card h3 {
    color: #D8FF60;
    font-size: 1.3rem;
    font-weight: 600;
    margin: 0;
}

.info-card p {
    color: rgba(255, 255, 255, 0.9);
    line-height: 1.6;
    font-size: 1rem;
    margin: 0;
}

.pulse {
    animation: pulse 2s infinite;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.05);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.popular-guides {
    margin: 40px 0;
    padding: 0 20px;
}

.popular-guides h2 {
    color: #D8FF60;
    margin-bottom: 30px;
    font-size: 1.8rem;
    font-weight: 600;
}

.guides-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 20px;
}

.guide-card {
    background: rgba(30, 30, 30, 0.6);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    padding: 25px;
    text-decoration: none;
    color: #ffffff;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.guide-card:hover {
    background: rgba(216, 255, 96, 0.1);
    transform: translateY(-3px);
    border-color: rgba(216, 255, 96, 0.2);
    box-shadow: 0 4px 20px rgba(216, 255, 96, 0.1);
}

.site-name {
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 15px;
}

.guide-meta {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.guide-link {
    color: #D8FF60;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
    opacity: 0.9;
}

.guide-card:hover .guide-link {
    opacity: 1;
}

.all-platforms {
    margin-bottom: 60px;
}

.all-platforms h2 {
    color: #D8FF60;
    margin-bottom: 30px;
    font-size: 1.8rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.platforms-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
    gap: 20px;
}

.platform-card {
    background: rgba(0, 0, 0, 0.2);
    border: 1px solid rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    padding: 20px;
    text-decoration: none;
    color: #ffffff;
    transition: all 0.3s ease;
    backdrop-filter: blur(5px);
}

.platform-card:hover {
    background: rgba(216, 255, 96, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.platform-card h3 {
    color: #D8FF60;
    margin-bottom: 15px;
    font-size: 1.2rem;
    text-transform: capitalize;
}

.platform-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.view-guide {
    color: #D8FF60;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 0.9rem;
}

.data-broker-link {
    display: inline-block;
    margin-top: 10px;
    color: #D8FF60;
    text-decoration: none;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.data-broker-link:hover {
    color: #ffffff;
}

@media (max-width: 768px) {
    .hero-section {
        padding: 40px 20px;
    }

    .hero-section h1 {
        font-size: 2.4rem;
    }

    .hero-subtitle {
        font-size: 1.1rem;
    }

    .search-section {
        padding: 0 15px;
    }

    .guides-grid {
        grid-template-columns: 1fr;
    }

    .info-cards {
        margin: 30px 15px;
    }
}

.language-switcher {
    margin: 20px 0;
    text-align: center;
}

.language-switcher label {
    margin-right: 10px;
    color: #fff;
}

.language-switcher select {
    padding: 5px 10px;
    border-radius: 5px;
    border: none;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    font-size: 1rem;
} 

/* Scrollbar styling */
.search-results::-webkit-scrollbar {
    width: 8px;
}

.search-results::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.05);
}

.search-results::-webkit-scrollbar-thumb {
    background: rgba(216, 255, 96, 0.2);
    border-radius: 4px;
}

.search-results::-webkit-scrollbar-thumb:hover {
    background: rgba(216, 255, 96, 0.3);
} 

.faq-section {
  max-width: 800px;
  margin: 3rem auto;
  padding: 1.5rem;
  background: rgba(30, 30, 30, 0.9);
  border-radius: 12px;
  border: 1px solid rgba(216, 255, 96, 0.1);
  backdrop-filter: blur(10px);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.faq-section h2 {
  color: #D8FF60;
  font-size: 1.75rem;
  margin-bottom: 1.5rem;
  text-align: left;
  font-weight: 600;
}

.faq-item {
  background: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(216, 255, 96, 0.1);
  border-radius: 8px;
  margin-bottom: 10px;
  overflow: hidden;
}

.faq-question {
  padding: 1rem;
  font-size: 1rem;
  color: #fff;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  gap: 0.75rem;
  line-height: 1.4;
}

.faq-question svg {
  margin-top: 0.2rem;
  flex-shrink: 0;
}

.faq-answer {
  padding: 1.5rem 1rem 1.5rem 2.75rem;
  color: rgba(255, 255, 255, 0.8);
  line-height: 1.6;
  font-size: 0.95rem;
  border-top: 1px solid rgba(216, 255, 96, 0.1);
  margin-top: 0.5rem;
}

/* Mobile optimization */
@media (max-width: 768px) {
  .faq-section {
    margin: 2rem 1rem;
    padding: 1rem;
  }

  .faq-section h2 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }

  .faq-item {
    margin-bottom: 0.75rem;
  }

  .faq-question {
    padding: 0.875rem;
    font-size: 0.95rem;
  }

  .faq-answer {
    padding: 1.25rem 0.875rem 1.25rem 2.5rem;
    font-size: 0.9rem;
  }
}

/* Small mobile devices */
@media (max-width: 480px) {
  .faq-section {
    margin: 1.5rem 0.75rem;
    padding: 0.875rem;
  }

  .faq-section h2 {
    font-size: 1.25rem;
  }

  .faq-item {
    margin-bottom: 0.5rem;
  }
} 

/* Responsive adjustments */
@media screen and (min-width: 1200px) {
    .guides-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@media screen and (max-width: 768px) {
    .info-cards {
        grid-template-columns: repeat(2, 1fr);
    }
    
    .info-card:nth-child(n+5) {
        display: none;
    }

    .guides-grid {
        grid-template-columns: 1fr;
        gap: 10px;
    }

    .guide-card {
        padding: 15px;
    }

    .site-name {
        font-size: 1.1rem;
    }

    .guide-meta {
        margin-top: 5px;
    }
} 

/* Search section styling */
.search-section {
    margin: 30px auto 0;
    max-width: 600px;
    width: 100%;
    padding: 0 20px;
}

.search-container {
    width: 100%;
    position: relative;
}

.search-wrapper {
    position: relative;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 16px;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

/* Info cards layout */
.info-cards {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin: 40px auto;
    padding: 0 20px;
    max-width: 600px;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .search-section {
        padding: 0 15px;
    }

    .search-wrapper {
        padding: 6px 15px;
        margin: 0;
    }

    .info-cards {
        grid-template-columns: 1fr;
        padding: 0 15px;
        gap: 15px;
    }

    .guides-grid {
        grid-template-columns: 1fr !important;
        padding: 0 15px;
    }

    .info-card {
        width: 100%;
    }
}

/* Extra small devices */
@media screen and (max-width: 576px) {
    .search-section {
        padding: 0 10px;
    }

    .info-cards,
    .guides-grid {
        padding: 0 10px;
    }
} 