.change-plan-page {
    min-height: calc(100vh - var(--navbar-height));
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
    padding: 60px 40px;
    color: #ffffff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.app-layout {
    display: flex;
    min-height: 100vh;
    background-color: #121212;
}

.main-content {
    flex: 1;
    margin-left: 0 !important;
    padding-left: 280px !important;
    width: 100% !important;
    min-height: 100vh;
    background-color: #121212;
    padding-top: var(--navbar-height) !important;
}

.plan-header {
    text-align: center;
    margin-bottom: 48px;
    max-width: 700px;
}

.plan-header h1 {
    font-size: 48px;
    background: linear-gradient(135deg, #D8FF60 0%, #A9CC4B 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 16px;
    font-weight: 700;
    letter-spacing: -0.5px;
}

.plan-header p {
    font-size: 18px;
    color: #999;
    font-weight: 400;
    line-height: 1.6;
}

.billing-toggle {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    margin-bottom: 48px;
    background: rgba(255, 255, 255, 0.03);
    padding: 12px 24px;
    border-radius: 100px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
}

.billing-toggle span {
    font-size: 15px;
    color: #999;
    font-weight: 500;
    transition: color 0.3s ease;
}

.billing-toggle span.active {
    color: #fff;
}

.toggle-switch {
    position: relative;
    display: inline-block;
    width: 52px;
    height: 28px;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(216, 255, 96, 0.1);
    transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 100px;
    border: 2px solid rgba(216, 255, 96, 0.2);
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 2px;
    bottom: 2px;
    background: #D8FF60;
    transition: 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border-radius: 50%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

input:checked + .slider {
    background: rgba(216, 255, 96, 0.2);
}

input:checked + .slider:before {
    transform: translateX(24px);
}

.save-badge {
    background: linear-gradient(135deg, #D8FF60 0%, #A9CC4B 100%);
    color: #000;
    padding: 4px 12px;
    border-radius: 100px;
    font-size: 13px;
    font-weight: 600;
    margin-left: 8px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
}

.plans-container {
    display: flex;
    justify-content: center;
    gap: 32px;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

.plan-card {
    background: linear-gradient(135deg, rgba(255, 255, 255, 0.05) 0%, rgba(255, 255, 255, 0.02) 100%);
    border-radius: 24px;
    padding: 40px;
    width: 360px;
    position: relative;
    transition: all 0.4s cubic-bezier(0.4, 0, 0.2, 1);
    border: 1px solid rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(10px);
}

.plan-card:hover {
    transform: translateY(-8px);
    border-color: rgba(216, 255, 96, 0.2);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.plan-card.featured {
    border: 1px solid rgba(216, 255, 96, 0.3);
    background: linear-gradient(135deg, rgba(216, 255, 96, 0.1) 0%, rgba(216, 255, 96, 0.05) 100%);
    transform: scale(1.05);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.3);
}

.plan-card .plan-icon {
    font-size: 32px;
    color: #D8FF60;
    margin-bottom: 24px;
    filter: drop-shadow(0 2px 4px rgba(216, 255, 96, 0.3));
}

.plan-card h2 {
    font-size: 28px;
    margin-bottom: 16px;
    color: #fff;
    font-weight: 700;
}

.price {
    margin: 32px 0;
}

.price .amount {
    font-size: 48px;
    font-weight: 700;
    color: #D8FF60;
    text-shadow: 0 2px 4px rgba(216, 255, 96, 0.2);
}

.price .period {
    color: #999;
    font-size: 18px;
}

.billed-text {
    color: #666;
    font-size: 14px;
    margin-top: 8px;
}

.features-list {
    list-style: none;
    padding: 0;
    margin: 32px 0;
}

.features-list li {
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 12px;
    color: #fff;
    font-size: 15px;
    line-height: 1.6;
}

.features-list li svg {
    color: #D8FF60;
    font-size: 18px;
    flex-shrink: 0;
}

.select-plan-button {
    width: 100% !important;
    padding: 16px !important;
    border-radius: 12px !important;
    border: none !important;
    background: linear-gradient(135deg, #D8FF60 0%, #A9CC4B 100%) !important;
    color: #000 !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    cursor: pointer !important;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1) !important;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2) !important;
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
    position: relative !important;
    z-index: 1 !important;
    outline: none !important;
    margin: 0 !important;
}

.select-plan-button:hover {
    transform: translateY(-2px) !important;
    box-shadow: 0 8px 16px rgba(216, 255, 96, 0.3) !important;
    background: linear-gradient(135deg, #E5FF80 0%, #B8DB5A 100%) !important;
}

.select-plan-button.selected {
    background: linear-gradient(135deg, #A9CC4B 0%, #8BA83D 100%) !important;
    color: #000 !important;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.select-plan-button:active {
    transform: translateY(1px) !important;
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1) !important;
}

.select-plan-button.featured {
    background: linear-gradient(135deg, #D8FF60 0%, #A9CC4B 100%) !important;
    box-shadow: 0 8px 24px rgba(216, 255, 96, 0.4) !important;
}

.select-plan-button.featured:hover {
    background: linear-gradient(135deg, #E5FF80 0%, #B8DB5A 100%) !important;
    box-shadow: 0 12px 28px rgba(216, 255, 96, 0.5) !important;
}

/* Add these styles at the end of your file */
button.select-plan-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    opacity: 1 !important;
    pointer-events: auto !important;
}

.popular-badge {
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
    background: linear-gradient(135deg, #D8FF60 0%, #A9CC4B 100%);
    color: #000;
    padding: 8px 20px;
    border-radius: 100px;
    font-weight: 600;
    font-size: 14px;
    text-shadow: 0 1px 0 rgba(255, 255, 255, 0.2);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1200px) {
    .plans-container {
        padding: 0;
    }
    
    .plan-card {
        width: 320px;
        padding: 32px;
    }
}

@media (max-width: 1024px) {
    .plan-card {
        width: 300px;
    }
}

@media (max-width: 768px) {
    .change-plan-page {
        padding: 40px 20px;
    }

    .plan-header h1 {
        font-size: 36px;
    }

    .plans-container {
        flex-direction: column;
        align-items: center;
        gap: 32px;
    }

    .plan-card {
        width: 100%;
        max-width: 400px;
    }

    .plan-card.featured {
        transform: none;
    }
}

@media (max-width: 480px) {
    .change-plan-page {
        padding: 32px 16px;
    }

    .plan-header h1 {
        font-size: 32px;
    }

    .billing-toggle {
        flex-direction: column;
        padding: 16px;
    }
} 