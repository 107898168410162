.setup-password-wrapper {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100vw !important;
    height: 100vh !important;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, var(--background-darker) 0%, var(--background-dark) 100%);
    padding: 20px;
    z-index: 9999 !important;
}

.setup-password-page {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.setup-password-container {
    background: #1A1A1A;
    border-radius: 12px;
    padding: 40px;
    width: 100%;
    max-width: 480px;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
}

.setup-password-container h1 {
    color: #FFFFFF;
    font-size: 28px;
    margin-bottom: 30px;
    text-align: center;
    font-weight: 600;
}

.error-banner {
    background: rgba(255, 59, 48, 0.1);
    border: 1px solid #FF3B30;
    color: #FF3B30;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 14px;
}

.success-banner {
    background: rgba(52, 199, 89, 0.1);
    border: 1px solid #34C759;
    color: #34C759;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-field {
    margin-bottom: 24px;
}

.form-field label {
    display: flex;
    align-items: center;
    gap: 8px;
    color: #FFFFFF;
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
}

.field-icon {
    color: #D8FF60;
}

.password-input-container {
    position: relative;
    display: flex;
    align-items: center;
}

.password-input-container input {
    width: 100%;
    padding: 14px;
    background: #2C2C2E;
    border: 1px solid #3A3A3C;
    border-radius: 8px;
    color: #FFFFFF;
    font-size: 16px;
    transition: all 0.3s ease;
}

.password-input-container input:focus {
    border-color: #D8FF60;
    outline: none;
    box-shadow: 0 0 0 2px rgba(216, 255, 96, 0.1);
}

.toggle-password {
    position: absolute;
    right: 12px;
    background: none;
    border: none;
    color: #8E8E93;
    cursor: pointer;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.toggle-password:hover {
    color: #D8FF60;
}

.password-requirements {
    margin-top: 12px;
    padding: 12px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 8px;
}

.requirement {
    color: #FF3B30;
    font-size: 12px;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.requirement:last-child {
    margin-bottom: 0;
}

.setup-password-container .submit-button {
    width: 100% !important;
    padding: 16px !important;
    background: #D8FF60 !important;
    border: none !important;
    border-radius: 8px !important;
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    margin-top: 20px !important;
    text-transform: uppercase !important;
    letter-spacing: 0.5px !important;
    display: block !important;
    text-align: center !important;
    line-height: 1.5 !important;
    height: auto !important;
    min-height: unset !important;
    position: relative !important;
    z-index: 100 !important;
}

.setup-password-container .submit-button:hover {
    background: #E2FF80 !important;
    transform: translateY(-1px) !important;
    box-shadow: 0 4px 12px rgba(216, 255, 96, 0.2) !important;
}

.setup-password-container .submit-button:active {
    transform: translateY(0) !important;
}

@media (max-width: 768px) {
    .setup-password-wrapper {
        padding: 16px;
    }

    .setup-password-container {
        padding: 24px;
    }

    .setup-password-container h1 {
        font-size: 24px;
    }

    .setup-password-container .submit-button {
        padding: 14px !important;
        font-size: 14px !important;
    }
}

@media (max-width: 480px) {
    .setup-password-wrapper {
        padding: 12px;
    }

    .setup-password-container {
        padding: 20px;
    }

    .setup-password-container h1 {
        font-size: 24px;
    }

    .setup-password-container .submit-button {
        padding: 14px !important;
        font-size: 14px !important;
    }
} 