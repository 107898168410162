/* src/pages/ResultsPage.css */

/* Reset and Base Styles */
*, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

/* General Styling */
.results-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;
    background-color: #1e1e1e;
    color: #fff;
    padding: 40px 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

/* Header Styling */
.results-header {
    text-align: center;
    margin-bottom: 40px;
    max-width: 800px;
}

.warning-icon {
    font-size: 48px;
    color: #D8FF60;
    margin-bottom: 20px;
}

.results-header h1 {
    font-size: 2.5rem;
    color: #D8FF60;
    margin-bottom: 15px;
}

.results-header h2 {
    font-size: 2rem;
    color: #fff;
    margin-bottom: 15px;
}

.results-header p {
    font-size: 1.1rem;
    color: #ccc;
}

/* Exposure Summary */
.exposure-summary {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    max-width: 800px;
    margin-bottom: 40px;
}

.stat-box {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 12px;
    padding: 20px;
    text-align: center;
}

.stat-icon {
    font-size: 24px;
    color: #D8FF60;
    margin-bottom: 10px;
}

.stat-content h3 {
    font-size: 1.5rem;
    color: #fff;
    margin-bottom: 5px;
}

.stat-content p {
    color: #ccc;
    font-size: 0.9rem;
}

/* Threats Container */
.threats-container {
    width: 100%;
    max-width: 800px;
    margin-bottom: 40px;
}

.threats-container h2 {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #D8FF60;
    margin-bottom: 20px;
    font-size: 1.5rem;
}

.section-icon {
    font-size: 1.2rem;
}

.threats-list {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 12px;
    padding: 20px;
    max-height: 500px;
    overflow-y: auto;
}

.exposure-item {
    background: rgba(30, 30, 30, 0.9);
    border-radius: 12px;
    padding: 20px;
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    transition: all 0.3s ease;
}

/* Add staggered animation delay for each item */
.exposure-item:nth-child(1) { animation-delay: 0.1s; }
.exposure-item:nth-child(2) { animation-delay: 0.2s; }
.exposure-item:nth-child(3) { animation-delay: 0.3s; }
.exposure-item:nth-child(4) { animation-delay: 0.4s; }
.exposure-item:nth-child(5) { animation-delay: 0.5s; }
.exposure-item:nth-child(6) { animation-delay: 0.6s; }

@keyframes slideIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.exposure-item:hover {
    border-color: rgba(216, 255, 96, 0.4);
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.exposure-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    height: 50px;
    background: rgba(216, 255, 96, 0.1);
    border-radius: 12px;
    font-size: 24px;
    color: #D8FF60;
    transition: all 0.3s ease;
}

.exposure-item:hover .exposure-icon {
    transform: scale(1.1);
    background: rgba(216, 255, 96, 0.2);
}

.exposure-content {
    flex: 1;
}

.site-name {
    color: #D8FF60;
    font-weight: 600;
    font-size: 1.1rem;
    margin-bottom: 8px;
    display: block;
}

.exposure-category {
    display: inline-block;
    background: rgba(216, 255, 96, 0.1);
    color: #D8FF60;
    padding: 6px 12px;
    border-radius: 20px;
    font-size: 0.85rem;
    font-weight: 500;
    margin-bottom: 8px;
}

.exposure-details {
    color: #ccc;
    font-size: 0.95rem;
    line-height: 1.5;
    opacity: 0.9;
}

/* Add a subtle scroll indicator */
.threats-list::-webkit-scrollbar {
    width: 8px;
}

.threats-list::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
}

.threats-list::-webkit-scrollbar-thumb {
    background: rgba(216, 255, 96, 0.2);
    border-radius: 4px;
}

.threats-list::-webkit-scrollbar-thumb:hover {
    background: rgba(216, 255, 96, 0.3);
}

/* Add a container fade effect */
.threats-container {
    position: relative;
}

.threats-container::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 40px;
    background: linear-gradient(to top, rgba(30, 30, 30, 1), transparent);
    pointer-events: none;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .exposure-item {
        padding: 15px;
    }

    .exposure-icon {
        min-width: 40px;
        height: 40px;
        font-size: 20px;
    }

    .site-name {
        font-size: 1rem;
    }

    .exposure-category {
        font-size: 0.8rem;
        padding: 4px 10px;
    }

    .exposure-details {
        font-size: 0.9rem;
    }
}

/* Action Steps */
.action-steps {
    width: 100%;
    max-width: 800px;
    margin-bottom: 40px;
    text-align: center;
}

.action-steps h2 {
    color: #D8FF60;
    margin-bottom: 30px;
    font-size: 1.5rem;
}

.steps-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.step {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(216, 255, 96, 0.2);
    border-radius: 12px;
    padding: 20px;
}

.step-icon {
    font-size: 24px;
    color: #D8FF60;
    margin-bottom: 15px;
}

.step h3 {
    color: #fff;
    margin-bottom: 10px;
    font-size: 1.1rem;
}

.step p {
    color: #ccc;
    font-size: 0.9rem;
}

/* CTA Section */
.cta-container {
    text-align: center;
    margin: 40px 0;
    padding: 40px;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    width: 100%;
    max-width: 800px;
}

.cta-container h2 {
    color: #D8FF60;
    font-size: 2rem;
    margin-bottom: 20px;
}

.cta-container p {
    color: #ccc;
    margin-bottom: 30px;
}

/* Specific button styling with higher specificity */
.cta-container .cta-button,
.results-page .cta-button {
    background: linear-gradient(45deg, #D8FF60, #A3FF00) !important;
    color: #111 !important;
    padding: 15px 40px;
    border-radius: 10px;
    border: none;
    font-size: 1.2rem;
    font-weight: 700;
    cursor: pointer;
    transition: all 0.3s ease;
    text-align: center;
    display: inline-block;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.3);
    width: auto;
    min-width: 200px;
}

.cta-container .cta-button:hover,
.results-page .cta-button:hover {
    background: linear-gradient(45deg, #B8FF50, #8AFF20) !important;
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(184, 255, 80, 0.5);
}

.guarantee {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
    color: #ccc;
}

/* Redirect Message */
.redirect-notice {
    display: flex;
    align-items: center;
    gap: 15px;
    color: #D8FF60;
    margin-top: 20px;
    padding: 15px;
    background: rgba(216, 255, 96, 0.1);
    border-radius: 8px;
}

.loading-icon {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.loading-icon.spinning {
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

/* Responsive Design */
@media (max-width: 768px) {
    .exposure-summary,
    .steps-container {
        grid-template-columns: 1fr;
    }

    .results-header h1 {
        font-size: 2rem;
    }

    .results-header h2 {
        font-size: 1.5rem;
    }

    .stat-box,
    .step {
        padding: 15px;
    }

    .threats-container {
        padding: 0 10px;
    }

    .exposure-item {
        flex-direction: column;
        padding: 12px;
    }

    .exposure-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
    }

    .exposure-icon {
        margin-bottom: 8px;
    }

    .site-name {
        font-size: 1rem;
        margin-bottom: 4px;
    }

    .exposure-category {
        font-size: 0.75rem;
        padding: 3px 8px;
    }

    .exposure-details {
        font-size: 0.85rem;
    }

    .risk-level {
        margin-top: 8px;
    }

    /* Improve scrolling on mobile */
    .threats-list {
        max-height: 350px;
        padding: 12px;
        -webkit-overflow-scrolling: touch;
    }

    /* Adjust container width for mobile */
    .threats-container {
        width: 95%;
        margin: 0 auto 30px auto;
    }
}

/* Add styles for even smaller screens */
@media (max-width: 480px) {
    .threats-container h2 {
        font-size: 1.2rem;
    }

    .exposure-item {
        padding: 10px;
    }

    .exposure-details {
        font-size: 0.8rem;
    }

    .threats-list {
        padding: 10px;
    }
}

.category-breakdown {
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 20px;
    margin: 20px 0;
}

.category-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-top: 15px;
}

.category-item {
    display: flex;
    align-items: center;
    gap: 15px;
    padding: 15px;
    background: rgba(255, 255, 255, 0.03);
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.category-icon {
    font-size: 24px;
    color: #D8FF60;
}

.category-content h4 {
    margin: 0;
    color: #fff;
}

.category-content p {
    margin: 5px 0 0;
    color: #D8FF60;
    font-size: 14px;
}

.risk-level span {
    font-weight: bold;
    padding: 2px 8px;
    border-radius: 3px;
    background-color: rgba(255, 255, 255, 0.1);
}

.success-icon {
    font-size: 48px;
    color: #4CAF50;
    margin-bottom: 20px;
}

.pulse-loader {
    width: 20px;
    height: 20px;
    background-color: #D8FF60;
    border-radius: 50%;
    animation: pulse 1.5s ease-in-out infinite;
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        opacity: 0.5;
    }
    50% {
        transform: scale(1.2);
        opacity: 1;
    }
    100% {
        transform: scale(0.8);
        opacity: 0.5;
    }
}

/* Update fade-out animation */
.fade-out {
    animation: fadeToBlack 0.5s ease-in-out forwards;
}

@keyframes fadeToBlack {
    from { 
        opacity: 1;
        background: transparent;
    }
    to { 
        opacity: 0;
        background: #000000;
    }
}

/* Add this to ensure the fade covers the whole screen */
body.fade-out {
    position: relative;
}

body.fade-out::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000000;
    z-index: 9999;
    animation: fadeToBlack 0.5s ease-in-out forwards;
}

/* Icon Base Styles */
.app-icon {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    background: #1e1e1e;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    margin-bottom: 12px;
}

/* Icon Container */
.exposure-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
}

/* Individual Icons */
.globe-icon,
.search-icon,
.document-icon,
.social-icon,
.contact-icon {
    font-size: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

/* Icon colors */
.globe-icon { color: #ff4b4b; }
.search-icon { color: #4b9eff; }
.document-icon { color: #ffb74b; }
.social-icon { color: #ff6b4b; }
.contact-icon { color: #4bff9f; }

/* Exposure Item Layout */
.exposure-item {
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 15px;
    align-items: center;
    padding: 20px;
}

/* Make sure the icon container takes full height */
.exposure-item .app-icon {
    align-self: center;
    justify-self: center;
}

/* Update exposure item styling */
.exposure-item {
    background: rgba(30, 30, 30, 0.9);
    border-radius: 12px;
    padding: 20px;
    display: grid;
    grid-template-columns: 48px 1fr;
    gap: 15px;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    transition: all 0.3s ease;
}

/* Icon styling */
.app-icon {
    width: 48px;
    height: 48px;
    border-radius: 10px;
    background: rgba(30, 30, 30, 0.9);
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

/* Individual icon styles */
.globe-icon,
.search-icon,
.social-icon,
.contact-icon,
.document-icon,
.database-icon {
    font-size: 24px;
    color: #D8FF60;
}

/* Content styling */
.exposure-content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.site-name {
    color: #D8FF60;
    font-size: 1.1rem;
    font-weight: 500;
}

.exposure-category {
    display: inline-block;
    background: rgba(216, 255, 96, 0.1);
    padding: 4px 12px;
    border-radius: 16px;
    font-size: 0.9rem;
    color: #D8FF60;
}

.exposure-details {
    color: #ccc;
    font-size: 0.95rem;
    margin-top: 4px;
}

/* Hover effects */
.exposure-item:hover {
    transform: translateY(-2px);
    border-color: rgba(216, 255, 96, 0.2);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

/* Icon colors */
.globe-icon { color: #ff4b4b; }
.search-icon { color: #4b9eff; }
.social-icon { color: #ff6b4b; }
.contact-icon { color: #4bff9f; }
.document-icon { color: #ffb74b; }
.database-icon { color: #D8FF60; }
