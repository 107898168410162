/* Reset styles for html and body */
html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    overflow-x: hidden;
    background-color: #0a0a0a;
}

#root {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

/* Page container */
.location-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    background-color: #0a0a0a;
    color: #e0e0e0;
    font-family: 'Plus Jakarta Sans', sans-serif;
    position: relative;
    overflow-x: hidden;
}

/* Main content container */
.main-content.full-screen {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
}

.location-container {
    width: 100%;
    max-width: 800px;
    box-sizing: border-box;
    padding: 20px;
    margin: 0 auto;
    padding-top: 80px; /* Add padding for navbar on desktop */
}

.global-content-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    max-width: 100% !important;
    display: flex;
    justify-content: center;
}

@media (max-width: 768px) {
    .location-container {
        padding: 15px;
        padding-top: 80px; /* Increased top padding for mobile */
        margin: 0;
        width: 100%;
    }

    .main-content.full-screen {
        padding-top: 60px !important; /* Ensure proper mobile top spacing */
    }

    .global-content-wrapper {
        width: 100% !important;
        min-height: calc(100vh - 60px);
    }
}

/* Header Card */
.header-card {
    margin-bottom: 25px;
}

.header-card h1 {
    font-size: 2.2rem;
    color: #fff;
    text-align: center;
    margin-bottom: 20px;
    line-height: 1.3;
    text-shadow: 0 0 8px rgba(216, 255, 96, 0.7);
}

/* Changing text styling */
.changing-text {
    color: #D8FF60;
    font-weight: bold;
    background-color: rgba(30, 30, 30, 0.8);
    padding: 5px 10px;
    border-radius: 8px;
    animation: pulseGlow 2s infinite;
    display: inline-block;
    margin-top: 10px;
}

@keyframes pulseGlow {
    0%, 100% { box-shadow: 0 0 3px rgba(216, 255, 96, 0.5); }
    50% { box-shadow: 0 0 10px rgba(216, 255, 96, 0.8); }
}

.location-container p {
    color: #ccc;
    font-size: 1.1rem;
    text-align: center;
    margin-bottom: 25px;
    line-height: 1.5;
}

/* Name Prompt */
.name-prompt {
    background-color: rgba(42, 42, 42, 0.8);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 25px;
    text-align: center;
}

.name-prompt .name-input-group {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

/* Input Fields and Button */
.location-input-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
    position: relative;
    width: 100%;
    gap: 15px;
}

.input-wrapper {
    position: relative;
    width: 100%;
}

.location-input {
    width: 100%;
    padding: 15px;
    margin-bottom: 15px;
    border-radius: 10px;
    border: 2px solid rgba(216, 255, 96, 0.3);
    background-color: rgba(30, 30, 30, 0.8);
    color: #fff;
    font-size: 1rem;
    transition: all 0.3s ease;
}

.location-input:focus {
    border-color: #D8FF60;
    box-shadow: 0 0 10px rgba(216, 255, 96, 0.5);
    outline: none;
}

.suggestions {
    width: 100%;
    background-color: rgba(30, 30, 30, 0.9);
    border: 1px solid rgba(216, 255, 96, 0.3);
    border-radius: 0 0 10px 10px;
    margin-top: -15px;
    padding-top: 5px;
    list-style: none;
    max-height: 150px;
    overflow-y: auto;
    z-index: 100;
    position: absolute;
}

.suggestion-item {
    padding: 10px 15px;
    color: #ccc;
    cursor: pointer;
    transition: all 0.3s ease;
}

.suggestion-item:hover, .suggestion-item:active {
    background-color: rgba(216, 255, 96, 0.2);
    color: #D8FF60;
}

/* Buttons */
.location-button {
    width: 100%;
    padding: 15px;
    margin-top: 15px;
    border-radius: 10px;
    background-color: #D8FF60 !important;
    color: #111 !important;
    font-size: 1.1rem;
    font-weight: bold;
    cursor: pointer;
    transition: all 0.3s ease;
    border: none;
    text-align: center;
    position: relative;
    overflow: hidden;
    display: block !important;
    z-index: 1;
}

.location-button:hover {
    background-color: #e5ff8a !important;
    transform: translateY(-2px);
    box-shadow: 0 5px 15px rgba(216, 255, 96, 0.3);
}

.location-button:active {
    transform: translateY(0);
    box-shadow: 0 2px 8px rgba(216, 255, 96, 0.2);
}

.location-button:disabled {
    background-color: #666 !important;
    cursor: not-allowed;
    transform: none;
    box-shadow: none;
    opacity: 0.7;
}

/* Add loading state styles if needed */
.location-button.loading {
    opacity: 0.8;
    cursor: wait;
}

/* Location Info */
.location-info {
    margin-top: 20px;
    padding: 20px;
    background-color: rgba(20, 20, 20, 0.8);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.location-info-title {
    font-size: 1rem;
    color: #D8FF60;
    font-weight: bold;
    text-align: center;
}

.location-info-description {
    color: #fff;
    font-size: 0.9rem;
    line-height: 1.4;
    margin-bottom: 10px;
    text-align: center;
}

.location-info-cta {
    color: #fff;
    font-size: 0.9rem;
    margin: 0;
    text-align: center;
}

/* Text emphasis */
.location-info strong {
    color: #D8FF60;
    font-weight: 600;
}

/* Larger screens - switch to side-by-side layout */
@media (min-width: 768px) {
    .header-card h1 {
        font-size: 3rem;
    }

    .location-container p {
        font-size: 1.3rem;
    }

    .name-prompt .name-input-group {
        flex-direction: row;
    }

    .location-input, .location-button {
        font-size: 1.2rem;
    }

    .location-stats {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .stat-item {
        flex: 1 1 300px;
        margin: 20px;
    }

    .highlighted-number {
        font-size: 3rem;
    }

    .stat-item p {
        font-size: 1.2rem;
    }
}

/* Statistics Section */
.location-stats {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    margin-top: 30px;
}

.stat-item {
    background-color: rgba(30, 30, 30, 0.8);
    padding: 25px;
    border-radius: 15px;
    text-align: center;
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.highlighted-number {
    font-size: 3.5rem;
    color: #D8FF60;
    font-weight: bold;
    margin-bottom: 10px;
    text-shadow: 0 0 15px rgba(216, 255, 96, 0.4);
    line-height: 1;
}

.stat-item p {
    color: #ccc;
    font-size: 1.1rem;
    line-height: 1.4;
    margin: 0;
}

/* Review Section */
.review-section {
    width: 100%;
    margin-top: 40px;
    padding: 20px;
    background-color: rgba(30, 30, 30, 0.8);
    border-radius: 15px;
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.2);
}
