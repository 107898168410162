body, html, #root {
    margin: 0;
    padding: 0;
    height: 100%;
    box-sizing: border-box;
}

*,
*::before,
*::after {
    box-sizing: inherit;
}

.login-page-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: linear-gradient(145deg, #0f0f0f 0%, #1a1a1a 100%);
}

.auth-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 100%;
    margin-top: 60px;
    gap: 30px;
}

.auth-wrapper {
    display: flex;
    gap: 80px;
    align-items: center;
    max-width: 1800px;
    width: 100%;
    padding: 0 40px;
}

.auth-image {
    flex: 2.5;
    max-width: 1200px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.auth-image img {
    width: 100%;
    height: auto;
    border-radius: 16px;
    box-shadow: 0 12px 40px rgba(0, 0, 0, 0.4);
    transition: transform 0.3s ease;
}

.auth-image img:hover {
    transform: scale(1.01);
}

.auth-content {
    width: 100%;
    max-width: 400px;
    flex-shrink: 0;
    padding: 30px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 12px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(216, 255, 96, 0.1);
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.auth-header {
    margin-bottom: 0;
    text-align: center;
}

.brand {
    margin: 0;
    font-size: 2rem;
    font-weight: 700;
    color: #D8FF60;
    margin-bottom: 1rem;
    letter-spacing: -0.5px;
}

.auth-title {
    font-size: 1.75rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
    background: linear-gradient(135deg, #D8FF60 0%, #A8FF00 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    letter-spacing: -0.5px;
}

.auth-subtitle {
    color: #a0a0a0;
    font-size: 1rem;
    line-height: 1.5;
}

.auth-form {
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
    color: #ffffff;
}

.form-field {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form-field label {
    display: block;
    margin-bottom: 8px;
    color: white;
    font-size: 14px;
}

.form-field input {
    width: 100%;
    height: 45px;
    background: rgba(42, 42, 42, 0.8);
    border: 1px solid rgba(216, 255, 96, 0.3);
    border-radius: 8px;
    padding: 0 15px;
    color: white;
    font-size: 16px;
}

.auth-form button[type="submit"] {
    margin-top: 8px !important;
    width: 100% !important;
    padding: 15px !important;
    background: linear-gradient(135deg, #D8FF60 0%, #A8FF00 100%) !important;
    color: #000000 !important;
    border: 1px solid rgba(216, 255, 96, 0.3) !important;
    border-radius: 8px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    cursor: pointer !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 4px 15px rgba(216, 255, 96, 0.2) !important;
    display: block !important;
    text-align: center !important;
    line-height: 1.5 !important;
    text-transform: none !important;
    text-decoration: none !important;
    font-family: inherit !important;
    appearance: none !important;
    -webkit-appearance: none !important;
    position: relative !important;
    height: auto !important;
    min-height: 45px !important;
    opacity: 1 !important;
    visibility: visible !important;
    pointer-events: auto !important;
    overflow: visible !important;
}

.auth-form button[type="submit"]:hover {
    background: linear-gradient(135deg, #c6e650 0%, #96e600 100%) !important;
    transform: translateY(-2px) !important;
    box-shadow: 0 6px 20px rgba(216, 255, 96, 0.3) !important;
}

.auth-form button[type="submit"]:active {
    transform: translateY(1px) !important;
    box-shadow: 0 2px 10px rgba(216, 255, 96, 0.2) !important;
}

.auth-form button[type="submit"]:disabled {
    background: linear-gradient(135deg, #4a4a4a 0%, #333333 100%) !important;
    color: rgba(255, 255, 255, 0.5) !important;
    cursor: not-allowed !important;
    transform: none !important;
    box-shadow: none !important;
    border-color: transparent !important;
}

/* Remove any conflicting styles */
.login-button, 
.auth-button, 
button[type="submit"].login-button,
button[type="submit"].auth-button {
    all: unset;
    display: none;
}

.auth-links {
    margin-top: 16px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    flex-wrap: wrap;
}

.text-button {
    padding: 8px 12px;
    display: inline-block;
    background: none;
    border: none;
    color: #D8FF60;
    cursor: pointer;
    font-size: 0.95rem;
    transition: all 0.3s ease;
    text-decoration: none;
    white-space: nowrap;
}

.text-button:hover {
    color: #c6e650;
    transform: translateY(-1px);
}

.text-button:active {
    transform: translateY(0);
}

.separator {
    color: rgba(216, 255, 96, 0.3);
    display: inline-block;
}

.alert {
    padding: 16px 20px;
    border-radius: 12px;
    margin-bottom: 20px;
    text-align: left;
    font-size: 0.95rem;
    display: flex;
    align-items: center;
    gap: 12px;
    line-height: 1.5;
    background: rgba(18, 18, 18, 0.95);
    border: 1px solid;
    backdrop-filter: blur(10px);
}

.alert.success {
    background: rgba(52, 211, 153, 0.05);
    border-color: rgba(52, 211, 153, 0.2);
    color: #34D399;
}

.alert.error {
    background: rgba(239, 68, 68, 0.05);
    border-color: rgba(239, 68, 68, 0.2);
    color: #EF4444;
}

.alert::before {
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 1.1rem;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
}

.alert.success::before {
    content: "\f00c";
}

.alert.error::before {
    content: "\f071";
}

.validation-errors {
    background: rgba(18, 18, 18, 0.95);
    border: 1px solid rgba(239, 68, 68, 0.2);
    border-radius: 12px;
    padding: 20px;
    margin-bottom: 20px;
    backdrop-filter: blur(10px);
}

.validation-errors > :first-child {
    color: #EF4444;
    font-size: 0.95rem;
    font-weight: 600;
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.validation-errors > :first-child::before {
    content: "\f071";
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    font-size: 1.1rem;
}

.validation-error {
    color: rgba(255, 255, 255, 0.9);
    font-size: 0.9rem;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding-left: 28px;
    position: relative;
}

.validation-error:last-child {
    margin-bottom: 0;
}

.validation-error::before {
    content: "";
    position: absolute;
    left: 10px;
    width: 6px;
    height: 6px;
    background-color: rgba(239, 68, 68, 0.5);
    border-radius: 50%;
}

/* Mobile adjustments */
@media screen and (max-width: 768px) {
    .validation-errors {
        padding: 16px;
        margin-bottom: 16px;
    }

    .validation-error {
        font-size: 0.85rem;
        padding-left: 24px;
    }

    .alert {
        padding: 14px 16px;
        font-size: 0.9rem;
    }
}

@media (min-width: 641px) {
    .auth-container {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 40px;
    }

    .auth-content {
        margin-right: 2rem;
    }

    .auth-image {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 2.5rem;
        background: rgba(255, 255, 255, 0.02);
    }

    .auth-image img {
        max-width: 100%;
        height: auto;
        border-radius: 20px;
        box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
    }

    .auth-links {
        flex-direction: row;
        justify-content: center;
    }

    .separator {
        display: inline-block;
        margin: 0 0.5rem;
        color: #a0a0a0;
    }
}

@media (max-width: 1400px) {
    .auth-wrapper {
        max-width: 1400px;
        padding: 0 30px;
    }
    
    .auth-image {
        flex: 2;
        max-width: 900px;
    }
}

@media (max-width: 768px) {
    .auth-wrapper {
        flex-direction: column;
        gap: 20px;
        padding: 0;
    }

    .auth-content {
        order: 1;
        width: 100%;
        padding: 20px;
        margin: 0 15px;
    }

    .auth-image {
        order: 2;
        max-width: 100%;
        padding: 0 15px;
    }

    .auth-image img {
        max-width: 100%;
        border-radius: 8px;
    }

    .auth-header {
        margin-bottom: 1.5rem;
    }

    .brand {
        font-size: 1.8rem;
    }

    .form-field input {
        font-size: 16px;
        padding: 12px 15px;
    }

    .auth-title {
        font-size: 1.5rem;
    }

    .auth-subtitle {
        font-size: 0.95rem;
    }

    .auth-links {
        flex-direction: row;
        padding: 8px 0;
        gap: 16px;
    }

    .separator {
        display: inline-block;
        margin: 0;
        color: rgba(216, 255, 96, 0.3);
    }

    .text-button {
        font-size: 0.9rem;
        padding: 8px 0;
    }

    .check-button {
        padding: 12px 20px;
        font-size: 0.95rem;
    }

    .spinner {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 360px) {
    .auth-links {
        flex-direction: column;
        gap: 8px;
    }

    .separator {
        display: none;
    }
}

.password-requirements {
    background: rgba(26, 26, 26, 0.95);
    border: 1px solid rgba(216, 255, 96, 0.15);
    border-radius: 12px;
    padding: 16px;
    margin: 16px 0;
}

.requirements-title {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}

.requirements-list {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.requirement-item {
    display: flex;
    align-items: center;
    gap: 8px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 13px;
    transition: all 0.3s ease;
}

.requirement-item.met {
    color: #D8FF60;
}

.requirement-icon {
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
}

.requirement-item.met .requirement-icon {
    color: #D8FF60;
}

/* Update existing validation errors to be hidden when using new requirements */
.validation-errors {
    display: none;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .password-requirements {
        padding: 12px;
        margin: 12px 0;
    }

    .requirements-title {
        font-size: 13px;
        margin-bottom: 10px;
    }

    .requirement-item {
        font-size: 12px;
    }
}
