/* General Support Page Styling */
.support-page {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
    color: #ffffff;
    display: flex;
    position: relative;
}

.support-main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

/* Support Header Styling */
.support-header {
    text-align: center;
    margin-bottom: 60px;
    position: relative;
    padding: 20px;
    background: rgba(30, 30, 30, 0.5);
    border-radius: 15px;
    border: 1px solid rgba(216, 255, 96, 0.1);
}

.header-content {
    position: relative;
    z-index: 2;
}

.support-icon {
    font-size: 3rem;
    color: #D8FF60;
    margin-bottom: 20px;
    filter: drop-shadow(0 0 10px rgba(216, 255, 96, 0.6));
}

.support-header h1 {
    font-size: 3rem;
    color: #D8FF60;
    text-shadow: 0 0 15px rgba(216, 255, 96, 0.6);
    margin-bottom: 15px;
    font-family: 'Orbitron', sans-serif;
}

.support-header p {
    font-size: 1.2rem;
    color: #cccccc;
    max-width: 600px;
    margin: 0 auto;
}

/* Cyber Lines */
.cyber-line {
    height: 2px;
    background: linear-gradient(90deg, transparent, #D8FF60, transparent);
    margin: 20px auto;
    width: 80%;
    position: relative;
    opacity: 0.6;
}

.cyber-line-small {
    height: 1px;
    background: linear-gradient(90deg, transparent, #D8FF60, transparent);
    margin: 10px 0;
    width: 100%;
    opacity: 0.4;
}

/* Section Headers */
.section-header {
    margin-bottom: 30px;
}

.section-header h2 {
    font-size: 2rem;
    color: #D8FF60;
    text-shadow: 0 0 10px rgba(216, 255, 96, 0.4);
    font-family: 'Orbitron', sans-serif;
}

/* FAQ Card */
.faq-section {
    margin-bottom: 60px;
}

.faq-card {
    background: rgba(30, 30, 30, 0.5);
    padding: 30px;
    border-radius: 15px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.4);
    position: relative;
    overflow: hidden;
}

.faq-card::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(216, 255, 96, 0.1), transparent);
    transition: 0.5s;
}

.faq-card:hover::before {
    left: 100%;
}

/* Contact Cards */
.contact-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 25px;
    margin-top: 30px;
}

.contact-card {
    background: rgba(30, 30, 30, 0.5);
    padding: 25px;
    border-radius: 12px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
}

.contact-icon {
    font-size: 1.5rem;
    color: #D8FF60;
    margin-bottom: 10px;
    filter: drop-shadow(0 0 5px rgba(216, 255, 96, 0.4));
}

.contact-card h3 {
    font-size: 1.2rem;
    color: #D8FF60;
    margin-bottom: 8px;
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: 600;
}

.contact-card p {
    font-size: 1rem;
    color: #ffffff;
    opacity: 0.9;
}

.contact-card:hover {
    transform: translateY(-3px);
    box-shadow: 0 8px 20px rgba(216, 255, 96, 0.1);
    border-color: rgba(216, 255, 96, 0.3);
    background: rgba(40, 40, 40, 0.5);
}

.card-glow {
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(216, 255, 96, 0.1), transparent);
    transition: 0.5s;
}

.contact-card:hover .card-glow {
    left: 100%;
}

/* Responsive Design */
@media screen and (max-width: 1200px) {
    .support-header h1 {
        font-size: 2.5rem;
    }
}

@media screen and (max-width: 992px) {
    .contact-options {
        grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    }
}

@media screen and (max-width: 768px) {
    .support-header h1 {
        font-size: 2rem;
    }

    .support-header p {
        font-size: 1rem;
    }

    .contact-options {
        grid-template-columns: 1fr;
    }

    .faq-card {
        padding: 20px;
    }
}

/* Update the content-wrapper class */
.content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 80px 20px;
    margin-left: 280px;
    width: calc(100% - 280px);
    min-width: 0;
    overflow-x: hidden;
}

.content-wrapper.full-screen {
    margin-left: 0;
    width: 100%;
}

@media screen and (max-width: 768px) {
    .content-wrapper {
        margin-left: 0;
        width: 100%;
        padding: 60px 15px 20px;
    }
    
    .content-wrapper.full-screen {
        padding: 60px 15px 20px;
    }
    
    /* Rest of your mobile styles... */
}

/* Add intermediate breakpoint */
@media screen and (max-width: 1200px) {
    .content-wrapper {
        padding: 80px 15px;
    }
    
    .support-main {
        max-width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .content-wrapper {
        padding: 80px 10px;
        margin-left: 280px;
        width: calc(100% - 280px);
    }
    
    .support-main {
        max-width: 100%;
    }
    
    .contact-options {
        gap: 15px;
    }
    
    .contact-card {
        width: calc(50% - 10px);
        min-width: auto;
    }
}

@media screen and (max-width: 768px) {
    .content-wrapper {
        margin-left: 0;
        width: 100%;
        padding: 60px 15px 20px;
    }
    
    .contact-options {
        flex-direction: column;
        align-items: center;
    }
    
    .contact-card {
        width: 100%;
        max-width: 300px;
    }
}

/* Update the layout classes */
.support-page {
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(135deg, #121212 0%, #1a1a1a 100%);
    color: #ffffff;
    display: flex;
}

.content-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 80px 20px;
    margin-left: 280px;
    width: calc(100% - 280px);
    min-width: 0;
}

.support-main {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

/* FAQ and Contact sections */
.faq-card, 
.contact-section {
    max-width: 100%;
    overflow-x: hidden;
}

/* Add better responsive breakpoints */
@media screen and (max-width: 1400px) {
    .support-main {
        max-width: 900px;
    }
}

@media screen and (max-width: 1200px) {
    .content-wrapper {
        padding: 80px 15px;
    }
    
    .support-main {
        max-width: 800px;
    }
}

@media screen and (max-width: 992px) {
    .content-wrapper {
        padding: 80px 10px;
        margin-left: 280px;
        width: calc(100% - 280px);
    }
    
    .support-main {
        max-width: 100%;
    }
    
    .contact-options {
        gap: 15px;
    }
    
    .contact-card {
        width: calc(50% - 10px);
        min-width: auto;
    }
}

@media screen and (max-width: 768px) {
    .content-wrapper {
        margin-left: 0;
        width: 100%;
        padding: 60px 15px 20px;
    }
    
    .contact-options {
        flex-direction: column;
        align-items: center;
    }
    
    .contact-card {
        width: 100%;
        max-width: 300px;
    }
}

/* Contact section improvements */
.contact-section {
    margin-top: 40px;
}

.contact-options {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    margin-top: 30px;
}

.contact-card {
    background: rgba(20, 20, 20, 0.7);
    padding: 25px;
    border-radius: 15px;
    border: 1px solid rgba(216, 255, 96, 0.1);
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    cursor: pointer;
}

/* Modern icon styling */
.contact-icon-wrapper {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(216, 255, 96, 0.1);
    border-radius: 50%;
    margin-bottom: 10px;
}

.contact-icon {
    width: 20px !important;
    height: 20px !important;
    color: #D8FF60;
    filter: drop-shadow(0 0 8px rgba(216, 255, 96, 0.4));
}

.contact-card h3 {
    font-size: 1.1rem;
    color: #D8FF60;
    font-weight: 600;
    margin: 0;
}

.contact-card p {
    font-size: 0.95rem;
    color: #ffffff;
    opacity: 0.9;
    margin: 0;
}

/* Hover effects */
.contact-card:hover {
    transform: translateY(-5px);
    background: rgba(30, 30, 30, 0.8);
    border-color: rgba(216, 255, 96, 0.3);
    box-shadow: 0 8px 25px rgba(216, 255, 96, 0.15);
}

.contact-card:hover .contact-icon {
    background: rgba(216, 255, 96, 0.15);
    transform: scale(1.05);
}

/* Responsive design */
@media screen and (max-width: 768px) {
    .contact-options {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .contact-card {
        padding: 25px;
    }

    .contact-icon {
        width: 50px;
        height: 50px;
        font-size: 20px;
    }

    .contact-card h3 {
        font-size: 1.1rem;
    }

    .contact-card p {
        font-size: 0.95rem;
    }
}

/* Contact section mobile improvements */
@media screen and (max-width: 768px) {
    .contact-options {
        grid-template-columns: 1fr;
        gap: 20px;
        padding: 0 15px;
    }

    .contact-card {
        width: 100%;
        max-width: 300px;
        margin: 0 auto;
    }

    .contact-section {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
    }

    .section-header {
        text-align: center;
        width: 100%;
        margin-bottom: 20px;
    }

    .section-header h2 {
        font-size: 1.8rem;
        margin-bottom: 10px;
    }
}

/* Extra small screens */
@media screen and (max-width: 480px) {
    .contact-card {
        max-width: 100%;
        padding: 20px 15px;
    }
}

