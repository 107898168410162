.area-code-detail {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  min-height: calc(100vh - 100px);
}

/* Title Section */
.received-call-banner {
  background: linear-gradient(180deg, rgba(216, 255, 96, 0.15), rgba(0, 0, 0, 0.3));
  border-radius: 15px;
  padding: 40px;
  text-align: center;
  margin-bottom: 30px;
  border: 1px solid rgba(216, 255, 96, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.received-call-banner h1 {
  color: #D8FF60;
  font-size: 2.6rem;
  margin-bottom: 15px;
  font-weight: 700;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  line-height: 1.2;
}

.received-call-banner .area-code {
  color: #ffffff;
  font-size: 3rem;
  font-weight: 800;
  display: block;
  margin: 15px 0;
  font-family: monospace;
  text-shadow: 0 2px 8px rgba(216, 255, 96, 0.3);
}

/* Action Buttons */
.action-buttons {
  display: flex;
  gap: 15px;
  justify-content: center;
  margin-top: 20px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}

.action-button.check-scams {
  background: rgba(255, 71, 87, 0.9);
  border: 1px solid rgba(255, 71, 87, 0.2);
}

.action-button.verify-callers {
  background: rgba(46, 213, 115, 0.9);
  border: 1px solid rgba(46, 213, 115, 0.2);
}

.action-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

/* Location Info & Risk Level */
.location-info {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.location-badge {
  display: inline-flex;
  align-items: center;
  gap: 12px;
  background: rgba(255, 255, 255, 0.1);
  padding: 12px 24px;
  border-radius: 30px;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

/* Risk Level Badge */
.risk-badge {
  display: flex;
  align-items: center;
  gap: 20px;
  background: rgba(20, 20, 20, 0.4);
  padding: 15px 20px;
  border-radius: 12px;
  margin-top: 15px;
  border: 1px solid rgba(255, 193, 7, 0.3);
}

.safety-label {
  display: flex;
  align-items: center;
  gap: 8px;
  background: rgba(255, 193, 7, 0.2);
  color: #ffc107;
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  border: 1px solid rgba(255, 193, 7, 0.3);
}

.risk-level {
  display: flex;
  align-items: center;
  gap: 10px;
}

.risk-level-label {
  color: #ffffff;
  font-size: 1rem;
  opacity: 0.9;
}

.risk-level-score {
  color: #D8FF60;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: monospace;
  background: rgba(216, 255, 96, 0.1);
  padding: 4px 10px;
  border-radius: 6px;
  border: 1px solid rgba(216, 255, 96, 0.2);
}

/* Risk Level Progress Bar */
.risk-meter {
  width: 100%;
  height: 6px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 3px;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
}

.risk-meter-fill {
  height: 100%;
  background: linear-gradient(to right, #2ed573, #ffa502, #ff4757);
  width: 68%; /* Matches the 6.8/10 score */
  border-radius: 3px;
  transition: width 1s ease;
}

/* Active Scam Alerts */
.active-alerts {
  background: rgba(255, 71, 87, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 71, 87, 0.2);
  box-shadow: 0 4px 20px rgba(255, 71, 87, 0.1);
}

.alert-details {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  margin-top: 20px;
  border: 1px solid rgba(255, 71, 87, 0.15);
}

/* Known Scam Numbers */
.known-scams {
  background: rgba(255, 71, 87, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 71, 87, 0.2);
}

.scam-prefixes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

.prefix-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  border: 1px solid rgba(255, 71, 87, 0.15);
  transition: transform 0.3s ease;
}

.prefix-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(255, 71, 87, 0.1);
}

.prefix-header {
  margin-bottom: 15px;
}

.prefix-number {
  color: #D8FF60;
  font-size: 1.3rem;
  font-weight: 600;
  font-family: monospace;
  display: block;
  margin-bottom: 8px;
}

.report-count {
  display: inline-block;
  background: rgba(255, 71, 87, 0.15);
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  color: #ff4757;
  border: 1px solid rgba(255, 71, 87, 0.2);
}

.prefix-details {
  color: #ffffff;
  font-size: 1rem;
  line-height: 1.5;
}

.prefix-details p {
  margin: 8px 0;
}

.prefix-details strong {
  color: #D8FF60;
  margin-right: 8px;
}

/* Legitimate Numbers */
.legitimate-numbers {
  background: rgba(46, 213, 115, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(46, 213, 115, 0.2);
}

.agency-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 20px;
  border: 1px solid rgba(46, 213, 115, 0.15);
  transition: transform 0.3s ease;
}

.agency-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(46, 213, 115, 0.1);
}

/* Section Headers */
section h2 {
  color: #D8FF60;
  font-size: 1.8rem;
  margin-bottom: 25px;
  display: flex;
  align-items: center;
  gap: 12px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Common styles for all sections */
section {
  margin-bottom: 40px;
  animation: fadeIn 0.5s ease;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(10px); }
  to { opacity: 1; transform: translateY(0); }
}

/* Add scrollbar styling */
::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(216, 255, 96, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(216, 255, 96, 0.4);
}

/* Active Scam Patterns Section */
.scam-patterns {
  background: linear-gradient(145deg, rgba(255, 71, 87, 0.05), rgba(0, 0, 0, 0.3));
  border-radius: 20px;
  padding: 35px;
  margin-bottom: 40px;
  border: 1px solid rgba(255, 71, 87, 0.15);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.scam-type {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 71, 87, 0.2);
  transition: transform 0.3s ease;
}

.scam-type:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(255, 71, 87, 0.1);
}

.scam-type h3 {
  color: #D8FF60;
  font-size: 1.4rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.scam-description {
  color: rgba(255, 255, 255, 0.9);
  font-size: 1.1rem;
  margin-bottom: 25px;
  line-height: 1.5;
  padding-left: 15px;
  border-left: 3px solid rgba(255, 71, 87, 0.3);
}

/* Call Patterns Styling */
.call-patterns {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 25px;
}

.call-patterns h4 {
  color: #D8FF60;
  font-size: 1.2rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.pattern-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-bottom: 20px;
}

.pattern-item {
  background: rgba(255, 255, 255, 0.05);
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.pattern-item strong {
  color: #D8FF60;
  margin-right: 8px;
}

/* Common Tactics Styling */
.common-tactics {
  background: rgba(255, 71, 87, 0.08);
  border-radius: 12px;
  padding: 20px;
}

.common-tactics h4 {
  color: #D8FF60;
  font-size: 1.2rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.tactics-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 12px;
}

.tactic-item {
  background: rgba(20, 20, 20, 0.4);
  padding: 12px 16px;
  border-radius: 8px;
  border: 1px solid rgba(255, 71, 87, 0.15);
  display: flex;
  align-items: center;
  gap: 10px;
}

.tactic-item:before {
  content: "⚠️";
  font-size: 1.1rem;
}

/* Report & Help Section */
.report-help {
  background: linear-gradient(145deg, rgba(46, 213, 115, 0.05), rgba(0, 0, 0, 0.3));
  border-radius: 20px;
  padding: 35px;
  margin-top: 40px;
  border: 1px solid rgba(46, 213, 115, 0.15);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.emergency-contacts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-bottom: 30px;
}

.contact-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 20px;
  border: 1px solid rgba(46, 213, 115, 0.2);
  transition: transform 0.3s ease;
}

.contact-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(46, 213, 115, 0.1);
}

.contact-card h4 {
  color: #D8FF60;
  font-size: 1.2rem;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.contact-number {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 12px;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  margin-bottom: 8px;
}

.contact-number strong {
  color: #2ed573;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .pattern-list,
  .tactics-list,
  .emergency-contacts {
    grid-template-columns: 1fr;
  }
  
  .scam-patterns,
  .report-help {
    padding: 25px;
  }

  .action-buttons {
    flex-direction: column;
  }

  .action-button {
    width: 100%;
    justify-content: center;
  }

  .received-call-banner h1 {
    font-size: 1.8rem;
  }
} 

/* Safety and Risk Level Badge */
.safety-rating {
  display: flex;
  align-items: center;
  gap: 15px;
  background: rgba(20, 20, 20, 0.4);
  padding: 15px;
  border-radius: 12px;
  margin-top: 20px;
  border: 1px solid rgba(255, 193, 7, 0.2);
}

.safety-badge {
  background: rgba(255, 193, 7, 0.2);
  padding: 6px 12px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  border: 1px solid rgba(255, 193, 7, 0.3);
}

.safety-badge span {
  color: #ffc107;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  font-size: 0.9rem;
}

.risk-level {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  font-size: 1rem;
}

/* Legitimate Numbers Section */
.legitimate-callers {
  background: rgba(46, 213, 115, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin: 30px 0;
  border: 1px solid rgba(46, 213, 115, 0.2);
}

.legitimate-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

.legitimate-section {
  background: rgba(20, 20, 20, 0.4);
  border-radius: 12px;
  padding: 25px;
  border: 1px solid rgba(46, 213, 115, 0.15);
}

.legitimate-section h3 {
  color: #D8FF60;
  font-size: 1.3rem;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.legitimate-card {
  background: rgba(46, 213, 115, 0.08);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid rgba(46, 213, 115, 0.2);
  transition: transform 0.3s ease;
}

.legitimate-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 15px rgba(46, 213, 115, 0.1);
}

.legitimate-card h4 {
  color: #ffffff;
  font-size: 1.1rem;
  margin-bottom: 8px;
}

.legitimate-card p {
  color: #D8FF60;
  font-size: 1.2rem;
  margin: 10px 0;
  font-family: monospace;
}

.legitimate-card a {
  display: inline-block;
  background: rgba(46, 213, 115, 0.2);
  color: #2ed573;
  padding: 8px 16px;
  border-radius: 6px;
  text-decoration: none;
  margin-top: 10px;
  transition: all 0.3s ease;
}

.legitimate-card a:hover {
  background: rgba(46, 213, 115, 0.3);
  transform: translateY(-2px);
}

/* Report & Help Section */
.resources {
  background: linear-gradient(145deg, rgba(46, 213, 115, 0.05), rgba(0, 0, 0, 0.3));
  border-radius: 20px;
  padding: 35px;
  margin-top: 40px;
  border: 1px solid rgba(46, 213, 115, 0.15);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 25px;
  margin-top: 25px;
}

.resource-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  border: 1px solid rgba(46, 213, 115, 0.2);
}

.resource-card h3 {
  color: #D8FF60;
  font-size: 1.3rem;
  margin-bottom: 20px;
}

.resource-card ul {
  list-style: none;
  padding: 0;
}

.resource-card li {
  color: #ffffff;
  padding: 12px;
  margin-bottom: 10px;
  background: rgba(46, 213, 115, 0.08);
  border-radius: 8px;
  border: 1px solid rgba(46, 213, 115, 0.15);
  display: flex;
  justify-content: space-between;
  align-items: center;
} 

/* Area Code Header */
.area-code-header {
  margin-bottom: 30px;
}

.area-code-title {
  color: #ffffff;
  font-size: 2rem;
  margin-bottom: 15px;
}

/* Location Info Box */
.location-box {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 15px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.location-info {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #ffffff;
  font-size: 1.1rem;
}

.location-icon {
  color: #D8FF60;
}

/* Safety Badge */
.safety-container {
  background: #ffa502;
  border-radius: 8px;
  padding: 2px;
  margin-bottom: 5px;
}

.safety-badge {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  background: #ffa502;
  color: #000000;
  padding: 8px 16px;
  border-radius: 6px;
  font-weight: 600;
  font-size: 0.9rem;
  text-transform: uppercase;
}

.safety-badge.moderate {
  background: #ffa502;
}

/* Risk Level */
.risk-level {
  color: #ffffff;
  padding: 8px 16px;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 5px;
}

.risk-score {
  color: #ffffff;
  font-weight: 600;
}

/* Progress Bar */
.risk-meter {
  width: 100%;
  height: 4px;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 2px;
  overflow: hidden;
}

.risk-meter-fill {
  height: 100%;
  width: 68%;
  background: #ffa502;
  border-radius: 2px;
  transition: width 0.3s ease;
} 

.error-message {
  text-align: center;
  padding: 40px 20px;
  background: rgba(255, 71, 87, 0.08);
  border-radius: 15px;
  margin: 40px auto;
  max-width: 600px;
  border: 1px solid rgba(255, 71, 87, 0.2);
}

.error-message h1 {
  color: #D8FF60;
  margin-bottom: 20px;
}

.error-message p {
  color: #ffffff;
  margin-bottom: 30px;
}

.back-button {
  display: inline-block;
  padding: 12px 24px;
  background: rgba(216, 255, 96, 0.1);
  color: #D8FF60;
  text-decoration: none;
  border-radius: 8px;
  border: 1px solid rgba(216, 255, 96, 0.2);
  transition: all 0.3s ease;
}

.back-button:hover {
  background: rgba(216, 255, 96, 0.2);
  transform: translateY(-2px);
} 

.hero-section {
  text-align: center;
  padding: 50px 30px;
  background: #1a1a1a;
  border-radius: 15px;
  margin-bottom: 30px;
  border: 1px solid rgba(216, 255, 96, 0.2);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
}

.hero-section h1 {
  font-size: 2.8rem;
  margin-bottom: 20px;
  color: #D8FF60;
  font-weight: 700;
}

.hero-description {
  color: #fff;
  font-size: 1.2rem;
  max-width: 800px;
  margin: 20px auto;
  line-height: 1.6;
}

.location-badge, .safety-badge {
  display: inline-block;
  padding: 8px 16px;
  border-radius: 20px;
  margin: 0 10px;
  font-weight: 500;
  background: rgba(20, 20, 20, 0.6);
  border: 1px solid rgba(255, 71, 87, 0.15);
}

.quick-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-bottom: 40px;
}

.stat-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  border: 1px solid rgba(255, 71, 87, 0.15);
  text-align: center;
  transition: transform 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
}

/* Active Scam Alerts - preserved and enhanced */
.active-alerts {
  background: rgba(255, 71, 87, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 71, 87, 0.2);
  box-shadow: 0 4px 20px rgba(255, 71, 87, 0.1);
}

.alert-details {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  margin-top: 20px;
  border: 1px solid rgba(255, 71, 87, 0.15);
}

.alert-card {
  background: rgba(255, 71, 87, 0.08);
  border: 1px solid rgba(255, 71, 87, 0.2);
  border-radius: 12px;
  padding: 20px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.alert-card:hover {
  transform: translateY(-2px);
}

.alert-header {
  margin-bottom: 15px;
}

.alert-date {
  display: inline-block;
  background: rgba(255, 71, 87, 0.15);
  color: #ff4757;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.alert-card h3 {
  color: #ff4757;
  font-size: 1.2rem;
  margin-bottom: 10px;
}

.alert-description {
  color: #fff;
  margin-bottom: 15px;
  line-height: 1.6;
}

.alert-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 15px;
  margin-top: 15px;
  padding-top: 15px;
  border-top: 1px solid rgba(255, 71, 87, 0.2);
}

.alert-target,
.alert-location,
.alert-losses {
  display: flex;
  align-items: center;
  gap: 8px;
  color: rgba(255, 255, 255, 0.8);
  font-size: 0.95rem;
}

.alert-target svg,
.alert-location svg,
.alert-losses svg {
  color: #ff4757;
}

.no-alerts {
  color: rgba(255, 255, 255, 0.6);
  text-align: center;
  padding: 30px;
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  border: 1px solid rgba(255, 71, 87, 0.15);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .alert-details {
    grid-template-columns: 1fr;
    gap: 10px;
  }
  
  .alert-card {
    padding: 15px;
  }
}

/* Known Scam Numbers - preserved */
.known-scams {
  background: rgba(255, 71, 87, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 71, 87, 0.2);
}

.scam-prefixes-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 25px;
  margin-top: 20px;
}

.prefix-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  border: 1px solid rgba(255, 71, 87, 0.15);
  transition: transform 0.3s ease;
}

/* New sections */
.content-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-bottom: 40px;
}

.grid-item {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  border: 1px solid rgba(255, 71, 87, 0.15);
}

.safety-resources {
  background: rgba(255, 71, 87, 0.08);
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(255, 71, 87, 0.2);
  box-shadow: 0 4px 20px rgba(255, 71, 87, 0.1);
}

.resources-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.resource-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  border: 1px solid rgba(255, 71, 87, 0.15);
  transition: transform 0.3s ease;
}

.resource-card:hover {
  transform: translateY(-3px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }
  
  .location-badge, .safety-badge {
    display: block;
    margin: 10px auto;
    max-width: 200px;
  }
  
  .content-grid {
    grid-template-columns: 1fr;
  }
  
  .stat-card, .alert-card, .resource-card {
    transform: none !important;
  }
} 

/* Area Code Search styles */
.area-code-search {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 30px;
  border: 1px solid rgba(216, 255, 96, 0.15);
}

.area-code-search h2 {
  color: #D8FF60;
  margin-bottom: 20px;
}

.search-container {
  display: flex;
  gap: 15px;
  align-items: center;
}

.search-input {
  flex: 1;
  padding: 12px 20px;
  border-radius: 8px;
  border: 1px solid rgba(216, 255, 96, 0.2);
  background: rgba(0, 0, 0, 0.3);
  color: #fff;
  font-size: 1rem;
}

.browse-all-btn {
  padding: 12px 24px;
  background: rgba(216, 255, 96, 0.1);
  color: #D8FF60;
  border: 1px solid rgba(216, 255, 96, 0.2);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  transition: all 0.3s ease;
}

.browse-all-btn:hover {
  background: rgba(216, 255, 96, 0.2);
  transform: translateY(-2px);
}

/* Legitimate Callers section styles */
.legitimate-callers-section {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(216, 255, 96, 0.15);
}

.legitimate-callers-section h2 {
  color: #D8FF60;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 25px;
}

.callers-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.caller-category {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
  border: 1px solid rgba(216, 255, 96, 0.1);
}

.caller-category h3 {
  color: #D8FF60;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 15px;
}

.caller-card {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
  border: 1px solid rgba(216, 255, 96, 0.1);
}

.caller-card h4 {
  color: #fff;
  margin-bottom: 8px;
}

.caller-card p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.9rem;
  margin-bottom: 12px;
}

.verify-link {
  display: inline-flex;
  align-items: center;
  gap: 5px;
  color: #D8FF60;
  text-decoration: none;
  font-size: 0.9rem;
  margin-top: 8px;
}

.prefix-badge {
  display: inline-block;
  padding: 4px 8px;
  background: rgba(216, 255, 96, 0.1);
  color: #D8FF60;
  border-radius: 4px;
  font-size: 0.8rem;
  margin-top: 8px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2rem;
  }
  
  .search-container {
    flex-direction: column;
  }
  
  .browse-all-btn {
    width: 100%;
    justify-content: center;
  }
  
  .callers-grid {
    grid-template-columns: 1fr;
  }
} 

/* Safety Rating Badge Styles */
.safety-badge {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 600;
  margin: 10px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.safety-badge[data-rating="low"] {
  background: rgba(46, 213, 115, 0.15);
  color: #2ed573;
  border-color: rgba(46, 213, 115, 0.3);
}

.safety-badge[data-rating="moderate"] {
  background: rgba(255, 165, 2, 0.15);
  color: #ffa502;
  border-color: rgba(255, 165, 2, 0.3);
}

.safety-badge[data-rating="high"] {
  background: rgba(255, 71, 87, 0.15);
  color: #ff4757;
  border-color: rgba(255, 71, 87, 0.3);
}

.safety-badge[data-rating="unknown"] {
  background: rgba(149, 165, 166, 0.15);
  color: #95a5a6;
  border-color: rgba(149, 165, 166, 0.3);
}

/* Icon styling within safety badge */
.safety-badge svg {
  font-size: 1.2em;
} 

/* Regional Info Styles */
.regional-info {
  background: rgba(20, 20, 20, 0.6);
  border-radius: 12px;
  padding: 25px;
  margin-bottom: 30px;
}

.info-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.info-card {
  background: rgba(30, 30, 30, 0.6);
  border-radius: 8px;
  padding: 20px;
  border: 1px solid rgba(216, 255, 96, 0.15);
}

.info-card h3 {
  color: #D8FF60;
  margin-bottom: 15px;
  font-size: 1.1rem;
}

.info-card ul {
  list-style: none;
  padding: 0;
}

.info-card li {
  color: #fff;
  margin-bottom: 8px;
  padding-left: 20px;
  position: relative;
}

.info-card li:before {
  content: "•";
  color: #D8FF60;
  position: absolute;
  left: 0;
}

.info-card li strong {
  color: #D8FF60;
  margin-right: 8px;
}

/* Update Quick Stats for 4 cards */
.quick-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  margin: 30px 0;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .info-grid {
    grid-template-columns: 1fr;
  }
  
  .quick-stats {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .quick-stats {
    grid-template-columns: 1fr;
  }
} 

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #121212;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.loading-pulse {
  width: 60px;
  height: 60px;
  border: 3px solid rgba(216, 255, 96, 0.2);
  border-radius: 50%;
  border-top-color: #D8FF60;
  animation: spin 1s ease-in-out infinite;
  margin-bottom: 20px;
}

.loading-text {
  color: #D8FF60;
  font-size: 1.2rem;
  opacity: 0;
  animation: fadeInOut 2s ease-in-out infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0.3; }
  50% { opacity: 1; }
} 