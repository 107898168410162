@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;700&display=swap');

:root {
    --primary-color: #D8FF60;
    --secondary-color: #121212;
    --hover-color: #B8FF50;
    --text-color: #FFFFFF;
    --dropdown-bg: rgba(30, 30, 30, 0.95);
    --menu-bg: rgba(18, 18, 18, 0.95);
    --neon-shadow: 0 0 10px rgba(216, 255, 96, 0.8);
    --box-shadow: 0 4px 12px rgba(0, 0, 0, 0.5);
    --transition: 0.3s ease;
    --navbar-height: 60px;
    --mobile-navbar-height: 60px;
    --sidebar-width: 280px;
    --mobile-breakpoint: 768px;
}

body {
    font-family: 'Orbitron', sans-serif;
}

.navbar {
    background: linear-gradient(180deg, rgba(30,30,30,0.95) 0%, rgba(18,18,18,0.98) 100%);
    padding: 0 2rem;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1200;
    height: var(--navbar-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(216, 255, 96, 0.1);
}

.logo-container {
    text-decoration: none;
    padding: 8px;
}

.logo-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
}

.eraser-icon {
    font-size: 24px;
    color: var(--primary-color);
    filter: drop-shadow(var(--neon-shadow));
}

.text-wrapper {
    display: flex;
    align-items: center;
}

.logo-text {
    color: var(--text-color);
    font-size: 24px;
    font-weight: 700;
}

.logo-subtitle {
    color: var(--primary-color);
    font-size: 18px;
    font-weight: 500;
}

.eraser-icon-container {
    line-height: 0; /* Remove any extra space around icon */
    display: flex;
    align-items: center;
}

.eraser-icon {
    font-size: 2rem;
    color: var(--primary-color);
    filter: drop-shadow(var(--neon-shadow));
    transition: transform var(--transition), filter var(--transition);
    vertical-align: middle; /* Align with text */
}

.eraser-icon:hover {
    transform: rotate(20deg) scale(1.2);
    filter: drop-shadow(0 0 15px rgba(216, 255, 96, 1));
}

.menu-toggle {
    display: none;
    background: none;
    border: none;
    cursor: pointer;
}

.menu-icon {
    font-size: 1.5rem;
    color: var(--text-color);
    transition: color var(--transition);
}

.menu-toggle:hover .menu-icon {
    color: var(--primary-color);
}

.nav-links {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.dropdown-container {
    position: relative;
    display: inline-block;
}

.dropdown-toggle {
    background: transparent;
    color: var(--text-color);
    border: 1px solid rgba(216, 255, 96, 0.3);
    padding: 10px 16px;
    gap: 12px;
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: 0;
    border-radius: 8px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    display: flex;
    align-items: center;
    min-width: 160px;
}

.dropdown-toggle:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.2);
}

.dropdown {
    position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);
    width: 250px;
    background: rgba(28, 28, 30, 0.98);
    backdrop-filter: blur(12px);
    border: 1px solid rgba(216, 255, 96, 0.08);
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
    padding: 8px;
    z-index: 1000;
    display: flex;
    flex-direction: column;
}

.dropdown li {
    list-style: none;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.dropdown li button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 16px;
    background: transparent;
    border: none;
    color: var(--text-color);
    font-size: 0.95rem;
    font-weight: 500;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.2s ease;
    white-space: nowrap;
}

.dropdown li button svg {
    width: 20px;
    height: 20px;
    color: var(--primary-color);
    flex-shrink: 0;
}

.dropdown li button span {
    text-align: left;
    flex-grow: 1;
}

.dropdown li button:hover {
    background: rgba(255, 255, 255, 0.03);
}

.dropdown li:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.06);
    margin-bottom: 4px;
    padding-bottom: 4px;
}

.dropdown li button::after {
    display: none;
}

.dropdown.show {
    display: block;
    opacity: 1;
    transform: translateY(0);
}

@keyframes dropdownShow {
    from {
        opacity: 0;
        transform: translateY(-8px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.login-btn,
.logout-btn {
    background: rgba(18, 18, 18, 0.8);
    padding: 10px 20px;
    gap: 10px;
    font-size: 0.9rem;
    font-weight: 500;
    letter-spacing: 0;
    border-radius: 8px;
    height: 42px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    cursor: pointer;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    min-width: 150px; /* Increased min-width for better fit */
    height: 45px; /* Fixed height for consistency */
}

.login-btn {
    color: #D8FF60;
    border: 1px solid #D8FF60;
    box-shadow: 0 0 10px rgba(216, 255, 96, 0.2),
                inset 0 0 10px rgba(216, 255, 96, 0.2);
}

.login-btn:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0;
    height: 0;
    background: rgba(216, 255, 96, 0.1);
    border-radius: 50%;
    transition: width 0.6s ease, height 0.6s ease;
    z-index: -1;
}

.login-btn:hover {
    color: #D8FF60;
    border-color: #D8FF60;
    box-shadow: 0 0 20px rgba(216, 255, 96, 0.4),
                inset 0 0 15px rgba(216, 255, 96, 0.4);
    transform: translateY(-2px);
}

.login-btn:hover:before {
    width: 300px;
    height: 300px;
}

.login-btn:after {
    /* Optional: Add any additional styling if needed */
}

.login-btn svg {
    font-size: 1rem;
    transition: transform 0.3s ease;
}

.login-btn:hover svg {
    transform: translateX(3px);
}

.logout-btn {
    background: rgba(18, 18, 18, 0.8);
    color: var(--primary-color);
    border: 1px solid rgba(216, 255, 96, 0.3);
    padding: 0 1.5rem;
    border-radius: 4px;
    font-family: 'Orbitron', sans-serif;
    font-size: 0.9rem;
    letter-spacing: 1px;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 60px;
    position: relative;
}

.logout-btn span {
    display: block;
    margin-bottom: 4px;
}

.logout-btn svg {
    font-size: 1.1rem;
    color: var(--primary-color);
}

@keyframes borderGlow {
    0%, 100% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(180deg);
    }
}

@keyframes glitch {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 10px rgba(216, 255, 96, 0.2),
                    inset 0 0 10px rgba(216, 255, 96, 0.2);
    }
    50% {
        box-shadow: 0 0 20px rgba(216, 255, 96, 0.4),
                    inset 0 0 15px rgba(216, 255, 96, 0.4);
    }
    100% {
        box-shadow: 0 0 10px rgba(216, 255, 96, 0.2),
                    inset 0 0 10px rgba(216, 255, 96, 0.2);
    }
}

.login-btn:focus,
.logout-btn:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(216, 255, 96, 0.5);
}

.login-btn:active,
.logout-btn:active {
    transform: translateY(1px);
    transition: transform 0.1s ease;
}

@media (max-width: 768px) {
    :root {
        --navbar-height: 60px; /* Slightly smaller for mobile */
    }

    .navbar {
        width: 100%;
        padding: 0.5rem 1rem;
    }

    .nav-links {
        display: none;
    }

    .nav-links.open {
        display: flex;
        position: absolute;
        top: var(--navbar-height);
        left: 0;
        width: 100%;
        background: var(--menu-bg);
        flex-direction: column;
        padding: 1.5rem;
        box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.6);
        border-radius: 0 0 1rem 1rem;
    }

    .menu-toggle {
        display: block;
    }

    .dropdown {
        top: calc(var(--navbar-height) - 0.5rem);
    }

    .dropdown a {
        padding: 1rem;
        font-size: 0.9rem;
    }

    .login-btn,
    .logout-btn {
        width: 100%;
        margin: 0.5rem 0;
        text-align: center;
    }

    .logout-btn:before {
        top: -4px;
        left: -4px;
        right: -4px;
        bottom: -4px;
    }
}

.dashboard-content {
    margin-left: 250px; /* Match sidebar width */
    width: calc(100% - 250px);
    padding-top: calc(var(--navbar-height) + 40px); /* Increased padding-top */
    background-color: var(--secondary-color);
    min-height: 100vh;
    box-sizing: border-box;
}

.sidebar {
    top: var(--navbar-height);
    height: calc(100vh - var(--navbar-height));
}

.dropdown-toggle svg {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

.dropdown-toggle:hover svg {
    transform: translateY(2px);
}

.nav-links a {
    position: relative;
    padding: 8px 16px;
    font-size: 0.95rem;
    font-weight: 500;
    letter-spacing: 0;
    transition: all 0.25s ease;
}

.nav-links a::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 50%;
    width: 0;
    height: 2px;
    background: #D8FF60;
    transition: all 0.25s ease;
    transform: translateX(-50%);
    opacity: 0;
}

.nav-links a:hover::after {
    width: calc(100% - 32px);
    opacity: 1;
}

.dropdown {
    width: 300px;
    padding: 10px;
}

.dropdown li button span {
    flex: 1;
    text-align: left;
    line-height: 1.2;
}

@media screen and (max-width: 1200px) {
    .nav-links {
        gap: 0.4rem; /* Reduce gap between nav items further */
    }
    
    .dropdown-toggle {
        min-width: 120px; /* Make dropdown button even smaller */
        padding: 8px 12px; /* Reduce padding */
    }

    /* Make other nav items more compact */
    .nav-links a {
        padding: 8px 12px;
        font-size: 0.9rem;
    }

    .login-btn,
    .logout-btn {
        padding: 8px 16px;
    }
}

@media screen and (max-width: calc(var(--mobile-breakpoint))) {
    .navbar {
        left: 0;
        width: 100%;
        display: none; /* Hide desktop navbar on mobile */
    }
    
    .dashboard-content {
        margin-left: 0;
        width: 100%;
        padding-top: var(--mobile-navbar-height);
    }
}

@media screen and (min-width: calc(var(--mobile-breakpoint) + 1px)) {
    .navbar {
        padding: 0 2rem;
    }
    
    .dashboard-content {
        margin-left: var(--sidebar-width);
        width: calc(100% - var(--sidebar-width));
        padding-top: var(--navbar-height);
    }
}

.navbar-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;
}

.nav-link {
    color: var(--text-color);
    text-decoration: none;
    cursor: pointer;
    padding: 10px 16px;
    border-radius: 8px;
    transition: all 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    font-weight: 500;
    font-size: 0.95rem;
    border: 1px solid rgba(216, 255, 96, 0.3);
    background: transparent;
}

.nav-link:hover {
    color: var(--primary-color);
    border-color: var(--primary-color);
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.2);
}

.free-badge-container {
    display: inline-flex;
    align-items: center;
    gap: 4px;
    margin-left: 8px;
    background: rgba(216, 255, 96, 0.1);
    padding: 2px 6px;
    border-radius: 4px;
}

.free-badge {
    color: #D8FF60;
    font-size: 0.8em;
    animation: pulse 2s infinite;
}

.free-text {
    color: #D8FF60;
    font-size: 0.7em;
    font-weight: bold;
    letter-spacing: 0.5px;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.6; }
    100% { opacity: 1; }
}
