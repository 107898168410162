/* DataLeakPage.css */

/* General Page Styles */
.data-leak-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background: #0a0c1b;
    background-image: 
        linear-gradient(rgba(0, 247, 255, 0.03) 1px, transparent 1px),
        linear-gradient(90deg, rgba(0, 247, 255, 0.03) 1px, transparent 1px);
    background-size: 50px 50px;
    padding-top: 80px;
    position: relative;
    color: #ffffff;
    font-family: 'Plus Jakarta Sans', sans-serif;
    box-sizing: border-box;
}

/* Content Wrapper */
.content-wrapper {
    flex: 1; /* Allows content to grow and footer to stick to bottom */
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
}

/* Card Styles */
.card {
    background: rgba(30, 30, 30, 0.9);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 12px;
    padding: 30px;
    margin-bottom: 30px;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.3);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 25px rgba(0, 0, 0, 0.4);
}

/* Header Section */
.data-leak-header {
    text-align: center;
    margin-bottom: 40px;
    padding: 0 20px;
}

.data-leak-header h1 {
    font-size: clamp(1.8rem, 4vw, 2.5rem);
    margin-bottom: 15px;
    color: #D8FF60;
    text-shadow: 0 0 10px rgba(216, 255, 96, 0.3);
}

.data-leak-header p {
    font-size: clamp(0.9rem, 2vw, 1.2rem);
    max-width: 600px;
    margin: 0 auto;
    color: #a0a0b0;
    line-height: 1.6;
}

/* Eraser Icon */
.eraser-icon-container {
    text-align: center;
    margin-bottom: 30px;
}

.eraser-icon {
    color: #D8FF60;
    transition: transform 0.3s ease;
}

.eraser-icon:hover {
    transform: scale(1.1);
}

/* Email Form */
.data-leak-form {
    max-width: 600px; /* Increased width for better desktop layout */
    margin: 0 auto;
}

.input-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.email-input {
    flex: 1;
    padding: 15px;
    border: 2px solid rgba(216, 255, 96, 0.3);
    border-radius: 10px;
    background-color: rgba(42, 42, 42, 0.8);
    color: #ffffff;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.email-input:focus {
    border-color: #D8FF60;
    box-shadow: 0 0 10px rgba(216, 255, 96, 0.5);
    outline: none;
}

.check-button {
    padding: 15px 25px;
    border: none;
    border-radius: 10px;
    background-color: #D8FF60;
    color: #1e1e1e;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.check-button:hover {
    background-color: #c6e650;
}

.error-message {
    color: #ff4d4f;
    text-align: center;
    margin-top: 10px;
}

/* Loading Stages */
.loading-stages {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin: 25px 0;
    padding: 20px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
}

.stage {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.03);
    transition: all 0.3s ease;
}

.stage.active {
    background: rgba(216, 255, 96, 0.05);
    transform: translateX(10px);
}

.stage-indicator {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.2);
    margin-right: 15px;
    position: relative;
    transition: all 0.3s ease;
}

.stage.active .stage-indicator {
    background-color: #D8FF60;
    box-shadow: 0 0 15px rgba(216, 255, 96, 0.5);
}

.stage.active .stage-indicator::after {
    content: '';
    position: absolute;
    top: -4px;
    left: -4px;
    right: -4px;
    bottom: -4px;
    border-radius: 50%;
    border: 2px solid #D8FF60;
    animation: pulse 1.5s infinite;
}

.stage-text {
    font-size: 0.95rem;
    color: rgba(255, 255, 255, 0.7);
    transition: all 0.3s ease;
}

.stage.active .stage-text {
    color: #D8FF60;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 0.8;
    }
    50% {
        transform: scale(1.5);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}

/* Breach Results */
.breach-results {
    margin-top: 20px;
    padding: 20px;
    border-radius: 12px;
    background: rgba(50, 50, 50, 0.8);
    transition: background 0.3s ease;
}

.breach-results:hover {
    background: rgba(70, 70, 70, 0.9);
}

.breach-results h2 {
    color: #D8FF60;
    margin-bottom: 15px;
}

.breach-results ul {
    list-style-type: disc;
    padding-left: 20px;
    max-height: 200px;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.breach-results ul.show-more {
    max-height: none;
}

.breach-results li {
    margin-bottom: 5px;
    color: #ccc;
    transition: color 0.3s ease;
}

.breach-results li:hover {
    color: #D8FF60; /* Change color on hover */
}

/* Breach Statistics Enhancement */
.breach-stats {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
    padding: 40px;
    background: rgba(30, 30, 30, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    margin: 40px 0;
}

.breach-stat {
    text-align: center;
    padding: 30px 20px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 12px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    position: relative;
    overflow: hidden;
}

.breach-stat:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.breach-stat::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #D8FF60, transparent);
    opacity: 0.5;
}

.breach-stat h2 {
    font-size: clamp(1.8rem, 3vw, 2.5rem);
    color: #D8FF60;
    margin-bottom: 10px;
    font-weight: 700;
    text-shadow: 0 0 20px rgba(216, 255, 96, 0.3);
}

.breach-stat p {
    color: #ffffff;
    font-size: clamp(0.9rem, 1.5vw, 1.1rem);
    opacity: 0.8;
    text-transform: uppercase;
    letter-spacing: 1px;
}

/* Section Divider */
.section-divider {
    position: relative;
    text-align: center;
    margin: 40px 0;
}

.section-divider::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
}

.section-divider span {
    position: relative;
    background: #1e1e1e;
    padding: 0 20px;
    color: #D8FF60;
    font-size: 0.9rem;
    text-transform: uppercase;
    letter-spacing: 2px;
}

/* Fade In Animation */
.fade-in {
    animation: fadeIn 0.5s ease-out;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Responsive Design Updates */
@media (max-width: 968px) {
    .breach-stats {
        grid-template-columns: repeat(3, 1fr);
        padding: 20px;
        gap: 15px;
    }

    .breach-stat {
        padding: 20px 15px;
    }
}

@media (max-width: 768px) {
    .breach-stats {
        grid-template-columns: 1fr;
        gap: 20px;
    }

    .breach-stat {
        padding: 25px 20px;
    }

    .breach-stat h2 {
        font-size: 1.8rem;
    }
}

/* Privacy Statement */
.privacy-statement {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
    font-size: 0.9rem;
    background-color: rgba(30, 30, 30, 0.9);
    border-radius: 12px;
}

/* Featured In Section */
.featured-in-section {
    text-align: center;
    padding: 20px;
    margin-bottom: 30px;
    background-color: rgba(30, 30, 30, 0.9);
    border-radius: 12px;
}

/* Data Brokers Check Section */
.data-brokers-check {
    padding: 30px 15px;
}

.name-inputs {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
}

.name-input {
    flex: 1;
    padding: 15px;
    border: 2px solid rgba(216, 255, 96, 0.3);
    border-radius: 10px;
    background-color: rgba(42, 42, 42, 0.8);
    color: #ffffff;
    font-size: 1rem;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.name-input:focus {
    border-color: #D8FF60;
    box-shadow: 0 0 10px rgba(216, 255, 96, 0.5);
    outline: none;
}

.check-info-button {
    padding: 15px 25px;
    border: none;
    border-radius: 10px;
    background-color: #D8FF60;
    color: #1e1e1e;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-top: 10px;
}

.check-info-button:hover {
    background-color: #c6e650;
}

/* Broker Info Result */
.broker-info-result {
    margin-top: 20px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .data-leak-page {
        padding: 60px 15px 40px;
    }

    .data-leak-header h1 {
        font-size: 2rem;
    }

    .data-leak-header p {
        font-size: 1rem;
    }

    .breach-stats {
        grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
        padding: 0 15px;
    }

    .breach-results ul {
        padding-left: 15px;
    }

    .card {
        padding: 20px;
        margin-bottom: 20px;
    }

    .check-button, .check-info-button {
        font-size: 0.9rem;
    }

    .breach-stat h2 {
        font-size: clamp(1.3rem, 2.5vw, 1.8rem);
    }

    /* Data Brokers Check Section Adjustments */
    .data-brokers-check {
        padding: 30px 15px;
    }

    .name-inputs {
        flex-direction: column;
    }
}

@media (max-width: 480px) {
    .data-leak-page {
        padding-top: 50px;
    }

    .card {
        padding: 15px;
        margin-bottom: 20px;
    }

    .data-leak-header h1 {
        font-size: 1.8rem;
    }

    .email-input,
    .name-input,
    .check-button,
    .check-info-button {
        font-size: 0.9rem;
        padding: 12px;
    }

    .breach-stat h2 {
        font-size: 1.2rem;
    }
}

/* Navbar Styles */
.navbar, .mobile-navbar {
    width: 100%;
    padding: 20px;
    background-color: #1e1e1e;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar ul, .mobile-navbar ul {
    list-style: none;
    display: flex;
    gap: 20px;
    margin: 0;
    padding: 0;
}

.navbar ul li, .mobile-navbar ul li {
    color: #ffffff;
    cursor: pointer;
    transition: color 0.3s ease;
}

.navbar ul li:hover, .mobile-navbar ul li:hover {
    color: #D8FF60;
}

@media (max-width: 768px) {
    .navbar {
        display: none;
    }

    .mobile-navbar {
        display: flex;
    }
}

@media (min-width: 769px) {
    .mobile-navbar {
        display: none;
    }

    .navbar {
        display: flex;
    }
}

/* Enhanced Breach Results Styling */
.breach-results {
    background: rgba(30, 30, 30, 0.95);
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.breach-list {
    display: grid;
    gap: 20px;
    margin-top: 20px;
}

.breach-item {
    background: rgba(40, 40, 40, 0.9);
    border-radius: 8px;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.breach-item:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.breach-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.breach-header h3 {
    color: #D8FF60;
    font-size: 1.2rem;
    margin: 0;
}

.threat-level {
    padding: 4px 12px;
    border-radius: 20px;
    font-size: 0.8rem;
    font-weight: 600;
    text-transform: uppercase;
}

.threat-level.critical {
    background: rgba(255, 59, 48, 0.2);
    color: #ff3b30;
    border: 1px solid rgba(255, 59, 48, 0.3);
}

.threat-level.high {
    background: rgba(255, 149, 0, 0.2);
    color: #ff9500;
    border: 1px solid rgba(255, 149, 0, 0.3);
}

.threat-level.medium {
    background: rgba(255, 204, 0, 0.2);
    color: #ffcc00;
    border: 1px solid rgba(255, 204, 0, 0.3);
}

.threat-level.low {
    background: rgba(52, 199, 89, 0.2);
    color: #34c759;
    border: 1px solid rgba(52, 199, 89, 0.3);
}

.breach-details {
    color: #cccccc;
}

.breach-date {
    font-size: 0.9rem;
    color: #888;
    margin: 5px 0;
}

.pwn-count {
    font-size: 0.9rem;
    color: #ff9500;
    margin: 5px 0;
}

.compromised-data {
    margin-top: 15px;
}

.compromised-data h4 {
    color: #ffffff;
    margin-bottom: 8px;
    font-size: 0.95rem;
}

.compromised-data ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.compromised-data li {
    background: rgba(216, 255, 96, 0.1);
    color: #D8FF60;
    padding: 4px 12px;
    border-radius: 15px;
    font-size: 0.8rem;
    border: 1px solid rgba(216, 255, 96, 0.2);
}

/* Enhanced Show More Button */
.show-more-button {
    background: rgba(216, 255, 96, 0.1);
    color: #D8FF60;
    border: 1px solid rgba(216, 255, 96, 0.3);
    padding: 12px 25px;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    margin: 20px auto;
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    width: auto;
}

.show-more-button:hover {
    background: rgba(216, 255, 96, 0.2);
    transform: translateY(-2px);
}

.button-icon {
    font-size: 1.2rem;
    transition: transform 0.3s ease;
}

/* Responsive Design */
@media (max-width: 768px) {
    .breach-list {
        gap: 15px;
    }

    .breach-item {
        padding: 15px;
    }

    .breach-header {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
    }

    .breach-header h3 {
        font-size: 1.1rem;
    }

    .compromised-data ul {
        flex-direction: column;
        gap: 5px;
    }

    .compromised-data li {
        width: fit-content;
    }

    .show-more-button {
        width: 100%;
        padding: 10px 20px;
        font-size: 0.9rem;
    }
}

/* Checking Button Animation */
.check-button {
    position: relative;
    overflow: hidden;
}

.check-button:disabled {
    background: linear-gradient(45deg, #D8FF60, #c6e650);
    cursor: progress;
}

.check-button:disabled::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
        90deg,
        transparent,
        rgba(255, 255, 255, 0.2),
        transparent
    );
    animation: loading-wave 1.5s infinite;
}

@keyframes loading-wave {
    0% {
        left: -100%;
    }
    100% {
        left: 100%;
    }
}

/* Spinner Enhancement */
.spinner {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 8px;
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #1e1e1e;
    animation: spin 1s linear infinite;
}

.check-button:disabled .spinner {
    border-top-color: #1e1e1e;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Update the checking text animation */
.check-button:disabled span {
    background: linear-gradient(90deg, #1e1e1e, #2a2a2a);
    -webkit-background-clip: text;
    color: transparent;
    animation: text-shift 2s infinite;
}

@keyframes text-shift {
    0%, 100% {
        opacity: 1;
    }
    50% {
        opacity: 0.7;
    }
}

