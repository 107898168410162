   /* src/components/Spinner.css */

   .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #D8FF60;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin-right: 10px;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}