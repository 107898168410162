.password-strength-meter {
    margin-top: 8px;
}

.strength-bar {
    height: 4px;
    background-color: #e0e0e0;
    border-radius: 2px;
    overflow: hidden;
}

.strength-fill {
    height: 100%;
    transition: width 0.3s ease, background-color 0.3s ease;
}

.strength-label {
    margin-top: 4px;
    font-size: 12px;
    text-align: right;
} 